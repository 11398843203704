//================================================================
//  Page: Details Page child of BusinessUnits.js
//================================================================

//  Purpose: Provides a detailed view the select business unit

// Properties:
//     - N/A

//  Example:
//      <DetailsPage></DetailsPage>

//================================================================


// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Contexts

// Components
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';

// Functions
import QueryListener from '../../../Library/QueryListener';
import DocumentListener from '../../../Library/DocumentListener';

// Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';
import Arrow from '../../../Components/Images/Icon_Breadcrumb_Black.svg';

// CSS


export default function DetailsPage() {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const navigate = useNavigate();
    const params = useParams();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Firestore Document
    const [document, setDocument] = useState(undefined);

    // For search
    const [searchInput, setSearchInput] = useState('');

    // Used to store all assets
    const [assetsInventory, setAssetsInventory] = useState([]);

    // Used to store the filtered assets
    const [assetsInventoryFiltered, setAssetsInventoryFiltered] = useState([]);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {

            setAssetsInventoryFiltered(assetsInventory);

        };

        // Search Filter
        const searchResults = assetsInventory.filter((object) =>
            object?.id?.toLowerCase().includes(value.toLowerCase()) ||
            object?.primarysitecontact?.emailaddress?.toLowerCase().includes(value.toLowerCase()) ||
            object?.agreement?.name?.toLowerCase().includes(value.toLowerCase())
        );

        // Save the filtered search results to the useState
        setAssetsInventoryFiltered(searchResults);

    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onLoad
    // - Get the Firestore Document
    useEffect(() => {

        if (params.id === '') return;
        if (params.id === null) return;

        function onLoadChange(document) {

            setDocument(document);
            setPageStatus('onload');

        }

        function onError(error) {

            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = DocumentListener('businessunits', params.id, onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, []);

    // onLoad
    // - Get all associated NMIs
    useEffect(() => {

        if (params.id === '') return;
        if (params.id === null) return;

        function onLoadChange(documents) {

            setAssetsInventory(documents);
            setAssetsInventoryFiltered(documents);

        }

        function onError(error) {

            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = QueryListener('nmis', [
            ['businessunit.id', '==', params.id]
        ], onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, []);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <PageComponent
            header={
                <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
                    <div className='flex flex-col w-fit-content'>

                        {/* Breadcrumbs */}
                        <p className='flex flex-row gap-1 text-[15px]'>
                            <Link className='font-medium text-[#028E8C] no-underline cursor-pointer' to='/assets/businessunits?view=ALL'>
                                Asset Inventory
                            </Link> 
                            <img src={Arrow} alt='>'></img>
                            Business Units
                            <img src={Arrow} alt='>'></img>
                            {document?.name}
                        </p>

                        {/* Title */}
                        <div className='flex flex-row gap-4 align-middle' hidden={document?.name === undefined}>
                            <h4 className='text-[25px] font-large m-0 p-0 text-wrap capitalize font-medium'>
                                {document?.name}
                            </h4>
                            <StatusLabel status={document?.status}></StatusLabel>
                        </div>

                        {/* Description */}
                        <div className='flex flex-row justify-between' hidden={document?.address?.streetaddressline1?.length === 0}>
                            <p className='m-0'>
                                {`${document?.address?.streetaddressline1}, ${document?.address?.streetaddressline2 && document?.address?.streetaddressline2 + ','} ${document?.address?.city}, ${document?.address?.state} ${document?.address?.postcode}`}
                            </p>
                        </div>

                    </div>
                </div>
            }
            requiredRoles={['isEPM']}
            requiredRolesValue={true}
            status={pageStatus}
            body={
                <div className='flex flex-col px-12 py-4 gap-3'>

                    {/* ===================================================== */}
                    {/*  Assets Table                                         */}
                    {/* ===================================================== */}

                    {/* ----------------------------------- */}
                    {/*  Search                             */}
                    {/* ----------------------------------- */}

                    <div className='flex flex-row justify-end w-full'>

                        {/*  Search */}
                        <div className='flex gap-2'>

                            {/* ======== Search Bar ======== */}
                            <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px] my-[5px] h-fit'>
                                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                <label htmlFor='searchInput'>
                                    <input
                                        id='searchInput'
                                        className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                        type='text'
                                        placeholder='Search'
                                        onChange={(e) => handleSearch(e.target.value)}
                                        value={searchInput}
                                        autoComplete='no'
                                    ></input>
                                </label>
                            </div>

                            <ExportToCSV filename={`${document?.name}-NMIs-${Date.now()}`} type='nmis' data={assetsInventoryFiltered}></ExportToCSV>
                        </div>

                    </div>

                    {/* Table */}
                    <div className='Table-Container'>

                        {/* ======== Sorting and Filters ========== */}
                        <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5] min-h-[55px]'>

                            <label className='font-semibold self-center'>
                                Total: {assetsInventoryFiltered?.length} of {assetsInventoryFiltered?.length}
                            </label>

                        </div>

                        {/* ======== Table ========== */}
                        <table className='w-full max-h-96 overflow-y-scroll'>
                            <thead>
                                <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                                    <th className='py-[15px] px-[10px] font-medium'></th>
                                    <th className='py-[15px] px-[10px] font-medium'>NMI</th>
                                    <th className='p-[15px] font-medium'>Retailer</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Contact</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Address</th>
                                    <th className='p-[15px] font-medium'>Business Unit</th>
                                    <th className='p-[15px] font-medium'>Contracting Entity</th>
                                    <th className='p-[15px] font-medium'>Trust</th>
                                    <th className='p-[15px] font-medium'>Fund</th>
                                    <th className='py-[15px] px-[10px] font-medium'>State</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    // No assets found
                                    assetsInventoryFiltered?.length === 0 ? (

                                        <tr>
                                            <td colSpan={10}>
                                                <div className='text-center p-[2%]'>
                                                    No results found.
                                                </div>
                                            </td>
                                        </tr>

                                    ) : (

                                        // Map in each business request
                                        assetsInventoryFiltered?.map((object, index) => (

                                            <tr key={index} className='border-b-[#C8C8C9] border-solid border-b'>

                                                <td className='py-[15px] px-[10px]'></td>

                                                {/* NMI */}
                                                <td className='py-[15px] px-[10px] font-medium cursor-pointer hover:underline' onClick={() => navigate(`/assets/meters/${object?.id}`)}>
                                                    {object?.id}
                                                </td>

                                                {/* Retailer */}
                                                <td className='py-[15px] px-[10px]'>
                                                    {object?.agreement?.name}
                                                </td>

                                                {/* Primary Site Contact */}
                                                <td className='py-[15px] px-[10px]'>
                                                    <div className='flex flex-col'>
                                                        <span className='font-semibold'>
                                                            {object?.primarysitecontact?.givenname} {object?.primarysitecontact?.surname}
                                                        </span>
                                                        <span className='text-[#797979]'>
                                                            {object?.primarysitecontact?.emailaddress}
                                                        </span>
                                                        <span className='text-[#797979]'>
                                                            {object?.primarysitecontact?.contactnumber}
                                                        </span>
                                                    </div>
                                                </td>

                                                {/* Address */}
                                                <td className='py-[15px] px-[10px]'>
                                                    <div className='flex flex-col'>
                                                        <span className='font-semibold'>
                                                            {object?.address?.streetaddressline1}
                                                        </span>
                                                        <span hidden={object?.address?.streetaddressline2?.length === 0} className='text-[#797979]'> {/* secondary address is optional */}
                                                            {object?.address?.streetaddressline2},
                                                        </span>
                                                        <span className='text-[#797979]'>
                                                            {object?.address?.city}, {object?.address?.state} {object?.address?.postcode}
                                                        </span>
                                                    </div>
                                                </td>

                                                {/* Business Unit */}
                                                <td className='py-[15px] px-[10px]'>
                                                    {object?.businessunit?.name}
                                                </td>

                                                {/* Contracting Entity */}
                                                <td className='py-[15px] px-[10px]'>
                                                    {object?.contractingentity?.name}
                                                </td>

                                                {/* Trust */}
                                                <td className='py-[15px] px-[10px]'>
                                                    {object?.trust?.name}
                                                </td>

                                                {/* Fund */}
                                                <td className='py-[15px] px-[10px]'>
                                                    {object?.fund?.name}
                                                </td>

                                                {/* State */}
                                                <td className='py-[15px] px-[10px]'>
                                                    {object?.address?.state}
                                                </td>

                                                {/* Status */}
                                                <td className='pt-[15px] pb-[10px] px-[10px]'>
                                                    <StatusLabel status={object?.status}></StatusLabel>
                                                </td>

                                            </tr>

                                        ))

                                    )

                                }
                            </tbody>
                        </table>

                    </div>

                    {/* ---------------------------------------------------------------- */}
                    {/*  Panes                                                           */}
                    {/* ---------------------------------------------------------------- */}


                    {/* ---------------------------------------------------------------- */}
                    {/*  Modals                                                          */}
                    {/* ---------------------------------------------------------------- */}



                </div >
            }
        ></PageComponent>

    )
}
