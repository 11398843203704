//================================================================
//  Page: Requests
//================================================================

//  Business Lead: 
//    - CAN see 'Under Review' & 'Completed' requests

//  EPM: 
//    - CAN see 'Under Review' & 'Completed' requests

//================================================================


//Libraries
import React, { useState, useContext, useEffect } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

// Library
import QueryListener from '../../Library/QueryListener';

//Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';
import UnderReview from './Tabs/UnderReview';
import Completed from './Tabs/Completed';

//Functions

//Images

//CSS


export default function Requests() {

  //------------------------------------------------------
  //  useContexts & React Router
  //------------------------------------------------------
    
    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to save the status of the page > 'onload', 'pending', 'error-fatal' 
    const [pageStatus, setPageStatus] = useState('pending');

    // Used to store all the energy supply requests 
    const [allEnergySupplyRequests, setAllEnergySupplyRequests] = useState([]);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // Onload --> Get all energy supply requests
    useEffect(()=>{
    
      if (getUser === undefined) return;
    
      function onLoadChange(documents){

        // Sorting by created time (in seconds)
        documents.sort((a, b) => {

          if (a.created.seconds < b.created.seconds) return 1;
          if (a.created.seconds > b.created.seconds) return -1;

          return 0;

        });

        setAllEnergySupplyRequests(documents);
        setPageStatus('onload');
      }
      
      function onError(){

        setPageStatus('error-fatal');
        
      }
      
      const unsubscribe = QueryListener('energysupplyrequests', 
        getUser?.roles?.isEPM ?
          []
        :
          [['createdby', '==', getUser?.emailaddress]]
      , onLoadChange, onLoadChange, onError);

      return () =>{
        unsubscribe();
      };
      
    // eslint-disable-next-line
    }, [getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        status={pageStatus}
        header={
          <div className='Page-Header-Container'>
            <p className='font-medium text-[20px] mb-0'> Requests </p>
          </div>
        }
        body={
          <TabView
            defaultView={1}

            // ---------- UNDER REVIEW ---------- //
            oneTitle={
              <div className='flex flex-row gap-2'>
                Under Review
                <div className='bg-[#006866] font-bold text-white text-[12px] px-[9px] py-[3px] m-0 rounded-[50%]' hidden={allEnergySupplyRequests.filter((request) => request.status === 'Under Review')?.length === 0}>
                  {allEnergySupplyRequests.filter((request) => request.status === 'Under Review')?.length}
                </div>
              </div>
            }
            oneContent={
              <UnderReview
                allEnergySupplyRequests={allEnergySupplyRequests}
                setPageStatus={setPageStatus}
              ></UnderReview>
            }
            oneRoute={`/requests?view=underreview`}

            // ---------- COMPLETED ---------- //
            twoTitle={'Completed'}
            twoContent={
              <Completed
                allEnergySupplyRequests={allEnergySupplyRequests}
                setPageStatus={setPageStatus}
              ></Completed>
            }
            twoRoute={`/requests?view=completed`}

          ></TabView>
        }
      ></PageComponent>
    )
}
