//================================================================
//  Page: Meters Table
//================================================================

//  Purpose: Table each meter in each state

// Properties:
//     - setPageStatus = {useState, used to set page status in case of errors}


//  Example:
// <Meters
//     setPageStatus={setPageStatus}
// ></Meters>

//================================================================


// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Contexts

// Components
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';

// Tabs

// Functions
import QueryListener from '../../../Library/QueryListener';

// Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';

// CSS


export default function Meters({
    setPageStatus
}) {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // For filtering project status
    const [currentFilter, setCurrentFilter] = useState('Active');

    // For search
    const [searchInput, setSearchInput] = useState('');

    // Used to store all assets
    const [assetsInventory, setAssetsInventory] = useState([]);

    // Used to store the filtered assets
    const [assetsInventoryFiltered, setAssetsInventoryFiltered] = useState([]);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {

            setAssetsInventoryFiltered(assetsInventory);

        };

        // Search Filter
        const searchResults = assetsInventory.filter((object) =>
            object?.id?.toLowerCase().includes(value.toLowerCase()) ||
            object?.primarysitecontact?.emailaddress?.toLowerCase().includes(value.toLowerCase()) ||
            object?.agreement?.name?.toLowerCase().includes(value.toLowerCase())
        );

        // Save the filtered search results to the useState
        setAssetsInventoryFiltered(searchResults);

    };

    //Handles status filter
    function handleChange(event) {
        setCurrentFilter(event.target.value);
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Query Listener
    // - Get all the nmis
    useEffect(() => {

        if (currentFilter === undefined) return;

        function onLoadChange(documents) {

            setAssetsInventory(documents);
            setAssetsInventoryFiltered(documents);

        }

        function onError(error) {

            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = QueryListener('nmis', currentFilter === 'All' ?
            searchParams.get('view') === 'ALL' ?
                []
                :
                [
                    ['address.state', '==', searchParams.get('view')],
                ]

            :
            searchParams.get('view') === 'ALL' ?
                [
                    ['status', '==', currentFilter.toLowerCase()],
                ]
                :
                [
                    ['address.state', '==', searchParams.get('view')],
                    ['status', '==', currentFilter.toLowerCase()],
                ]

            , onLoadChange, onLoadChange, onError
        );

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, [currentFilter, searchParams.get('view')]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div className='flex flex-col gap-3 m-0 p-0'>

            {/* ----------------------------------- */}
            {/*  Filters & Search                    */}
            {/* ----------------------------------- */}

            <div className='flex flex-row justify-between w-full'>

                {/* Filters Container */}
                <div className='flex flex-col gap-1'>

                    {/* ======== States (Buttons) ========== */}
                    <div className=' flex flex-row gap-2'>

                        {/* All */}
                        <button className={searchParams.get('view') === 'ALL' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=ALL')}>
                            All
                        </button>

                        {/* NSW */}
                        <button className={searchParams.get('view') === 'NSW' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=NSW')}>
                            NSW
                        </button>

                        {/* VIC */}
                        <button className={searchParams.get('view') === 'VIC' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=VIC')}>
                            VIC
                        </button>

                        {/* ACT */}
                        <button className={searchParams.get('view') === 'ACT' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=ACT')}>
                            ACT
                        </button>

                        {/* QLD */}
                        <button className={searchParams.get('view') === 'QLD' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=QLD')}>
                            QLD
                        </button>

                        {/* WA */}
                        <button className={searchParams.get('view') === 'WA' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=WA')}>
                            WA
                        </button>

                        {/* SA */}
                        <button className={searchParams.get('view') === 'SA' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=SA')}>
                            SA
                        </button>

                        {/* TAS */}
                        <button className={searchParams.get('view') === 'TAS' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/meters?view=TAS')}>
                            TAS
                        </button>

                    </div>

                </div>

                {/*  Search */}
                <div className='flex justify-end m-0 gap-2'>

                    {/* ======== Search Bar ======== */}
                    <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px] my-[5px] h-fit'>
                        <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                        <label htmlFor='searchInput'>
                            <input
                                id='searchInput'
                                className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                type='text'
                                placeholder='Search'
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchInput}
                                autoComplete='no'
                            ></input>
                        </label>
                    </div>

                    <ExportToCSV filename={`${searchParams.get('view')}-NMIs-${Date.now()}`} type='nmis' data={assetsInventoryFiltered}></ExportToCSV>
                </div>

            </div>

            {/* Table */}
            <div className='Table-Container'>

                {/* ======== Sorting and Filters ========== */}
                <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5] min-h-[55px]'>

                    <label className='font-semibold self-center'>
                        Total: {assetsInventoryFiltered?.length} of {assetsInventoryFiltered?.length}
                    </label>

                    {/* ======== Status (Dropdown) ========== */}
                    <div className='flex flex-row items-center gap-2'>

                        <select className='Input-Field-Select' onChange={handleChange}>
                            <option value='Active'>Active</option>
                            <option value='decommissioned'>Decommissioned</option>
                            <option value='All'>All</option>
                        </select>
                    </div>

                </div>

                {/* ======== Table ========== */}
                <table className='w-full max-h-96 overflow-y-scroll'>
                    <thead>
                        <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                            <th className='py-[15px] px-[10px] font-medium'></th>
                            <th className='py-[15px] px-[10px] font-medium'>NMI</th>
                            <th className='p-[15px] font-medium'>Retailer</th>
                            <th className='py-[15px] px-[10px] font-medium'>Contact</th>
                            <th className='py-[15px] px-[10px] font-medium'>Address</th>
                            <th className='p-[15px] font-medium'>Business Unit</th>
                            <th className='p-[15px] font-medium'>Contracting Entity</th>
                            <th className='p-[15px] font-medium'>Trust</th>
                            <th className='p-[15px] font-medium'>Fund</th>
                            <th className='py-[15px] px-[10px] font-medium'>State</th>
                            <th className='py-[15px] px-[10px] font-medium'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            // No assets found
                            assetsInventoryFiltered?.length === 0 ? (

                                <tr>
                                    <td colSpan={10}>
                                        <div className='text-center p-[2%]'>
                                            No results found.
                                        </div>
                                    </td>
                                </tr>

                            ) : (

                                // Map in each business request
                                assetsInventoryFiltered?.map((object, index) => (

                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>

                                        <td className='py-[15px] px-[10px]'></td>

                                        {/* NMI */}
                                        <td className='py-[15px] px-[10px]'>
                                            <label className='font-medium cursor-pointer hover:underline' onClick={() => navigate(`/assets/meters/${object?.id}`)}>
                                                {object?.id}
                                            </label>
                                        </td>

                                        {/* Retailer */}
                                        <td className='py-[15px] px-[10px]'>
                                            <label className='cursor-pointer hover:underline' onClick={() => navigate(`/assets/retailers/${object?.agreement?.id}`)}>
                                                {object?.agreement?.name}
                                            </label>
                                        </td>

                                        {/* Primary Site Contact */}
                                        <td className='py-[15px] px-[10px]'>
                                            <div className='flex flex-col'>
                                                <span className='font-semibold'>
                                                    {object?.primarysitecontact?.givenname} {object?.primarysitecontact?.surname}
                                                </span>
                                                <span className='text-[#797979]'>
                                                    {object?.primarysitecontact?.emailaddress}
                                                </span>
                                                <span className='text-[#797979]'>
                                                    {object?.primarysitecontact?.contactnumber}
                                                </span>
                                            </div>
                                        </td>

                                        {/* Address */}
                                        <td className='py-[15px] px-[10px]'>
                                            <div className='flex flex-col'>
                                                <span className='font-semibold'>
                                                    {object?.address?.streetaddressline1}
                                                </span>
                                                <span hidden={object?.address?.streetaddressline2?.length === 0} className='text-[#797979]'> {/* secondary address is optional */}
                                                    {object?.address?.streetaddressline2},
                                                </span>
                                                <span className='text-[#797979]'>
                                                    {object?.address?.city}, {object?.address?.state} {object?.address?.postcode}
                                                </span>
                                            </div>
                                        </td>

                                        {/* Business Unit */}
                                        <td className='py-[15px] px-[10px]'>
                                            <label className='cursor-pointer hover:underline' onClick={() => navigate(`/assets/businessunits/${object?.businessunit?.id}`)}>
                                                {object?.businessunit?.name}
                                            </label>
                                        </td>

                                        {/* Contracting Entity */}
                                        <td className='py-[15px] px-[10px]'>
                                            <label className='cursor-pointer hover:underline' onClick={() => navigate(`/assets/contractingentities/${object?.contractingentity?.id}`)}>
                                                {object?.contractingentity?.name}
                                            </label>
                                        </td>

                                        {/* Trust */}
                                        <td className='py-[15px] px-[10px]'>
                                            <label className='cursor-pointer hover:underline' onClick={() => navigate(`/assets/trusts/${object?.trust?.id}`)}>
                                                {object?.trust?.name}
                                            </label>
                                        </td>

                                        {/* Fund */}
                                        <td className='py-[15px] px-[10px]'>
                                            <label className='cursor-pointer hover:underline' onClick={() => navigate(`/assets/funds/${object?.fund?.id}`)}>
                                                {object?.fund?.name}
                                            </label>
                                        </td>

                                        {/* State */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.address?.state}
                                        </td>

                                        {/* Status */}
                                        <td className='pt-[15px] pb-[10px] px-[10px]'>
                                            <StatusLabel status={object?.status}></StatusLabel>
                                        </td>

                                    </tr>

                                ))

                            )

                        }
                    </tbody>
                </table>

            </div>

        </div>

    )
}
