//================================================================
//  Component: Add Trust - Child component of NewEnergyRequestForm.js
//================================================================

//  Purpose: new business unit form, creates a new Firestore Document

//  Properties:
//    - openForm = {useState, open & close the modal}
//    - setOpenForm = {useState, open & close the modal}
//    - formData = {useReducer, partner useReducer formData}
//    - setFormData = {useReducer, partner useReducer setFormData}

//  Example:
//
//    <AddTrust
//      openForm={openForm}
//      setOpenForm={setOpenForm}
//      formData={formData}
//      setFormData={setFormData}
//    ></AddTrust>    

//================================================================


//Libraries
import React, { useContext, useReducer, useEffect } from 'react';

//Contexts
import { GetUser, SetToast } from '../../../Library/GlobalContexts';

//Components

//Functions
import QueryDocument from '../../../Library/QueryDocument';

//Images
import InfoIcon from '../../../Components/Images/Icon_Info_Blue.svg';
import returnIcon from '../../../Components/Images/Icon_Return_White.svg';

//CSS


export default function AddTrust({
    openForm,
    setOpenForm,
    formData,
    setFormData,
    disabled,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);
    const setToast = useContext(SetToast);

    // --------------------------------------------------
    //  useReducer
    // --------------------------------------------------


    const [modalData, setModalData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

            'name': formData?.trust?.type === 'existing' ? '' : formData.trust?.name,
            'nameError': '',

            'abn': formData?.trust?.type === 'existing' ? '' : formData.trust?.abn,

            'streetaddressline1': formData?.trust?.type === 'existing' ? '' : formData.trust?.address?.streetaddressline1,

            'streetaddressline2': formData?.trust?.type === 'existing' ? '' : formData.trust?.address?.streetaddressline2,

            'city': formData?.trust?.type === 'existing' ? '' : formData.trust?.address?.city,

            'state': formData?.trust?.type === 'existing' ? '' : formData.trust?.address?.state,

            'postcode': formData?.trust?.type === 'existing' ? '' : formData.trust?.address?.postcode,
            'postcodeError': '',

            'formChanged': false,

        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Check for an existing meter
    function handleLookup() {

        if (modalData.name.length === 0) return;

        QueryDocument('trusts', [
            ['name', '==', modalData.name]
        ]).then((documents) => {

            if (documents.length === 0) {

                setModalData({ 'nameError': '' });
                setToast({
                    'type': '', // info, pending, success, warning, error
                    'message': '',
                });

                return;

            }

            setModalData({ 'nameError': `Unable to add an existing trust.` });
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': `Unable to add an existing trust.`,
            });

        }).catch((error) => {

            console.log('error', error);
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to check for existing trust. Try again later.',
            });

        });

    }

    // Check if Save New Trust button is disabled
    function isDisabled(modalData) {
        return modalData.name.length === 0 ||
            modalData.abn.length === 0 ||
            modalData.streetaddressline1.length === 0 ||
            modalData.city.length === 0 ||
            modalData.state.length === 0 ||
            modalData.postcode.length === 0 ||
            modalData.nameError.length !== 0 ||
            modalData.postcodeError.length !== 0 ||
            modalData.formChanged === false

    };

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onChange
    //  1. IF the 'contractingentity' changes, we need to update the reference in 'trust'
    useEffect(() => {

        if (formData?.contractingentity?.id === undefined) return;
        if (formData.contractingentity.id === '') return;

        formData.trust.contractingentity = {
            'id': formData.contractingentity.id,
            'name': formData.contractingentity.name,
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.contractingentity.id]);

    // onChange
    //  1. Reset form, IF trust is empty
    useEffect(() => {

        if (formData?.trust?.id === undefined) return;
        if (formData.trust.id.length > 0) return;

        // Reset Form
        setModalData({

            'name': '',
            'nameError': '',

            'abn': '',

            'streetaddressline1': '',
            'streetaddressline2': '',

            'city': '',

            'state': '',

            'postcode': '',
            'postcodeError': '',

            'givenname': '',
            'givennameError': '',
            'surname': '',
            'surnameError': '',
            'emailaddress': '',
            'emailaddressError': '',
            'contactnumber': '',
            'contactnumberError': '',

            'formChanged': false,

        });

        setOpenForm(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.trust.id]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    // Close the Modal
    if (openForm === false) return null;

    return (

        <div className='flex flex-col justify-center bg-[#E7F0F4] px-4 py-3 rounded-md w-fit'>

            {/* ========================================= */}
            {/*     Header                                */}
            {/* ========================================= */}

            <div className='flex flex-row justify-between items-center'>

                <div className='flex flex-row gap-2 text-[#5E93AC] font-semibold items-center my-2'>
                    <img src={InfoIcon} alt='Info-Icon'></img>
                    New Trust
                </div>

            </div>

            {/* ========================================= */}
            {/*     Form                                  */}
            {/* ========================================= */}

            <div className='flex flex-col rounded w-fit gap-2'>

                <div className='flex flex-row gap-2'>

                    {/* Name */}
                    <div className='flex flex-col gap-1'>
                        <label className='font-semibold'>Name</label>
                        <input
                            className={modalData.nameError === '' ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                            onChange={(e) => setModalData({ 'name': e.target.value, 'formChanged': true })}
                            onBlur={() => handleLookup()}
                            placeholder='Jones Lang LaSalle (NSW) Pty Limited'
                            style={{ width: '300px' }}
                            value={modalData.name}
                            disabled={disabled}
                        ></input>
                    </div>

                    {/* ABN */}
                    <div className='flex flex-col gap-1'>
                        <label className='font-semibold'>ABN</label>
                        <input
                            className='Input-Field-Text'
                            onChange={(e) => setModalData({ 'abn': e.target.value, 'formChanged': true })}
                            onBlur={() => handleLookup()}
                            placeholder='51 824 753 556'
                            style={{ width: '220px' }}
                            value={modalData.abn}
                            disabled={disabled}
                        ></input>
                    </div>

                </div>

                {/* Address Line 1 */}
                <div className='flex flex-col gap-1'>
                    <label className='font-semibold'>Address Line 1</label>
                    <input
                        className='Input-Field-Text'
                        onChange={(e) => setModalData({ 'streetaddressline1': e.target.value, 'formChanged': true })}
                        placeholder='Street Address'
                        style={{ width: '100%' }}
                        value={modalData.streetaddressline1}
                        disabled={disabled}
                    ></input>
                </div>

                {/* Address Line 2 */}
                <div className='flex flex-col gap-1'>
                    <label className='font-semibold'>Address Line 2</label>
                    <input
                        className='Input-Field-Text'
                        onChange={(e) => setModalData({ 'streetaddressline2': e.target.value, 'formChanged': true })}
                        placeholder='Apartment, suite, building, floor, etc. (optional)'
                        style={{ width: '100%' }}
                        value={modalData.streetaddressline2}
                        disabled={disabled}
                    ></input>
                </div>

                <div className='flex flex-row gap-2'>

                    {/* City */}
                    <div className='flex flex-col gap-1'>
                        <label className='font-semibold'>City</label>
                        <input
                            className='Input-Field-Text'
                            onChange={(e) => setModalData({ 'city': e.target.value, 'formChanged': true })}
                            placeholder='Sydney'
                            style={{ width: '312px' }}
                            value={modalData.city}
                            disabled={disabled}
                        ></input>
                    </div>

                    {/* State */}
                    <div className='flex flex-col gap-1'>
                        <label className='font-semibold'>State</label>
                        <select
                            className='Input-Field-Select'
                            onChange={(e) => setModalData({ 'state': e.target.value, 'formChanged': true })}
                            style={{ width: '100px' }}
                            value={modalData.state}
                            disabled={disabled}
                        >
                            <option hidden value=''>-</option>
                            <option value='NSW'> NSW </option>
                            <option value='VIC'> VIC </option>
                            <option value='QLD'> QLD </option>
                            <option value='SA'> SA </option>
                            <option value='WA'> WA </option>
                            <option value='NT'> NT </option>
                            <option value='ACT'> ACT </option>
                            <option value='TAS'> TAS </option>
                        </select>
                    </div>

                    {/* Post Code */}
                    <div className='flex flex-col gap-1'>
                        <label className='font-semibold'>Post Code</label>
                        <input
                            className={modalData.postcodeError.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                            disabled={disabled}
                            onChange={(e) => {

                                const value = e.target.value;

                                if (value.length > 4 || value.length < 4) {

                                    return setModalData({
                                        'postcode': e.target.value,
                                        'postcodeError': 'No Valid'
                                    });

                                }

                                setModalData({
                                    'postcode': e.target.value,
                                    'postcodeError': '',
                                    'formChanged': true,
                                });

                            }}
                            placeholder='2000'
                            style={{ width: '100px' }}
                            type='number'
                            value={modalData.postcode}
                        ></input>
                    </div>

                </div>

                {/* ========================================= */}
                {/*     Buttons Container                     */}
                {/* ========================================= */}

                <div className='flex flex-row gap-2 justify-between'>

                    {/* ========================================= */}
                    {/*     Choose Existing Trust Button          */}
                    {/* ========================================= */}

                    <button hidden={disabled} className='Primary-Button w-fit mt-3' onClick={() => {

                        // Reset children
                        setFormData({
                            'trust': {
                                'type': 'existing', // new or existing

                                // ======== EXISTING & NEW ======== //
                                'id': '',
                                'name': '',
                
                                // ========   NEW    ======== //
                                'abn': '',
                                'contractingentity': {},
                                'address': {
                                    'streetaddressline1': '',
                                    'streetaddressline2': '',
                                    'city': '',
                                    'state': '',
                                    'postcode': '',
                                },
                                'createdby': '', // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            },
                            'fund': {
                                'type': 'existing', // new or existing

                                // ======== EXISTING & NEW ======== //
                                'id': '',
                                'name': '',
                
                                // ========   NEW    ======== //
                                'abn': '',
                                'trust': {},
                                'address': {
                                    'streetaddressline1': '',
                                    'streetaddressline2': '',
                                    'city': '',
                                    'state': '',
                                    'postcode': '',
                                },
                                'createdby': '', // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            }
                        });

                        // Reset Form
                        setModalData({

                            'name': '',
                            'nameError': '',

                            'abn': '',

                            'streetaddressline1': '',
                            'streetaddressline2': '',

                            'city': '',

                            'state': '',

                            'postcode': '',
                            'postcodeError': '',

                            'givenname': '',
                            'givennameError': '',
                            'surname': '',
                            'surnameError': '',
                            'emailaddress': '',
                            'emailaddressError': '',
                            'contactnumber': '',
                            'contactnumberError': '',

                            'formChanged': false,

                        });

                        setOpenForm(false);

                    }}>
                        <div className='flex flex-row gap-2'>
                            <img src={returnIcon} className='w-[20px]' alt='Return'></img>
                            Choose existing trust
                        </div>
                    </button>

                    {/* ========================================= */}
                    {/*     Save Button                           */}
                    {/* ========================================= */}

                    <button hidden={disabled} disabled={isDisabled(modalData)} className='Primary-Button w-fit self-end mt-3' onClick={() => {

                        setModalData({
                            'formChanged': false
                        });

                        setFormData({
                            'trust': {
                                'type': 'new', // new or existing

                                'id': `tr-${Date.now()}`,
                                'name': modalData.name, // Jones Lang LaSalle (NSW) Pty Limited, etc
                                'abn': modalData.abn, // 123456789
                                'contractingentity': {
                                    'id': formData.contractingentity.id,
                                    'name': formData.contractingentity.name,
                                },
                                'address': {
                                    'streetaddressline1': modalData.streetaddressline1,
                                    'streetaddressline2': modalData.streetaddressline2,
                                    'city': modalData.city,
                                    'state': modalData.state,
                                    'postcode': modalData.postcode,
                                },

                                'createdby': getUser.emailaddress, // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            }
                        });

                        setToast({
                            'type': '', // info, pending, success, warning, error
                            'message': '',
                        });

                    }}>
                        {formData?.trust?.type === 'new' && isDisabled(modalData) ? 'Saved' : 'Save'}
                    </button>

                </div>

            </div>

        </div>
    )
}
