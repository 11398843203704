//================================================================
//  Component: Contracting Entity - Child component of NewEnergyRequestForm.js
//================================================================

//  Purpose: Shows the new meters from the the parent useReducer

//  Properties:
//    - formData = {useReducer, partner useReducer formData}
//    - setFormData = {useReducer, partner useReducer setFormData}

//  ContractingEntity:
//    <ContractingEntity
//      formData={formData}
//      setFormData={setFormData}
//    ></ContractingEntity>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import { SetToast } from '../../../Library/GlobalContexts';

//Components
import AddContractingEntity from './AddContractingEntity';

//Functions
import QueryDocument from '../../../Library/QueryDocument';

//Images

//CSS


export default function ContractingEntity({
    formData,
    setFormData,
    disabled,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast)

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Holds the contracting entities
    const [contractingEntities, setContractingEntities] = useState([]);

    const [openForm, setOpenForm] = useState(formData.contractingentity.type === 'new' ? true : false);

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onChange
    //  1. Get all 'contractingentities' linked to the selected business unit
    useEffect(() => {

        if (formData?.businessunit?.id === undefined) return;

        QueryDocument('contractingentities', [
            ['businessunit.id', '==', formData.businessunit.id],
            ['status', '==', 'active'],
        ]).then((docs) => {

            setContractingEntities(docs);

        }).catch((error) => {

            console.log('error', error);

            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to retrieve contracting entities. Try again later.',
            });

        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.businessunit]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div hidden={formData?.businessunit?.id === ''} className='FormComponent-Row'>

            <label className='font-medium'>2a. Contracting Entity? </label>

            <div hidden={openForm} className='flex flex-row gap-2'>
                <select
                    className='Input-Field-Select'
                    value={formData.contractingentity.name}
                    disabled={disabled}
                    onChange={(e) => {

                        const doc = contractingEntities.find((object) => e.target.value === object.name);

                        // Using the index, select the correct Contracting Entity
                        setFormData({
                            'contractingentity': {
                                'type': 'existing',
                                'id': doc?.id,
                                'name': doc?.name,
                                'abn': doc?.abn,
                                'businessunit': {
                                    'id': formData.businessunit.id,
                                    'name': formData.businessunit.name,
                                },
                                'address': {
                                    'streetaddressline1': doc?.address?.streetaddressline1,
                                    'streetaddressline2': doc?.address?.streetaddressline2,
                                    'city': doc?.address?.city,
                                    'state': doc?.address?.state,
                                    'postcode': doc?.address?.postcode,
                                },
                                'givenname': doc?.primarybillingcontact?.givenname,
                                'surname': doc?.primarybillingcontact?.surname,
                                'emailaddress': doc?.primarybillingcontact?.emailaddress,
                                'contactnumber': doc?.primarybillingcontact?.contactnumber,

                                'createdby': doc?.createdby,
                                'created': doc?.created,
                                'modifiedby': doc?.modifiedby,
                                'modified': doc?.modified,
                                'status': doc?.status, // active or decommissioned
                            },

                            'primarybillingGivenname': doc?.primarybillingcontact?.givenname,
                            'primarybillingSurname': doc?.primarybillingcontact?.surname,
                            'primarybillingEmailaddress': doc?.primarybillingcontact?.emailaddress,
                            'primarybillingContactnumber': doc?.primarybillingcontact?.contactnumber,

                            // Clear all children, as the parent was updated
                            'trust': {
                                'type': 'existing', // new or existing

                                // ======== EXISTING & NEW ======== //
                                'id': '',
                                'name': '',
                
                                // ========   NEW    ======== //
                                'abn': '',
                                'contractingentity': {},
                                'address': {
                                    'streetaddressline1': '',
                                    'streetaddressline2': '',
                                    'city': '',
                                    'state': '',
                                    'postcode': '',
                                },
                                'createdby': '', // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            },
                            'fund': {
                                'type': 'existing', // new or existing

                                // ======== EXISTING & NEW ======== //
                                'id': '',
                                'name': '',
                
                                // ========   NEW    ======== //
                                'abn': '',
                                'trust': {},
                                'address': {
                                    'streetaddressline1': '',
                                    'streetaddressline2': '',
                                    'city': '',
                                    'state': '',
                                    'postcode': '',
                                },
                                'createdby': '', // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            }

                        });

                    }}
                >
                    <>
                        <option value=''>-</option>
                        {

                            // Map in each BU from Firestore
                            contractingEntities.map((doc, index) => (

                                <option key={index} value={doc.name}>{doc.name}</option>

                            ))

                        }
                    </>

                </select>

                {/* + Add New */}
                <button hidden={disabled} className='Primary-Button w-fit' onClick={() => {

                    // Clear all children, as the parent is 'new'
                    setFormData({
                        'trust': {
                            'type': 'existing', // new or existing

                            // ======== EXISTING & NEW ======== //
                            'id': '',
                            'name': '',
            
                            // ========   NEW    ======== //
                            'abn': '',
                            'contractingentity': {},
                            'address': {
                                'streetaddressline1': '',
                                'streetaddressline2': '',
                                'city': '',
                                'state': '',
                                'postcode': '',
                            },
                            'createdby': '', // emailaddress
                            'created': new Date(),
                            'modifiedby': '', // emailaddress
                            'modified': '',
                            'status': 'active', // active or decommissioned
                        },
                        'fund': {
                            'type': 'existing', // new or existing

                            // ======== EXISTING & NEW ======== //
                            'id': '',
                            'name': '',
            
                            // ========   NEW    ======== //
                            'abn': '',
                            'trust': {},
                            'address': {
                                'streetaddressline1': '',
                                'streetaddressline2': '',
                                'city': '',
                                'state': '',
                                'postcode': '',
                            },
                            'createdby': '', // emailaddress
                            'created': new Date(),
                            'modifiedby': '', // emailaddress
                            'modified': '',
                            'status': 'active', // active or decommissioned
                        }
                    });

                    setOpenForm(true);

                }}>+ Add New</button>
            </div>

            <AddContractingEntity
                formData={formData}
                setFormData={setFormData}
                openForm={openForm}
                setOpenForm={setOpenForm}
                disabled={disabled}
            ></AddContractingEntity>

        </div>
    )

}
