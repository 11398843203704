//================================================================
//  Page: Details Page child of Meters.js
//================================================================

//  Purpose: Provides a detailed view the select nmi

// Properties:
//     - N/A

//  Example:
//      <DetailsPage></DetailsPage>

//================================================================


// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Contexts

// Components
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';

// Functions
import DocumentListener from '../../../Library/DocumentListener';

// Images
import LinkIcon from '../../../Components/Images/Icon_Link_Blue.svg'
import Arrow from '../../../Components/Images/Icon_Breadcrumb_Black.svg';

// CSS


export default function DetailsPage() {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const navigate = useNavigate();
    const params = useParams();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Firestore Document
    const [document, setDocument] = useState(undefined);

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // onLoad
    // - Get the Firestore Document
    useEffect(() => {

        if (params.id === '') return;
        if (params.id === null) return;

        function onLoadChange(document) {

            setDocument(document);
            setPageStatus('onload');

        }

        function onError(error) {

            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = DocumentListener('nmis', params.id, onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, []);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <PageComponent
            header={
                <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>
                    <div className='flex flex-col w-fit-content'>

                        {/* Breadcrumbs */}
                        <p className='flex flex-row gap-1 text-[15px]'>
                            <Link className='font-medium text-[#028E8C] no-underline cursor-pointer' to='/assets/meters?view=ALL'>
                                Asset Inventory
                            </Link> 
                            <img src={Arrow} alt='>'></img>
                            Meters
                            <img src={Arrow} alt='>'></img>
                            {document?.id}
                        </p>

                        {/* Title */}
                        <div className='flex flex-row gap-4 align-middle' hidden={document?.id === undefined}>
                            <h4 className='text-[25px] font-large m-0 p-0 text-wrap capitalize font-medium'>
                                {document?.id}
                            </h4>
                            <StatusLabel status={document?.status}></StatusLabel>
                        </div>

                        {/* Description */}
                        <div className='flex flex-row justify-between' hidden={document?.id === undefined}>
                            <p className='m-0'>
                                {`${document?.address?.streetaddressline1}, ${document?.address?.streetaddressline2 && document?.address?.streetaddressline2 + ','} ${document?.address?.city}, ${document?.address?.state} ${document?.address?.postcode}`}
                            </p>
                        </div>

                    </div>
                </div>
            }
            requiredRoles={['isEPM']}
            requiredRolesValue={true}
            status={pageStatus}
            body={
                <div className='flex flex-col px-12 py-4 gap-3'>

                    {/* ===================================================== */}
                    {/*  Details                                              */}
                    {/* ===================================================== */}

                    {/* Retailer */}
                    <div className='FormComponent-Row'>

                        <label className='font-medium border-b border-[#D8D8D8] border-solid w-[600px] p-1'>Retailer</label>

                        {/* Name */}
                        <div className='flex flex-row gap-2'>

                            <div className='flex flex-col'>
                                <label>Name</label>
                                <input
                                    style={{ width: '580px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.agreement?.name}
                                    disabled
                                ></input>
                            </div>

                            <img className='relative top-[20px] w-[20px] cursor-pointer' src={LinkIcon} alt='Icon-Link' onClick={() => navigate(`/assets/retailers/${document?.agreement?.id}`)}></img>

                        </div>

                        {/* Start Date & Estimated Consumption */}
                        <div className='flex flex-row gap-2'>

                            <div className='flex flex-col'>
                                <label>Start Date</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.energystart}
                                    disabled
                                ></input>
                            </div>

                            <div className='flex flex-col'>
                                <label>Estimated Consumption</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.expectedconsumption}
                                    disabled
                                ></input>
                            </div>

                        </div>

                    </div>

                    {/* Overview */}
                    <div className='FormComponent-Row'>

                        <label className='font-medium border-b border-[#D8D8D8] border-solid w-[600px] p-1'>Overview</label>

                        {/* Business Unit */}
                        <div className='flex flex-row gap-2'>

                            <div className='flex flex-col'>
                                <label>Business Unit</label>
                                <input
                                    style={{ width: '580px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.businessunit?.name}
                                    disabled
                                ></input>
                            </div>

                            <img className='relative top-[20px] w-[20px] cursor-pointer' src={LinkIcon} alt='Icon-Link' onClick={() => navigate(`/assets/businessunits/${document?.businessunit?.id}`)}></img>

                        </div>

                        {/* Contracting Entity */}
                        <div className='flex flex-row gap-2'>

                            <div className='flex flex-col'>
                                <label>Contracting Entity</label>
                                <input
                                    style={{ width: '580px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.contractingentity?.name}
                                    disabled
                                ></input>
                            </div>

                            <img className='relative top-[20px] w-[20px] cursor-pointer' src={LinkIcon} alt='Icon-Link' onClick={() => navigate(`/assets/contractingentities/${document?.contractingentity?.id}`)}></img>

                        </div>

                        {/* Trust */}
                        <div className='flex flex-row gap-2'>

                            <div className='flex flex-col'>
                                <label>Trust</label>
                                <input
                                    style={{ width: '580px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.trust?.name}
                                    disabled
                                ></input>
                            </div>

                            <img className='relative top-[20px] w-[20px] cursor-pointer' src={LinkIcon} alt='Icon-Link' onClick={() => navigate(`/assets/trusts/${document?.trust?.id}`)}></img>

                        </div>

                        {/* Fund */}
                        <div className='flex flex-row gap-2'>

                            <div className='flex flex-col'>
                                <label>Fund</label>
                                <input
                                    style={{ width: '580px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.fund?.name}
                                    disabled
                                ></input>
                            </div>

                            <img className='relative top-[20px] w-[20px] cursor-pointer' src={LinkIcon} alt='Icon-Link' onClick={() => navigate(`/assets/funds/${document?.fund?.id}`)}></img>

                        </div>

                    </div>

                    {/* Primary Site Contact */}
                    <div className='FormComponent-Row'>

                        <label className='font-medium border-b border-[#D8D8D8] border-solid w-[600px] p-1'>Primary Site Contact</label>

                        <div className='flex flex-row gap-2'>

                            {/* First name */}
                            <div className='flex flex-col'>
                                <label>First name</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarysitecontact?.givenname}
                                    disabled
                                ></input>
                            </div>

                            {/* Surname */}
                            <div className='flex flex-col'>
                                <label>Surname</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarysitecontact?.surname}
                                    disabled
                                ></input>
                            </div>

                        </div>

                        {/* Email Address & Contact Number */}
                        <div className='flex flex-row gap-2'>

                            {/* Email Address */}
                            <div className='flex flex-col'>
                                <label>Email Address</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarysitecontact?.emailaddress}
                                    disabled
                                ></input>
                            </div>

                            {/* Contact Number */}
                            <div className='flex flex-col'>
                                <label>Contact Number</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarysitecontact?.contactnumber}
                                    disabled
                                ></input>
                            </div>

                        </div>

                    </div>

                    {/* Primary Billing Contact */}
                    <div className='FormComponent-Row'>

                        <label className='font-medium border-b border-[#D8D8D8] border-solid w-[600px] p-1'>Primary Billing Contact</label>

                        <div className='flex flex-row gap-2'>

                            {/* First name */}
                            <div className='flex flex-col'>
                                <label>First name</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarybillingcontact?.givenname}
                                    disabled
                                ></input>
                            </div>

                            {/* Surname */}
                            <div className='flex flex-col'>
                                <label>Surname</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarybillingcontact?.surname}
                                    disabled
                                ></input>
                            </div>

                        </div>

                        {/* Email Address & Contact Number */}
                        <div className='flex flex-row gap-2'>

                            {/* Email Address */}
                            <div className='flex flex-col'>
                                <label>Email Address</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarybillingcontact?.emailaddress}
                                    disabled
                                ></input>
                            </div>

                            {/* Contact Number */}
                            <div className='flex flex-col'>
                                <label>Contact Number</label>
                                <input
                                    style={{ width: '300px' }}
                                    className='Input-Field-Text'
                                    defaultValue={document?.primarybillingcontact?.contactnumber}
                                    disabled
                                ></input>
                            </div>

                        </div>

                    </div>

                    {/* ---------------------------------------------------------------- */}
                    {/*  Panes                                                           */}
                    {/* ---------------------------------------------------------------- */}


                    {/* ---------------------------------------------------------------- */}
                    {/*  Modals                                                          */}
                    {/* ---------------------------------------------------------------- */}



                </div >
            }
        ></PageComponent>

    )
}
