//================================================================
//  Page: Modify Energy Supply Overview
//================================================================

// Purpose: Allows the EPM review the modification of an existing NMI(s)

//  Business Lead: 
//    - CAN view 'Modify Energy Supply Overview'

//  EPM: 
//    - CAN modify 'Modify Energy Supply Overview' with the status of 'Under Review'
//    - CAN NOT modify 'Modify Energy Supply Overview' with the status of 'Completed'

//================================================================

// Libraries
import React, { useContext, useState, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

// Functions
import WriteDocument from '../../../../Library/WriteDocument';
import QueryDocument from '../../../../Library/QueryDocument';

//Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import MeterTable from './MeterTable/MeterTable';
import BusinessUnit from '../../../../Components/EnergySupplyComponents/BusinessUnit/BusinessUnit';
import ContractingEntity from '../../../../Components/EnergySupplyComponents/ContractingEntity/ContractingEntity';
import Trust from '../../../../Components/EnergySupplyComponents/Trust/Trust';
import Fund from '../../../../Components/EnergySupplyComponents/Fund/Fund';

// Images

//CSS

export default function ModifyEnergySupplyOverview({
    energySupplyRequest
}) {

    // --------------------------------------------------
    //  useContext and React Router
    // --------------------------------------------------

    const getUser = useContext(GetUser);
    const navigate = useNavigate();

    // --------------------------------------------------
    //  useStates
    // --------------------------------------------------

    // Used to save page status >  'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Hold all active agreements
    const [agreements, setAgreements] = useState([]);

    // Used to determine if the confirmation box has been checked
    const [confirmationChecked, setConfirmationChecked] = useState(false);

    // --------------------------------------------------
    //  useReducer
    // --------------------------------------------------

    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

            'nmiAssigned': '',
            'nmiAssignedError': '',

            // Array of NMI's
            'nmis': energySupplyRequest.nmis,

            'modificationType': energySupplyRequest.modificationtype, // 'Reassignment' or 'Termination' 

            'businessunit': {
                'type': energySupplyRequest.businessunit.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.businessunit.id,
                'name': energySupplyRequest.businessunit.name,

                // ========   NEW    ======== //
                'address': {
                    'streetaddressline1': energySupplyRequest.businessunit.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.businessunit.address.streetaddressline2,
                    'city': energySupplyRequest.businessunit.address.city,
                    'state': energySupplyRequest.businessunit.address.state,
                    'postcode': energySupplyRequest.businessunit.address.postcode,
                },
                'createdby': energySupplyRequest.businessunit.createdby,
                'created': energySupplyRequest.businessunit.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.businessunit.status, // active or decommissioned
            },
            'businessunitError': '',

            'contractingentity': {
                'type': energySupplyRequest.contractingentity.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.contractingentity.id,
                'name': energySupplyRequest.contractingentity.name,

                // ========   NEW    ======== //
                'abn': energySupplyRequest.contractingentity.abn,
                'businessunit': energySupplyRequest.contractingentity.businessunit,
                'address': {
                    'streetaddressline1': energySupplyRequest.contractingentity.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.contractingentity.address.streetaddressline2,
                    'city': energySupplyRequest.contractingentity.address.city,
                    'state': energySupplyRequest.contractingentity.address.state,
                    'postcode': energySupplyRequest.contractingentity.address.postcode,
                },
                'givenname': energySupplyRequest.contractingentity.primarybillingcontact.givenname,
                'surname': energySupplyRequest.contractingentity.primarybillingcontact.surname,
                'emailaddress': energySupplyRequest.contractingentity.primarybillingcontact.emailaddress,
                'contactnumber': energySupplyRequest.contractingentity.primarybillingcontact.contactnumber,

                'createdby': energySupplyRequest.contractingentity.createdby,
                'created': energySupplyRequest.contractingentity.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.contractingentity.status, // active or decommissioned
            },

            'trust': {
                'type': energySupplyRequest.trust.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.trust.id,
                'name': energySupplyRequest.trust.name,

                // ========   NEW    ======== //
                'abn': energySupplyRequest.trust.abn,
                'contractingentity': energySupplyRequest.trust.contractingentity,
                'address': {
                    'streetaddressline1': energySupplyRequest.trust.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.trust.address.streetaddressline2,
                    'city': energySupplyRequest.trust.address.city,
                    'state': energySupplyRequest.trust.address.state,
                    'postcode': energySupplyRequest.trust.address.postcode,
                },
                'createdby': energySupplyRequest.trust.createdby,
                'created': energySupplyRequest.trust.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.trust.status, // active or decommissioned
            },

            // 'fund' doc ref
            'fund': {
                'type': energySupplyRequest.fund.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.fund.id,
                'name': energySupplyRequest.fund.name,

                // ========   NEW    ======== //
                'abn': energySupplyRequest.fund.abn,
                'trust': energySupplyRequest.fund.trust,
                'address': {
                    'streetaddressline1': energySupplyRequest.fund.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.fund.address.streetaddressline2,
                    'city': energySupplyRequest.fund.address.city,
                    'state': energySupplyRequest.fund.address.state,
                    'postcode': energySupplyRequest.fund.address.postcode,
                },
                'createdby': energySupplyRequest.fund.createdby,
                'created': energySupplyRequest.fund.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.fund.status, // active or decommissioned
            },

            'energychange': energySupplyRequest.energychange, // date-string
            'energychangeError': '',

            'reason': energySupplyRequest.reason, // date-string
            'reasonError': '',
            'reasonOther': energySupplyRequest.reasonother,
            'reasonOtherError': '',

            'agreement': {
                'id': energySupplyRequest.agreement.id,
                'name': energySupplyRequest.agreement.name,
            },
            'agreementError': '',
    
        }
    );

    // --------------------------------------------------
    //  Functions
    // --------------------------------------------------

    //  Submit Handler
    function handleSubmit() {

        setPageStatus('pending');

        const docPromises = [];

        //=================================================
        //  Business Unit
        //=================================================

        if (formData.businessunit.type === 'new') {

            const businessUnitDoc = {
                'id': formData.businessunit.id,
                'name': formData.businessunit.name,
                'address': {
                    'streetaddressline1': formData.businessunit.address.streetaddressline1,
                    'streetaddressline2': formData.businessunit.address.streetaddressline2,
                    'city': formData.businessunit.address.city,
                    'state': formData.businessunit.address.state,
                    'postcode': formData.businessunit.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('businessunits', businessUnitDoc.id, businessUnitDoc, false),
            );

        }

        //=================================================
        //  Contracting Entity
        //=================================================

        if (formData.contractingentity.type === 'new') {

            const contractingEntityDoc = {
                'id': formData.contractingentity.id,
                'name': formData.contractingentity.name,
                'abn': formData.contractingentity.abn,
                'businessunit': {
                    'id': formData.businessunit.id,
                    'name': formData.businessunit.name,
                },
                'address': {
                    'streetaddressline1': formData.contractingentity.address.streetaddressline1,
                    'streetaddressline2': formData.contractingentity.address.streetaddressline2,
                    'city': formData.contractingentity.address.city,
                    'state': formData.contractingentity.address.state,
                    'postcode': formData.contractingentity.address.postcode,
                },
                'primarybillingcontact': {
                    'givenname': formData.contractingentity.givenname,
                    'surname': formData.contractingentity.surname,
                    'emailaddress': formData.contractingentity.emailaddress,
                    'contactnumber': formData.contractingentity.contactnumber,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('contractingentities', contractingEntityDoc.id, contractingEntityDoc, false),
            );

        }

        //=================================================
        //  Trust
        //=================================================

        if (formData.trust.type === 'new') {

            const trustDoc = {
                'id': formData.trust.id,
                'name': formData.trust.name,
                'abn': formData.trust.abn,
                'contractingentity': {
                    'id': formData.contractingentity.id,
                    'name': formData.contractingentity.name,
                },
                'address': {
                    'streetaddressline1': formData.trust.address.streetaddressline1,
                    'streetaddressline2': formData.trust.address.streetaddressline2,
                    'city': formData.trust.address.city,
                    'state': formData.trust.address.state,
                    'postcode': formData.trust.address.postcode,
                },

                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('trusts', trustDoc.id, trustDoc, false),
            );
        }

        //=================================================
        //  Fund
        //=================================================

        if (formData.fund.type === 'new') {

            const fundDoc = {
                'id': formData.fund.id,
                'name': formData.fund.name,
                'abn': formData.fund.abn,
                'trust': {
                    'id': formData.trust.id,
                    'name': formData.trust.name,
                },
                'address': {
                    'streetaddressline1': formData.fund.address.streetaddressline1,
                    'streetaddressline2': formData.fund.address.streetaddressline2,
                    'city': formData.fund.address.city,
                    'state': formData.fund.address.state,
                    'postcode': formData.fund.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('funds', fundDoc.id, fundDoc, false),
            );

        }

        //=================================================
        //  NMI's
        //=================================================

        formData.nmis.forEach((nmi) => {

            let nmiDoc = {
                'id': nmi.id,
                'expectedconsumption': nmi.expectedconsumption,
                'transferdate': formData.energychange, // date-string
                'decommissioneddate': formData.modificationType === 'Termination' ? `${new Date().toISOString().split('T')[0]}` : '', // date-string
                'address': {
                    'streetaddressline1': nmi.streetaddressline1,
                    'streetaddressline2': nmi.streetaddressline2,
                    'city': nmi.city,
                    'state': nmi.state,
                    'postcode': nmi.postcode,
                },
                'agreement': {
                    'id': formData.fund.id,
                    'name': formData.fund.name,
                },

                'modifiedby': getUser.emailaddress, // emailaddress
                'modified': new Date(),

                'status': formData.modificationType === 'Termination' ? 'decommissioned' : 'active', // active or decommissioned
            };

            // Q2. What type of modification are you requesting?
            if (formData.modificationType === 'Reassignment') {

                nmiDoc = {
                    ...nmiDoc,
                    'businessunit': {
                        'id': formData.businessunit.id,
                        'name': formData.businessunit.name,
                    },
                    'contractingentity': {
                        'id': formData.contractingentity.id,
                        'name': formData.contractingentity.name,
                    },
                    'trust': {
                        'id': formData.trust.id,
                        'name': formData.trust.name,
                    },
                    'fund': {
                        'id': formData.fund.id,
                        'name': formData.fund.name,
                    },
                    'agreement': {
                        'id': formData.agreement.id,
                        'name': formData.agreement.name,
                    },

                    // 'contractingentity' contains the primary billing contact
                    'primarybillingcontact': {
                        'givenname': formData.contractingentity.givenname,
                        'surname': formData.contractingentity.surname,
                        'emailaddress': formData.contractingentity.emailaddress,
                        'contactnumber': formData.contractingentity.contactnumber,
                    },

                }

            }

            docPromises.push(
                WriteDocument('nmis', nmiDoc.id, nmiDoc, true),
            );

        });

        //=================================================
        //  Settle Promises
        //=================================================

        Promise.all(docPromises).then(() => {

            const document = {
                'status': 'Completed',
                'modified': new Date(),
                'modifiedby': getUser.emailaddress,

                'nmis': formData.nmis,

                'agreement': {
                    'id': formData.agreement.id,
                    'name': formData.agreement.name,
                },

                'businessunit': {
                    'type': formData.businessunit.type,
                    'id': formData.businessunit.id,
                    'name': formData.businessunit.name,
                    'address': {
                        'streetaddressline1': formData.businessunit.address.streetaddressline1,
                        'streetaddressline2': formData.businessunit.address.streetaddressline2,
                        'city': formData.businessunit.address.city,
                        'state': formData.businessunit.address.state,
                        'postcode': formData.businessunit.address.postcode,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'contractingentity': {
                    'type': formData.contractingentity.type,
                    'id': formData.contractingentity.id,
                    'name': formData.contractingentity.name,
                    'abn': formData.contractingentity.abn,
                    'businessunit': [
                        {
                            'id': formData.businessunit.id,
                            'name': formData.businessunit.name,
                        }
                    ],
                    'address': {
                        'streetaddressline1': formData.contractingentity.address.streetaddressline1,
                        'streetaddressline2': formData.contractingentity.address.streetaddressline2,
                        'city': formData.contractingentity.address.city,
                        'state': formData.contractingentity.address.state,
                        'postcode': formData.contractingentity.address.postcode,
                    },
                    'primarybillingcontact': {
                        'givenname': formData.contractingentity.givenname,
                        'surname': formData.contractingentity.surname,
                        'emailaddress': formData.contractingentity.emailaddress,
                        'contactnumber': formData.contractingentity.contactnumber,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'trust': {
                    'type': formData.trust.type,
                    'id': formData.trust.id,
                    'name': formData.trust.name,
                    'abn': formData.trust.abn,
                    'contractingentity': [
                        {
                            'id': formData.contractingentity.id,
                            'name': formData.contractingentity.name,
                        }
                    ],
                    'address': {
                        'streetaddressline1': formData.trust.address.streetaddressline1,
                        'streetaddressline2': formData.trust.address.streetaddressline2,
                        'city': formData.trust.address.city,
                        'state': formData.trust.address.state,
                        'postcode': formData.trust.address.postcode,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'fund': {
                    'type': formData.fund.type,
                    'id': formData.fund.id,
                    'name': formData.fund.name,
                    'abn': formData.fund.abn,
                    'trust': [
                        {
                            'id': formData.trust.id,
                            'name': formData.trust.name,
                        }
                    ],
                    'address': {
                        'streetaddressline1': formData.fund.address.streetaddressline1,
                        'streetaddressline2': formData.fund.address.streetaddressline2,
                        'city': formData.fund.address.city,
                        'state': formData.fund.address.state,
                        'postcode': formData.fund.address.postcode,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'energychange': formData.energychange, // date-string
    
                'reason': formData.reason,
                'reasonother': formData.reasonOther,

            }
            return WriteDocument('energysupplyrequests', energySupplyRequest.energysupplyrequestid, document, true).then(() => {

                navigate('/requests?view=completed');

            });

        }).catch((error) => {

            console.log(error);
            setPageStatus('error-fatal');

        });

    }

    // --------------------------------------------------
    //  useEffects
    // --------------------------------------------------

    // onLoad
    //  1. Get all agreements
    useEffect(() => {

        QueryDocument('agreements', [
            ['status', '==', 'active']
        ]).then((documents) => {

            setAgreements(documents);

        }).catch((error) => {

            console.log('Failed to get agreements', error);
            setPageStatus('error-fatal');

        });

    }, []);

    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

    return (
        <PageComponent
            requiredRoles={['isLLUser', 'isEPM']}
            requiredRolesValue={true}
            status={pageStatus}
            body={

                <div className='Page-Section-Container'>

                    <div className='FormComponent'>

                        {/* ========================================= */}
                        {/*  Provide each existing NMI *              */}
                        {/*  - Adds items to the table                */}
                        {/* ========================================= */}

                        <MeterTable
                            formData={formData}
                            setFormData={setFormData}
                            disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                        ></MeterTable>

                        {/* =========================================================== */}
                        {/*  2. What type of modification are you requesting?           */}
                        {/* =========================================================== */}

                        <div className='FormComponent-Row'>

                            <label className='font-medium'>2. What type of modification are you requesting? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Reassignment
                                    <input
                                        type='radio'
                                        checked={formData.modificationType === 'Reassignment'}
                                        onChange={() => setFormData({ 'modificationType': 'Reassignment' })}
                                    ></input>
                                    <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                </label>

                                <label className='Radio-Buttons-Container'>
                                    Termination
                                    <input
                                        type='radio'
                                        checked={formData.modificationType === 'Termination'}
                                        onChange={() => setFormData({ 'modificationType': 'Termination' })}
                                    ></input>
                                    <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                </label>
                            </div>

                        </div>

                        {/* =================================================== */}
                        {/*  2a. What type of modification are you requesting?  */}
                        {/*  Q2 Answer: Reassignment                            */}
                        {/* =================================================== */}

                        <div hidden={formData.modificationType !== 'Reassignment' || formData.modificationType === ''} className='FormComponent-Row'>

                            <BusinessUnit
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></BusinessUnit>

                            {/* ==== 2a. Contracting Entity <Optional> ==== */}
                            <ContractingEntity
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></ContractingEntity>

                            {/* ==== 2b. Trust <Optional> ==== */}
                            <Trust
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></Trust>

                            {/* ==== 2c. Fund <Optional> ==== */}
                            <Fund
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></Fund>

                        </div>

                        {/* =================================================== */}
                        {/*  2a. What type of modification are you requesting?  */}
                        {/*  Q2 Answer: Termination                             */}
                        {/* =================================================== */}

                        <div hidden={formData.modificationType !== 'Termination' || formData.modificationType === ''} className='FormComponent-Row'>

                            <label className='font-medium'>2a. Reason for the change? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>

                                {/* Meter is been transferred to customer */}
                                <label className='Radio-Buttons-Container'>
                                    Meter is been transferred to customer
                                    <input
                                        type='radio'
                                        checked={formData.reason === 'Meter is been transferred to customer'}
                                        onChange={() => setFormData({ 'reason': 'Meter is been transferred to customer' })}
                                        disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                    ></input>
                                    <span className={formData.reasonError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                </label>

                                {/* Meter is been handed back to Landlord */}
                                <label className='Radio-Buttons-Container'>
                                    Meter is been handed back to Landlord
                                    <input
                                        type='radio'
                                        checked={formData.reason === 'Meter is been handed back to Landlord'}
                                        onChange={() => setFormData({ 'reason': 'Meter is been handed back to Landlord' })}
                                        disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                    ></input>
                                    <span className={formData.reasonError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                </label>

                                {/* Other */}
                                <label className='Radio-Buttons-Container'>
                                    Other
                                    <input
                                        type='radio'
                                        checked={formData.reason === 'Other'}
                                        onChange={() => setFormData({ 'reason': 'Other' })}
                                        disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                    ></input>
                                    <span className={formData.reasonError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                </label>

                            </div>

                            {/* Other Text Area */}
                            <textarea
                                hidden={formData.reason === 'Other' ? false : true}
                                className={formData.reasonOtherError?.length === 0 ? 'Input-Field-TextArea' : 'Input-Field-TextArea-Error'}
                                onChange={(e) => setFormData({ 'reasonOther': e.target.value })}
                                placeholder='Reason for the change'
                                value={formData.reasonOther}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></textarea>

                        </div>

                        {/* ================================================== */}
                        {/*  3. When should this change take effect?           */}
                        {/* ================================================== */}

                        <div className='FormComponent-Row'>
                            <label className='font-medium'>3. When should this change take effect? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                            <input
                                className={formData.energychangeError.length === 0 ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                                type='date'
                                onChange={(e) => setFormData({ 'energychange': e.target.value })}
                                value={formData.energychange}
                                min={new Date().toISOString().split('T')[0]}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></input>
                        </div>

                        {/* ================================================== */}
                        {/*  4. Attach Energy Agreement                        */}
                        {/* ================================================== */}

                        <div hidden={formData.modificationType !== 'Reassignment' || formData.modificationType === ''} className='FormComponent-Row'>

                            <label className='font-medium'>4. Attach Energy Agreement <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                            <select
                                className={formData.agreementError.length === 0 ? ('Input-Field-Select') : ('Input-Field-Select-Error')}
                                style={{ width: '300px' }}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                value={formData.agreement.name}
                                onChange={(e) => {

                                    const value = e.target.value;

                                    // Reset input
                                    if (value === '') {

                                        return setFormData({
                                            'agreement': {
                                                'id': '',
                                                'name': ''
                                            },
                                            'agreementError': 'value required',
                                        });

                                    }

                                    // Find and attached select agreement
                                    const agreement = agreements.find((object) => object.retailername === value);
                                    setFormData({
                                        'agreement': {
                                            'id': agreement?.id,
                                            'name': agreement?.retailername
                                        },
                                        'agreementError': '',
                                    });

                                }}
                            >
                                <>
                                    <option className='pt-3' value=''>-</option>

                                    {

                                        // Map in each BU from Firestore
                                        agreements.map((doc, index) => (

                                            <option className='pt-3' key={index} value={doc.retailername}>{doc.retailername}</option>

                                        ))

                                    }
                                </>

                            </select>

                        </div>

                    </div>

                    {/* ===================================== */}
                    {/*  Checkbox                             */}
                    {/* ===================================== */}
                                            
                    <hr></hr>

                    <div className='flex flex-row items-start gap-2 my-4 mx-2' hidden={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}>

                        <label className='Checkbox-Container'>
                            <input
                                required
                                type='checkbox'
                                name='confirmation'
                                checked={confirmationChecked}
                                onChange={() => setConfirmationChecked(!confirmationChecked)}
                            ></input>
                            <span className='Checkbox'></span>
                        </label>

                        <label>
                            I understand the impact of the above changes and by selecting <strong>Modify</strong>, I am applying these changes to the Asset Inventory.
                        </label>

                    </div>

                    {/* ===================================== */}
                    {/*  Submit Buttons                       */}
                    {/* ===================================== */}

                    <div className='FormComponent-Row flex flex-row gap-2'>

                        <button hidden={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'} className='Primary-Button w-fit' disabled={
                            formData.nmis.length === 0 ||
                            formData.businessunit?.id === undefined ||
                            formData.energychange?.length === 0 ||
                            (formData.modificationType === 'Reassignment' && formData.agreement?.name?.length === 0) ||
                            confirmationChecked === false
                        } onClick={() => handleSubmit()}>
                            Modify
                        </button>

                        <button className='Secondary-Button w-fit' onClick={() => getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed' ? navigate('/requests?view=completed') : navigate('/requests?view=underreview')}>
                            {
                                getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed' ? 'Back' : 'Cancel'
                            }
                        </button>

                    </div>

                </div>

            }
        ></PageComponent>
    )

}
