// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';
import { doc, getDoc } from "firebase/firestore";

/**
 * Returns a document from a collection
 * 
 * @param {string} collectionId
 * @param {string} documentId
 * @param {boolean} disableExistsError - <OPTIONAL> ignores errors when a document doesn't exist
 * 
 * ```
 * const collectionId = 'users';
 * const documentId = 'mark.bennett@lendlease.com';
 * const disableExistsError = true;
 * 
 * GetDocument(collectionId, documentId, disableExistsError).then((document) => {
 * 
 *  console.log('Document', document);
 * 
 * }).catch((error) => {
 * 
 *  console.log('Error', error);
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/firestore/query-data/get-data#get_a_document
 */

export default async function GetDocument(collectionId, documentId, disableExistsError){

  //------------------------------------------------------
  //  Execute query
  //------------------------------------------------------

    try {

      // Firestore Client
      const app = initializeApp(firebaseConfig);
      const db = getFirestore(app);

      // Get Document
      const docRef = doc(db, collectionId, documentId);
      const docSnap = await getDoc(docRef);
    
      // IF the document doesn't exist and optional flag enabled
      if (docSnap.exists() === false && disableExistsError === true) return undefined;

      // Found the document
      if (docSnap.exists()) return docSnap.data();

      // Default --> throw error when document doesnt exist
      throw new Error(`Document '${documentId}' does not exist`);

    } catch (error) {
  
      throw new Error(`Function GetDocument failed to complete, error ${error}`);

    }

  //------------------------------------------------------
}