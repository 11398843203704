//================================================================
//  Tab: Completed
//================================================================

//  Purpose: Tab inside of the home page 'Requests'

//  Properties:
//    - allEnergySupplyRequests = {useState, all the Completed requests}

//  BusinessUnit:
//    <Completed
//      allEnergySupplyRequests={allEnergySupplyRequests}
//    ></Completed>  

//================================================================


//Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts

// Library

//Components
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';

//Functions
import ConvertDate from '../../../Library/ConvertDate';

//Images
import EditIcon from '../../../Components/Images/Icon_Edit_White.svg';

//CSS


export default function Completed({
  allEnergySupplyRequests,
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate();

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    <div className='flex flex-col gap-3 m-0 p-0'>

      {/* Buttons */}
      <div className='flex justify-end gap-2'>
        <button className='Primary-Button' onClick={() => navigate('/requests/newenergyrequestform')}>+ Energy Supply Request</button>
        <button className='Primary-Button flex flex-row' onClick={() => navigate('/requests/modifyenergysupplyform')}>
          <img className='w-[16px] self-center mr-[5px]' src={EditIcon} alt='Edit-Icon'></img>
          Modify Energy Supply</button>
      </div>

      {/* Table */}
      <div className='Table-Container'>

        {/* ======== Sorting and Filters ========== */}
        <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5] min-h-[55px]'>

          <label className='font-semibold self-center'>
            Total: {allEnergySupplyRequests.filter((request) => request.status === 'Completed')?.length} of {allEnergySupplyRequests.filter((request) => request.status === 'Completed')?.length}
          </label>

        </div>

        {/* ======== Table ========== */}
        <table className='w-full max-h-96 overflow-y-scroll'>
          <thead>
            <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242] px-[10px]'>
              <th className='py-[15px] px-[10px] font-medium'></th>
              <th className='py-[15px] px-[10px] font-medium'>Request</th>
              <th className='py-[15px] px-[10px] font-medium'>Type</th>
              <th className='py-[15px] px-[10px] font-medium'>Requester</th>
              <th className='py-[15px] px-[10px] font-medium'>Status</th>
              <th className='py-[15px] px-[10px] font-medium'>NMI's</th>
              <th className='py-[15px] px-[10px] font-medium'>Completed Date</th>
              <th className='py-[15px] px-[10px] font-medium'>Completed By</th>
              <th className='py-[15px] font-medium'></th>
            </tr>
          </thead>
          <tbody>
            {

              // No requests found
              allEnergySupplyRequests.filter((request) => request.status === 'Completed')?.length === 0 ?
                (

                  <tr>
                    <td colSpan={8}>
                      <div className='text-center p-[2%]'>
                        No results found.
                      </div>
                    </td>
                  </tr>

                )
                :
                (

                  // Map in each business request
                  allEnergySupplyRequests.filter((request) => request.status === 'Completed')?.map((request, index) => (

                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b cursor-pointer hover:bg-[#F0F7F7]'>

                      <td className='py-[15px] px-[10px]'></td>

                      {/* Request */}
                      <td className='py-[15px] px-[10px] font-medium hover:underline' onClick={() => navigate(`/requests/${request?.energysupplyrequestid}`)}>
                        {request?.energysupplyrequestid}
                      </td>

                      {/* Request Type */}
                      <td className='py-[15px] px-[10px]'>
                        <div className='max-w-[250px] leading-normal whitespace-nowrap overflow-hidden text-ellipsis'>
                          {request?.requesttype}
                        </div>
                      </td>

                      {/* Requester */}
                      <td className='py-[15px] px-[10px]'>
                        {request?.createdby}
                      </td>

                      {/* Status */}
                      <td className='pt-[15px] pb-[10px] px-[10px]'>
                        <StatusLabel status={request?.status}></StatusLabel>
                      </td>

                      {/* NMI's */}
                      <td className='py-[15px] px-[10px]'>
                        <ul>
                          {
                            request?.nmis?.map((object, index) => (
                              <li key={index} className='list-disc'>{object?.id}</li>
                            ))
                          }
                        </ul>
                      </td>

                      {/* Completed Date */}
                      <td className='py-[15px] px-[10px]'>
                        {ConvertDate(request?.modified)?.split(',')[0]}
                      </td>

                      {/* Completed By */}
                      <td className='py-[15px] px-[10px]'>
                        {request?.modifiedby}
                      </td>

                    </tr>

                  ))

                )

            }
          </tbody>
        </table>

      </div>

    </div>

  )

}
