/**
 * Converts a Firestore timestamp to a date string
 * 
 * @param {timestamp} date
 * @param {string} locale - <OPTIONAL> set a locale or use system default
 * @param {object} options - <OPTIONAL> metadata to format date e.g. long date, short date, etc
 * 
 * ```
 * const locale = 'en-AU';
 * const shortDateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
 *
 * <td>
 *   {ConvertDate(object?.enddate)}
 * </td>
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/reference/js/v8/firebase.firestore.Timestamp
 */

import { Timestamp } from 'firebase/firestore';

export default function ConvertDate(date, locale, options){

    try {
        
        // If date is Firestore timestamp
        if (date instanceof Timestamp) {

            return date.toDate().toLocaleString(locale, options);

        }

        // If date is date object
        if (typeof(date) == 'object') {

            return date.toLocaleString(locale, options);

        }

    } catch (error) {
        
        return error
        
    }
     
}

//--------------------------------------------------------