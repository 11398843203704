//================================================================
//  Component: Meter Table - Child component of NewEnergyRequestForm.js
//================================================================

//  Purpose: Shows the new meters from the the parent useReducer

//  Properties:
//    - formData = {useReducer, partner useReducer formData}
//    - setFormData = {useReducer, partner useReducer setFormData}

//  MeterTable:
//    <MeterTable
//      formData={formData}
//      setFormData={setFormData}
//    ></MeterTable>    

//================================================================


//Libraries
import React, { useState } from 'react';

//Contexts

//Components
import AddMeter from './AddMeter';

//Functions

//Images
import AddIcon from '../../../../../Components/Images/Icon_Add_Teal.svg';
import DeleteIcon from '../../../../../Components/Images/Icon_Delete_Red.svg';

//CSS


export default function MeterTable({
    formData,
    setFormData
}) {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    const [openModal, setOpenModal] = useState(false);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <>
       
            <label className='font-medium'>1. Provide each existing NMI <span className='text-[#C4314B]'>* </span></label>

            <button className='Primary-Button w-fit mt-2' onClick={() => setOpenModal(true)}> Add Existing Meter </button>

            <div className='Table-Container mt-3'>

                <table>
                    <thead>
                        <tr className='border-b-[#D8D8D8] border-solid border-b-2'>
                            <th className='py-[15px] px-[20px] font-medium'>NMI</th>
                            <th className='py-[15px] px-[20px] font-medium'>Consumption (kWh)</th>
                            <th className='py-[15px] px-[20px] font-medium'>Address Line 1</th>
                            <th className='py-[15px] px-[20px] font-medium'>Address Line 2</th>
                            <th className='py-[15px] px-[20px] font-medium'>City</th>
                            <th className='py-[15px] px-[20px] font-medium'>State</th>
                            <th className='py-[15px] px-[20px] font-medium'>Post Code</th>
                            <th className='py-[15px] px-[20px] font-medium'>
                                <img className='cursor-pointer' src={AddIcon} alt='Add-Icon' onClick={() => setOpenModal(true)}></img>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            formData?.nmis?.length === 0 ? (

                                <tr className='border-b-[#C8C8C9] border-solid border-b last:border-none'>
                                    <td className='text-center p-[2%]' colSpan={7}>No NMI's found, click on <b>+</b> to add an existing meter</td>
                                </tr>

                            ) : (

                                formData?.nmis?.map((object, index) => (
                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-none'>
                                        <td className='py-[15px] px-[20px]'>{object?.id}</td>
                                        <td className='py-[15px] px-[20px]'>{object?.expectedconsumption}</td>
                                        <td className='py-[15px] px-[20px]'>{object?.streetaddressline1}</td>
                                        <td className='py-[15px] px-[20px]'>{object?.streetaddressline2}</td>
                                        <td className='py-[15px] px-[20px]'>{object?.city}</td>
                                        <td className='py-[15px] px-[20px]'>{object?.state}</td>
                                        <td className='py-[15px] px-[20px]'>{object?.postcode}</td>
                                        <td className='py-[15px] px-[20px]'>
                                            <img className='cursor-pointer' src={DeleteIcon} alt='Delete-Icon' onClick={() => {

                                                // Remove element from array
                                                formData.nmis.splice(index, 1);

                                                setFormData({
                                                    'nmis': [...formData.nmis]
                                                });

                                            }}></img>
                                        </td>
                                    </tr>
                                ))
                            )

                        }
                    </tbody>
                </table>

            </div>

            <AddMeter
                formData={formData}
                setFormData={setFormData}
                openModal={openModal}
                setOpenModal={setOpenModal}
            ></AddMeter>

        </>
    )
}
