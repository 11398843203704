//================================================================
//  Page: New Energy Request Form
//================================================================

// Purpose: Allows the Business Lead/EPM to create new NMI

//  Business Lead: 
//    - CAN submit a 'New Energy Supply' Form

//  EPM: 
//    - CAN submit a 'New Energy Supply' Form

//================================================================

// Libraries
import React, { useContext, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

// Functions
import WriteDocument from '../../../../Library/WriteDocument';

//Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../../../Components/Breadcrumbs/BreadCrumbs';
import MeterTable from './MeterTable/MeterTable';
import BusinessUnit from '../../../../Components/EnergySupplyComponents/BusinessUnit/BusinessUnit';
import ContractingEntity from '../../../../Components/EnergySupplyComponents/ContractingEntity/ContractingEntity';
import Trust from '../../../../Components/EnergySupplyComponents/Trust/Trust';
import Fund from '../../../../Components/EnergySupplyComponents/Fund/Fund';

// Images
import Tick from '../../../../Components/Images/Icon_Tick_DarkTeal.svg';
import WarningIcon from '../../../../Components/Images/Icon_Warning_Yellow.svg';

//CSS


export default function NewEnergyRequestForm() {

    // --------------------------------------------------
    //  useContext and React Router
    // --------------------------------------------------

    const getUser = useContext(GetUser);
    const navigate = useNavigate();

    // --------------------------------------------------
    //  useStates
    // --------------------------------------------------

    // Used to save page status >  'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Used to determine if the confirmation box has been checked
    const [confirmationChecked, setConfirmationChecked] = useState(false);

    // --------------------------------------------------
    //  useReducer
    // --------------------------------------------------

    // Used to store the form inputs
    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

            'nmiAssigned': '',
            'nmiAssignedError': '',

            // Array of NMI's
            'nmis': [],

            'businessunit': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },
            'businessunitError': '',

            'contractingentity': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'abn': '',
                'businessunit': {},
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'givenname': '',
                'surname':  '',
                'emailaddress': '',
                'contactnumber': '',
                
                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },

            'trust': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'abn': '',
                'contractingentity': {},
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },

            // 'fund' doc ref
            'fund': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'abn': '',
                'trust': {},
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },

            'energystart': '', // date-string
            'energystartError': '',

            'sitecontactGivenname': '',
            'sitecontactGivennameError': '',
            'sitecontactSurname': '',
            'sitecontactSurnameError': '',
            'sitecontactEmailaddress': '',
            'sitecontactEmailaddressError': '',
            'sitecontactContactnumber': '',
            'sitecontactContactnumberError': '',

            'primarybillingGivenname': '',
            'primarybillingGivennameError': '',
            'primarybillingSurname': '',
            'primarybillingSurnameError': '',
            'primarybillingEmailaddress': '',
            'primarybillingEmailaddressError': '',
            'primarybillingContactnumber': '',
            'primarybillingContactnumberError': '',

        }
    );

    // --------------------------------------------------
    //  Functions
    // --------------------------------------------------

    //  Submit Handler
    function handleSubmit() {

        setPageStatus('pending');

        //=================================================
        //  Prepare Document for Firestore Write
        //=================================================

        const energySupplyRequestId = `es-${Date.now()}`;

        const energySupplyRequestDoc = {
            'energysupplyrequestid': energySupplyRequestId,

            'createdby': getUser.emailaddress,
            'created': new Date(),
            'modifiedby': '', // emailaddress
            'modified': '',
            'status': 'Under Review', // Under Review, Completed

            'nmis': formData.nmis,

            'requesttype': 'New Meter', // 'New Meter', 'Modify Meter'

            'agreement': {
                'id': '',
                'name': '',
            },

            'businessunit': {
                'type': formData.businessunit.type,
                'id': formData.businessunit.id,
                'name': formData.businessunit.name,
                'address': {
                    'streetaddressline1': formData.businessunit.address.streetaddressline1,
                    'streetaddressline2': formData.businessunit.address.streetaddressline2,
                    'city': formData.businessunit.address.city,
                    'state': formData.businessunit.address.state,
                    'postcode': formData.businessunit.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'contractingentity': {
                'type': formData.contractingentity.type,
                'id': formData.contractingentity.id,
                'name': formData.contractingentity.name,
                'abn': formData.contractingentity.abn,
                'businessunit': [
                    {
                        'id': formData.businessunit.id,
                        'name': formData.businessunit.name,
                    }
                ],
                'address': {
                    'streetaddressline1': formData.contractingentity.address.streetaddressline1,
                    'streetaddressline2': formData.contractingentity.address.streetaddressline2,
                    'city': formData.contractingentity.address.city,
                    'state': formData.contractingentity.address.state,
                    'postcode': formData.contractingentity.address.postcode,
                },
                'primarybillingcontact': {
                    'givenname': formData.contractingentity.givenname,
                    'surname': formData.contractingentity.surname,
                    'emailaddress': formData.contractingentity.emailaddress,
                    'contactnumber': formData.contractingentity.contactnumber,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'trust': {
                'type': formData.trust.type,
                'id': formData.trust.id,
                'name': formData.trust.name,
                'abn': formData.trust.abn,
                'contractingentity': [
                    {
                        'id': formData.contractingentity.id,
                        'name': formData.contractingentity.name,
                    }
                ],
                'address': {
                    'streetaddressline1': formData.trust.address.streetaddressline1,
                    'streetaddressline2': formData.trust.address.streetaddressline2,
                    'city': formData.trust.address.city,
                    'state': formData.trust.address.state,
                    'postcode': formData.trust.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'fund': {
                'type': formData.fund.type,
                'id': formData.fund.id,
                'name': formData.fund.name,
                'abn': formData.fund.abn,
                'trust': [
                    {
                        'id': formData.trust.id,
                        'name': formData.trust.name,
                    }
                ],
                'address': {
                    'streetaddressline1': formData.fund.address.streetaddressline1,
                    'streetaddressline2': formData.fund.address.streetaddressline2,
                    'city': formData.fund.address.city,
                    'state': formData.fund.address.state,
                    'postcode': formData.fund.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'energystart': formData.energystart, // date-string

            'primarysitecontact': {
                'givenname': formData.sitecontactGivenname,
                'surname': formData.sitecontactSurname,
                'emailaddress': formData.sitecontactEmailaddress,
                'contactnumber': formData.sitecontactContactnumber,
            },

            'primarybillingcontact': {
                'givenname': formData.primarybillingGivenname,
                'surname': formData.primarybillingSurname,
                'emailaddress': formData.primarybillingEmailaddress,
                'contactnumber': formData.primarybillingContactnumber,
            },
        
        };

        WriteDocument('energysupplyrequests', energySupplyRequestId, energySupplyRequestDoc, false).then(() => {

            setPageStatus('success');

        }).catch((error) => {

            console.log(error);
            setPageStatus('error-fatal');

        })

    }

    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

    return (
        <PageComponent
            header={
                pageStatus !== 'success' &&
                <div className='Page-Header-Container'>

                    {/* Breadcrumbs */}
                    <BreadCrumbs
                        rootLinkName={'Requests'}
                        childLinkName={'Energy Supply Request Form'}
                    ></BreadCrumbs>

                    {/* Header */}
                    <p className='font-medium text-[20px]'>Welcome to the Energy Supply Request Form</p>

                    <p className='text-base'>
                        This is the first step in having your Energy Supply Request submit to Energy Procurement Manager and be assessed.
                        <br></br>
                        <br></br>

                        This is an automated process that follows a path that is determined by the answers you provide on this form.
                        You will be supported along this path by your Energy Procurement Manager who will be notified and will contact you once you complete this questionnaire in full.
                        Please note that making this request does not signify approval, but it does set in motion an automated review and approval process that is aligned with Regional and Group Limits of Authority.
                        <br></br>
                        <br></br>

                        Funding for any approved energy supply must come from the project or Business Unit, and final approval will be through Energy Procurement Manager, so it is important to work with your Project Lead and the Energy Procurement Manager.
                        <br></br>
                        <br></br>

                        Energy Procurement Manager will contact you and follow up on the details of the energy supply contract once they liaise and negotiate with the energy supply vendors.
                    </p>

                </div>
            }
            requiredRoles={['isLLUser']}
            requiredRolesValue={true}
            status={pageStatus}
            body={
                <div className='p-[2%]'>
                    <div className='Page-Section-Container'>

                        <div className='FormComponent'>

                            {/* ========================================= */}
                            {/*  1. Do you have a NMI assigned? *         */}
                            {/* ========================================= */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>1. Do you have a NMI assigned? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                    <label className='Radio-Buttons-Container'>
                                        Yes
                                        <input
                                            type='radio'
                                            checked={formData.nmiAssigned === 'Yes'}
                                            onChange={() => setFormData({ 'nmiAssigned': 'Yes' })}
                                        ></input>
                                        <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                    </label>

                                    <label className='Radio-Buttons-Container'>
                                        No
                                        <input
                                            type='radio'
                                            checked={formData.nmiAssigned === 'No'}
                                            onChange={() => setFormData({ 'nmiAssigned': 'No' })}
                                        ></input>
                                        <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                    </label>
                                </div>
                            </div>

                            {/* ========================================= */}
                            {/*  1. Do you have a NMI assigned? *         */}
                            {/*  Answer: No                               */}
                            {/* ========================================= */}

                            <div hidden={formData.nmiAssigned === 'Yes' || formData.nmiAssigned === ''} className='FormComponent-Row mb-4'>

                                <div className='flex flex-row gap-2 items-center bg-[#FFF5D2] border-l-4 border-solid border-[#E4BA25] w-fit p-4 rounded'>
                                    <img className='w-[28px]' src={WarningIcon} alt='Warning-Icon'></img>
                                    Unfortunately our Energy Procurement team will not be able to assist without completing the following prerequisite, <a className='text-[#028E8C] font-semibold w-fit' href='https://www.google.com' target='_blank' rel='noopener noreferrer'>Energy Supply Onboarding - 2024.</a>
                                </div>

                            </div>

                            {/* ========================================= */}
                            {/*  1. Do you have a NMI assigned? *         */}
                            {/*  Answer: Yes                              */}
                            {/*  Proceed with form!                       */}
                            {/* ========================================= */}

                            <div hidden={formData.nmiAssigned === 'No' || formData.nmiAssigned === ''}>

                                {/* ========================================= */}
                                {/*  Provide each new NMI *                   */}
                                {/*  - Adds items to the table                */}
                                {/* ========================================= */}

                                <MeterTable
                                    formData={formData}
                                    setFormData={setFormData}
                                ></MeterTable>

                                {/* ========================================= */}
                                {/*  2. Select the Lendlease Business Unit *  */}
                                {/* ========================================= */}

                                <BusinessUnit
                                    formData={formData}
                                    setFormData={setFormData}
                                ></BusinessUnit>

                                {/* ==== 2a. Contracting Entity <Optional> ==== */}
                                <ContractingEntity
                                    formData={formData}
                                    setFormData={setFormData}
                                ></ContractingEntity>

                                {/* ==== 2b. Trust <Optional> ==== */}
                                <Trust
                                    formData={formData}
                                    setFormData={setFormData}
                                ></Trust>

                                {/* ==== 2c. Fund <Optional> ==== */}
                                <Fund
                                    formData={formData}
                                    setFormData={setFormData}
                                ></Fund>

                                {/* ================================================== */}
                                {/*  3. When does energy need to start being provided? */}
                                {/* ================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'>3. When does energy need to start being provided? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        className={formData.energystartError.length === 0 ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                                        type='date'
                                        onChange={(e) => setFormData({ 'energystart': e.target.value })}
                                        value={formData.energystart}
                                        min={new Date().toISOString().split('T')[0]}
                                    ></input>
                                </div>

                                {/* ================================================== */}
                                {/*  4. Who is the primary site contact?               */}
                                {/* ================================================== */}

                                <div className='FormComponent-Row'>

                                    <label className='font-medium'>4. Who is the primary site contact? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                                    <div className='border border-solid border-[#D8D8D8] rounded px-4  pb-4 pt-2 w-fit'>

                                        <div className='flex flex-row gap-2'>

                                            {/* First name */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>First name</label>
                                                <input
                                                    className={formData.sitecontactGivennameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'sitecontactGivenname': e.target.value })}
                                                    placeholder='Sally'
                                                    value={formData.sitecontactGivenname}
                                                ></input>
                                            </div>

                                            {/* Surname */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>Surname</label>
                                                <input
                                                    className={formData.sitecontactSurnameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'sitecontactSurname': e.target.value })}
                                                    placeholder='Smith'
                                                    value={formData.sitecontactSurname}
                                                ></input>
                                            </div>

                                        </div>
                                        <div className='flex flex-row gap-2'>

                                            {/* Email Address */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>Email Address</label>
                                                <input
                                                    className={formData.sitecontactEmailaddressError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'sitecontactEmailaddress': e.target.value })}
                                                    placeholder='sally.smith@lendlease.com'
                                                    value={formData.sitecontactEmailaddress}
                                                    type='email'
                                                ></input>
                                            </div>

                                            {/* Phone */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>Phone</label>
                                                <input
                                                    className={formData.sitecontactContactnumberError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'sitecontactContactnumber': e.target.value })}
                                                    placeholder='04xx xxx xxx'
                                                    value={formData.sitecontactContactnumber}
                                                    type='number'
                                                ></input>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                {/* ================================================== */}
                                {/*  5. Who is the primary billing contact?            */}
                                {/* ================================================== */}

                                <div className='FormComponent-Row'>

                                    <label className='font-medium'>5. Who is the primary billing contact? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                                    <div className='border border-solid border-[#D8D8D8] rounded px-4  pb-4 pt-2 w-fit'>
                                        <div className='flex flex-row gap-2'>

                                            {/* First name */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>First name</label>
                                                <input
                                                    className={formData.primarybillingGivennameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'primarybillingGivenname': e.target.value })}
                                                    placeholder='Sally'
                                                    value={formData.primarybillingGivenname}
                                                ></input>
                                            </div>

                                            {/* Surname */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>Surname</label>
                                                <input
                                                    className={formData.primarybillingSurnameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'primarybillingSurname': e.target.value })}
                                                    placeholder='Smith'
                                                    value={formData.primarybillingSurname}
                                                ></input>
                                            </div>

                                        </div>
                                        <div className='flex flex-row gap-2'>

                                            {/* Email Address */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>Email Address</label>
                                                <input
                                                    className={formData.primarybillingEmailaddressError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'primarybillingEmailaddress': e.target.value })}
                                                    placeholder='sally.smith@lendlease.com'
                                                    value={formData.primarybillingEmailaddress}
                                                    type='email'
                                                ></input>
                                            </div>

                                            {/* Phone */}
                                            <div className='flex flex-col'>
                                                <label className='font-medium'>Phone</label>
                                                <input
                                                    className={formData.primarybillingContactnumberError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                    onChange={(e) => setFormData({ 'primarybillingContactnumber': e.target.value })}
                                                    placeholder='04xx xxx xxx'
                                                    value={formData.primarybillingContactnumber}
                                                    type='number'
                                                ></input>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                {/* ===================================== */}
                                {/*  Checkbox                             */}
                                {/* ===================================== */}
                                                        
                                <hr className='mt-4'></hr>

                                <div className='flex flex-row items-start gap-2 my-4 mx-2'>

                                    <label className='Checkbox-Container'>
                                        <input
                                            required
                                            type='checkbox'
                                            name='confirmation'
                                            checked={confirmationChecked}
                                            onChange={() => setConfirmationChecked(!confirmationChecked)}
                                        ></input>
                                        <span className='Checkbox'></span>
                                    </label>

                                    <label>
                                        I understand the impact of the above changes and by selecting <strong>Add</strong>, I am applying these changes to the Asset Inventory.
                                    </label>

                                </div>

                            </div>

                            {/* ===================================== */}
                            {/*  Submit Buttons                       */}
                            {/* ===================================== */}

                            <div className='FormComponent-Row flex flex-row gap-2'>

                                <button className='Primary-Button w-fit' disabled={
                                    formData.nmis.length === 0 ||
                                    formData.businessunit?.id === undefined ||
                                    formData.energystart?.length === 0 ||
                                    formData?.sitecontactGivenname?.length === 0 ||
                                    formData?.sitecontactSurname?.length === 0 ||
                                    formData?.sitecontactEmailaddress?.length === 0 ||
                                    formData?.sitecontactContactnumber?.length === 0 ||
                                    formData?.primarybillingGivenname?.length === 0 ||
                                    formData?.primarybillingSurname?.length === 0 ||
                                    formData?.primarybillingEmailaddress?.length === 0 ||
                                    formData?.primarybillingContactnumber?.length === 0 || 
                                    confirmationChecked === false
                                } onClick={() => handleSubmit()}>
                                    Submit
                                </button>
                                <button className='Secondary-Button w-fit' onClick={() => navigate('/requests')}>
                                    Cancel
                                </button>

                            </div>

                        </div>

                    </div>
                </div>
            }
            successContent={
                <div className='p-[2%]'>
                    <div className='Page-Section-Container' style={{ padding: '3%' }}>

                        <div className='flex flex-row gap-[12px] items-center'>
                            <img className='m-0 p-0' src={Tick} alt='Complete'></img>
                            <h4 className='my-2 text-[#007A78]'>Thank You</h4>
                        </div>

                        <p className='font-medium text-[18px] my-3'>Your request has been successfully submitted</p>

                        <p className='text-base'>
                            This is the first step in having your Energy Supply Request submit to Energy Procurement Manager and be assessed.
                            <br></br>
                            <br></br>

                            This is an automated process that follows a path that is determined by the answers you provide on this form.
                            You will be supported along this path by your Energy Procurement Manager who will be notified and will contact you once you complete this questionnaire in full.
                            Please note that making this request does not signify approval, but it does set in motion an automated review and approval process that is aligned with Regional and Group Limits of Authority.
                            <br></br>
                            <br></br>

                            Funding for any approved energy supply must come from the project or Business Unit, and final approval will be through Energy Procurement Manager, so it is important to work with your Project Lead and the Energy Procurement Manager.
                            <br></br>
                            <br></br>

                            Energy Procurement Manager will contact you and follow up on the details of the energy supply contract once they liaise and negotiate with the energy supply vendors.
                        </p>

                        <button className='Primary-Button' onClick={() => navigate('/requests')}> Return Home </button>

                    </div>
                </div>
            }
        ></PageComponent>
    )

}
