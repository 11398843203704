//================================================================
//  Application:AES Portal
//================================================================

//  Product Owner: Gary Searle
//  Created 15/02/24

//================================================================

//Libraries
import React from 'react';
import { Routes, Route } from 'react-router-dom';

//Contexts

//Pages
import NotFound from './Pages/NotFound/NotFound.js';

import Requests from './Pages/Requests/Requests.js';
import NewEnergyRequestForm from './Pages/Requests/Pages/NewEnergyRequestForm/NewEnergyRequestForm.js';
import ModifyEnergySupplyForm from './Pages/Requests/Pages/ModifyEnergySupplyForm/ModifyEnergySupplyForm.js';
import EnergySupplyRequestsId from  './Pages/Requests/Pages/EnergySupplyRequestsId.js';

import AssetsInventory from './Pages/AssetsInventory/AssetsInventory.js';
import MetersDetailsPage from './Pages/AssetsInventory/Meters/DetailsPage.js';
import RetailerDetailsPage from './Pages/AssetsInventory/Retailers/DetailsPage.js';
import BusinessUnitDetailsPage from './Pages/AssetsInventory/BusinessUnits/DetailsPage.js';
import ContractEntityDetailsPage from './Pages/AssetsInventory/ContractingEntities/DetailsPage.js';
import TrustDetailsPage from './Pages/AssetsInventory/Trusts/DetailsPage.js';
import FundDetailsPage from './Pages/AssetsInventory/Funds/DetailsPage.js';

//Components
import Navbar from './Components/Navbar/Navbar';
import GlobalToast from './Components/GlobalToast/GlobalToast.js';
import Footer from './Components/Footer/Footer.js';

//Styles
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';


export default function App() {

  //------------------------------------------------------
  //  Return HTML
  //------------------------------------------------------

  return (
    <div className='App-Container'>

      {/* Nav */}
      <div className='App-Container-Nav'>
        <Navbar></Navbar>
      </div>

      {/* Toast */}
      <GlobalToast></GlobalToast>

      {/* Body > Contains all pages in the app  */}
      <div className='App-Container-Body'>
       
        <Routes>

          {/* Requests */}
          <Route path='/' element={<Requests />} />
          <Route path='/requests' element={<Requests />} />
          <Route path='/requests/newenergyrequestform' element={<NewEnergyRequestForm />} />
          <Route path='/requests/modifyenergysupplyform' element={<ModifyEnergySupplyForm />} />
          <Route path='/requests/:id' element={<EnergySupplyRequestsId />} />
        
          {/* Asset Inventory */}
          <Route path='/assets' element={<AssetsInventory/>} />
          <Route path='/assets/meters' element={<AssetsInventory/>} />
          <Route path='/assets/meters/:id' element={<MetersDetailsPage/>} />
          <Route path='/assets/retailers' element={<AssetsInventory/>} />
          <Route path='/assets/retailers/:id' element={<RetailerDetailsPage/>} />
          <Route path='/assets/businessunits' element={<AssetsInventory/>} />
          <Route path='/assets/businessunits/:id' element={<BusinessUnitDetailsPage/>} />
          <Route path='/assets/contractingentities' element={<AssetsInventory/>} />
          <Route path='/assets/contractingentities/:id' element={<ContractEntityDetailsPage/>} />
          <Route path='/assets/trusts' element={<AssetsInventory/>} />
          <Route path='/assets/trusts/:id' element={<TrustDetailsPage/>} />
          <Route path='/assets/funds' element={<AssetsInventory/>} />
          <Route path='/assets/funds/:id' element={<FundDetailsPage/>} />

          <Route path='*' element={<NotFound />}></Route>
          
        </Routes>

        <Footer></Footer>

      </div>

    </div>
  )

  //------------------------------------------------------
}