//================================================================
//  Component: Business Unit - Child component of NewEnergyRequestForm.js
//================================================================

//  Purpose: Shows the new meters from the the parent useReducer

//  Properties:
//    - formData = {useReducer, partner useReducer formData}
//    - setFormData = {useReducer, partner useReducer setFormData}

//  BusinessUnit:
//    <BusinessUnit
//      formData={formData}
//      setFormData={setFormData}
//    ></BusinessUnit>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import { SetToast } from '../../../Library/GlobalContexts';

//Components
import AddBusinessUnit from './AddBusinessUnit';

//Functions
import QueryDocument from '../../../Library/QueryDocument';

//Images

//CSS


export default function BusinessUnit({
    formData,
    setFormData,
    disabled,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast)

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Holds the business units
    const [businessUnits, setBusinessUnits] = useState([]);

    const [openForm, setOpenForm] = useState(formData.businessunit.type === 'new' ? true : false);

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onLoad
    //  1. Get all business units
    useEffect(() => {

        QueryDocument('businessunits', [
            ['status', '==', 'active']
        ]).then((docs) => {

            setBusinessUnits(docs);

        }).catch((error) => {

            console.log('error', error);

            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to retrieve business units. Try again later.',
            });

        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='FormComponent-Row'>

            <label className='font-medium'>2. Select the Lendlease Business Unit <span className='text-[#C4314B]'>* </span></label>

            <div hidden={openForm} className='flex flex-row gap-2'>
                <select
                    className={formData.businessunitError.length === 0 ? ('Input-Field-Select') : ('Input-Field-Select-Error')}
                    disabled={disabled}
                    value={formData.businessunit.name}
                    onChange={(e) => {

                        const doc = businessUnits.find((object) => e.target.value === object.name);

                        // Select the correct Business Unit from Firestore!
                        setFormData({
                            'businessunit': {
                                'type': 'existing',
                                'id': doc?.id,
                                'name': doc?.name,
                                'address': {
                                    'streetaddressline1': doc?.address?.streetaddressline1,
                                    'streetaddressline2': doc?.address?.streetaddressline2,
                                    'city': doc?.address?.city,
                                    'state': doc?.address?.state,
                                    'postcode': doc?.address?.postcode,
                                },
                                'createdby': doc?.createdby, // emailaddress
                                'created': doc?.created,
                                'modifiedby': doc?.modifiedby,
                                'modified': doc?.modified,
                                'status': doc?.status, // active or decommissioned
                            },

                            // Clear all children, as the parent was updated
                            'contractingentity': {
                                'type': 'existing', // new or existing

                                // ======== EXISTING & NEW ======== //
                                'id': '',
                                'name': '',
                
                                // ========   NEW    ======== //
                                'abn': '',
                                'businessunit': {},
                                'address': {
                                    'streetaddressline1': '',
                                    'streetaddressline2': '',
                                    'city': '',
                                    'state': '',
                                    'postcode': '',
                                },
                                'givenname': '',
                                'surname':  '',
                                'emailaddress': '',
                                'contactnumber': '',
                                
                                'createdby': '', // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            },
                            'trust': {
                                'type': 'existing', // new or existing

                                // ======== EXISTING & NEW ======== //
                                'id': '',
                                'name': '',
                
                                // ========   NEW    ======== //
                                'abn': '',
                                'contractingentity': {},
                                'address': {
                                    'streetaddressline1': '',
                                    'streetaddressline2': '',
                                    'city': '',
                                    'state': '',
                                    'postcode': '',
                                },
                                'createdby': '', // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            },
                            'fund': {
                                'type': 'existing', // new or existing

                                // ======== EXISTING & NEW ======== //
                                'id': '',
                                'name': '',
                
                                // ========   NEW    ======== //
                                'abn': '',
                                'trust': {},
                                'address': {
                                    'streetaddressline1': '',
                                    'streetaddressline2': '',
                                    'city': '',
                                    'state': '',
                                    'postcode': '',
                                },
                                'createdby': '', // emailaddress
                                'created': new Date(),
                                'modifiedby': '', // emailaddress
                                'modified': '',
                                'status': 'active', // active or decommissioned
                            }
                        });

                    }}
                >
                    <>
                        <option className='pt-3' value=''>-</option>

                        {

                            // Map in each BU from Firestore
                            businessUnits.map((doc, index) => (

                                <option className='pt-3' key={index} value={doc.name}>{doc.name}</option>

                            ))

                        }
                    </>

                </select>

                {/* + Add New */}
                <button hidden={disabled} className='Primary-Button w-fit' onClick={() => {

                    // Clear all children, as the parent is 'new'
                    setFormData({
                        'contractingentity': {
                            'type': 'existing', // new or existing

                            // ======== EXISTING & NEW ======== //
                            'id': '',
                            'name': '',
            
                            // ========   NEW    ======== //
                            'abn': '',
                            'businessunit': {},
                            'address': {
                                'streetaddressline1': '',
                                'streetaddressline2': '',
                                'city': '',
                                'state': '',
                                'postcode': '',
                            },
                            'givenname': '',
                            'surname':  '',
                            'emailaddress': '',
                            'contactnumber': '',
                            
                            'createdby': '', // emailaddress
                            'created': new Date(),
                            'modifiedby': '', // emailaddress
                            'modified': '',
                            'status': 'active', // active or decommissioned
                        },
                        'trust': {
                            'type': 'existing', // new or existing

                            // ======== EXISTING & NEW ======== //
                            'id': '',
                            'name': '',
            
                            // ========   NEW    ======== //
                            'abn': '',
                            'contractingentity': {},
                            'address': {
                                'streetaddressline1': '',
                                'streetaddressline2': '',
                                'city': '',
                                'state': '',
                                'postcode': '',
                            },
                            'createdby': '', // emailaddress
                            'created': new Date(),
                            'modifiedby': '', // emailaddress
                            'modified': '',
                            'status': 'active', // active or decommissioned
                        },
                        'fund': {
                            'type': 'existing', // new or existing

                            // ======== EXISTING & NEW ======== //
                            'id': '',
                            'name': '',
            
                            // ========   NEW    ======== //
                            'abn': '',
                            'trust': {},
                            'address': {
                                'streetaddressline1': '',
                                'streetaddressline2': '',
                                'city': '',
                                'state': '',
                                'postcode': '',
                            },
                            'createdby': '', // emailaddress
                            'created': new Date(),
                            'modifiedby': '', // emailaddress
                            'modified': '',
                            'status': 'active', // active or decommissioned
                        }
                    });

                    setOpenForm(true);

                }}>+ Add New</button>
            
            </div>

            <AddBusinessUnit
                formData={formData}
                setFormData={setFormData}
                openForm={openForm}
                setOpenForm={setOpenForm}
                disabled={disabled}
            ></AddBusinessUnit>

        </div>
    )

}
