//================================================================
//  Page: New Energy Request Overview
//================================================================

// Purpose: Allows the EPM review the request of an new NMI(s)

//  Business Lead: 
//    - CAN view 'New Energy Supply Overview'

//  EPM: 
//    - CAN modify 'New Energy Supply Overview' with the status of 'Under Review'
//    - CAN NOT modify 'New Energy Supply Overview' with the status of 'Completed'

//================================================================

// Libraries
import React, { useContext, useState, useReducer, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

// Functions
import WriteDocument from '../../../../Library/WriteDocument';

//Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import MeterTable from './MeterTable/MeterTable';
import BusinessUnit from '../../../../Components/EnergySupplyComponents/BusinessUnit/BusinessUnit';
import ContractingEntity from '../../../../Components/EnergySupplyComponents/ContractingEntity/ContractingEntity';
import Trust from '../../../../Components/EnergySupplyComponents/Trust/Trust';
import Fund from '../../../../Components/EnergySupplyComponents/Fund/Fund';

// Images
import WarningIcon from '../../../../Components/Images/Icon_Warning_Yellow.svg';
import QueryDocument from '../../../../Library/QueryDocument';

//CSS

export default function NewEnergyRequestOverview({
    energySupplyRequest
}) {

    // --------------------------------------------------
    //  useContext and React Router
    // --------------------------------------------------

    const getUser = useContext(GetUser);

    const navigate = useNavigate();

    // --------------------------------------------------
    //  useStates
    // --------------------------------------------------

    // Used to save page status >  'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Hold all active agreements
    const [agreements, setAgreements] = useState([]);

    // Used to determine if the confirmation box has been checked
    const [confirmationChecked, setConfirmationChecked] = useState(false);

    // --------------------------------------------------
    //  useReducer
    // --------------------------------------------------

    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

            'nmiAssigned': 'Yes',
            'nmiAssignedError': '',

            // Array of NMI's
            'nmis': energySupplyRequest.nmis,

            'businessunit': {
                'type': energySupplyRequest.businessunit.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.businessunit.id,
                'name': energySupplyRequest.businessunit.name,

                // ========   NEW    ======== //
                'address': {
                    'streetaddressline1': energySupplyRequest.businessunit.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.businessunit.address.streetaddressline2,
                    'city': energySupplyRequest.businessunit.address.city,
                    'state': energySupplyRequest.businessunit.address.state,
                    'postcode': energySupplyRequest.businessunit.address.postcode,
                },
                'createdby': energySupplyRequest.businessunit.createdby,
                'created': energySupplyRequest.businessunit.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.businessunit.status, // active or decommissioned
            },
            'businessunitError': '',

            'contractingentity': {
                'type': energySupplyRequest.contractingentity.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.contractingentity.id,
                'name': energySupplyRequest.contractingentity.name,

                // ========   NEW    ======== //
                'abn': energySupplyRequest.contractingentity.abn,
                'businessunit': energySupplyRequest.contractingentity.businessunit,
                'address': {
                    'streetaddressline1': energySupplyRequest.contractingentity.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.contractingentity.address.streetaddressline2,
                    'city': energySupplyRequest.contractingentity.address.city,
                    'state': energySupplyRequest.contractingentity.address.state,
                    'postcode': energySupplyRequest.contractingentity.address.postcode,
                },

                'givenname': energySupplyRequest.contractingentity.primarybillingcontact.givenname,
                'surname': energySupplyRequest.contractingentity.primarybillingcontact.surname,
                'emailaddress': energySupplyRequest.contractingentity.primarybillingcontact.emailaddress,
                'contactnumber': energySupplyRequest.contractingentity.primarybillingcontact.contactnumber,

                'createdby': energySupplyRequest.contractingentity.createdby,
                'created': energySupplyRequest.contractingentity.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.contractingentity.status, // active or decommissioned
            },

            'trust': {
                'type': energySupplyRequest.trust.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.trust.id,
                'name': energySupplyRequest.trust.name,

                // ========   NEW    ======== //
                'abn': energySupplyRequest.trust.abn,
                'contractingentity': energySupplyRequest.trust.contractingentity,
                'address': {
                    'streetaddressline1': energySupplyRequest.trust.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.trust.address.streetaddressline2,
                    'city': energySupplyRequest.trust.address.city,
                    'state': energySupplyRequest.trust.address.state,
                    'postcode': energySupplyRequest.trust.address.postcode,
                },
                'createdby': energySupplyRequest.trust.createdby,
                'created': energySupplyRequest.trust.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.trust.status, // active or decommissioned
            },

            'fund': {
                'type': energySupplyRequest.fund.type, // new or existing

                // ======== EXISTING & NEW ======== //
                'id': energySupplyRequest.fund.id,
                'name': energySupplyRequest.fund.name,

                // ========   NEW    ======== //
                'abn': energySupplyRequest.fund.abn,
                'trust': energySupplyRequest.fund.trust,
                'address': {
                    'streetaddressline1': energySupplyRequest.fund.address.streetaddressline1,
                    'streetaddressline2': energySupplyRequest.fund.address.streetaddressline2,
                    'city': energySupplyRequest.fund.address.city,
                    'state': energySupplyRequest.fund.address.state,
                    'postcode': energySupplyRequest.fund.address.postcode,
                },
                'createdby': energySupplyRequest.fund.createdby,
                'created': energySupplyRequest.fund.created,
                'modifiedby': '',
                'modified': '',
                'status': energySupplyRequest.fund.status, // active or decommissioned
            },

            'energystart': energySupplyRequest.energystart, // date-string
            'energystartError': '',

            'sitecontactGivenname': energySupplyRequest.primarysitecontact.givenname,
            'sitecontactGivennameError': '',
            'sitecontactSurname': energySupplyRequest.primarysitecontact.surname,
            'sitecontactSurnameError': '',
            'sitecontactEmailaddress': energySupplyRequest.primarysitecontact.emailaddress,
            'sitecontactEmailaddressError': '',
            'sitecontactContactnumber': energySupplyRequest.primarysitecontact.contactnumber,
            'sitecontactContactnumberError': '',

            'primarybillingGivenname': energySupplyRequest.primarybillingcontact.givenname,
            'primarybillingGivennameError': '',
            'primarybillingSurname': energySupplyRequest.primarybillingcontact.surname,
            'primarybillingSurnameError': '',
            'primarybillingEmailaddress': energySupplyRequest.primarybillingcontact.emailaddress,
            'primarybillingEmailaddressError': '',
            'primarybillingContactnumber': energySupplyRequest.primarybillingcontact.contactnumber,
            'primarybillingContactnumberError': '',

            'agreement': {
                'id': energySupplyRequest.agreement.id,
                'name': energySupplyRequest.agreement.name,
            },
            'agreementError': '',

        }
    );

    // --------------------------------------------------
    //  Functions
    // --------------------------------------------------

    //  Submit Handler
    function handleSubmit() {

        setPageStatus('pending');

        const docPromises = [];

        //=================================================
        //  Business Unit
        //=================================================

        if (formData.businessunit.type === 'new') {

            const businessUnitDoc = {
                'id': formData.businessunit.id,
                'name': formData.businessunit.name,
                'address': {
                    'streetaddressline1': formData.businessunit.address.streetaddressline1,
                    'streetaddressline2': formData.businessunit.address.streetaddressline2,
                    'city': formData.businessunit.address.city,
                    'state': formData.businessunit.address.state,
                    'postcode': formData.businessunit.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('businessunits', businessUnitDoc.id, businessUnitDoc, false),
            );

        }

        //=================================================
        //  Contracting Entity
        //=================================================

        if (formData.contractingentity.type === 'new') {

            const contractingEntityDoc = {
                'id': formData.contractingentity.id,
                'name': formData.contractingentity.name,
                'abn': formData.contractingentity.abn,
                'businessunit': {
                    'id': formData.businessunit.id,
                    'name': formData.businessunit.name,
                },
                'address': {
                    'streetaddressline1': formData.contractingentity.address.streetaddressline1,
                    'streetaddressline2': formData.contractingentity.address.streetaddressline2,
                    'city': formData.contractingentity.address.city,
                    'state': formData.contractingentity.address.state,
                    'postcode': formData.contractingentity.address.postcode,
                },
                'primarybillingcontact': {
                    'givenname': formData.contractingentity.givenname,
                    'surname': formData.contractingentity.surname,
                    'emailaddress': formData.contractingentity.emailaddress,
                    'contactnumber': formData.contractingentity.contactnumber,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('contractingentities', contractingEntityDoc.id, contractingEntityDoc, false),
            );

        }

        //=================================================
        //  Trust
        //=================================================

        if (formData.trust.type === 'new') {

            const trustDoc = {
                'id': formData.trust.id,
                'name': formData.trust.name,
                'abn': formData.trust.abn,
                'contractingentity': {
                    'id': formData.contractingentity.id,
                    'name': formData.contractingentity.name,
                },
                'address': {
                    'streetaddressline1': formData.trust.address.streetaddressline1,
                    'streetaddressline2': formData.trust.address.streetaddressline2,
                    'city': formData.trust.address.city,
                    'state': formData.trust.address.state,
                    'postcode': formData.trust.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('trusts', trustDoc.id, trustDoc, false),
            );
        }

        //=================================================
        //  Fund
        //=================================================

        if (formData.fund.type === 'new') {

            const fundDoc = {
                'id': formData.fund.id,
                'name': formData.fund.name,
                'abn': formData.fund.abn,
                'trust': {
                    'id': formData.trust.id,
                    'name': formData.trust.name,
                },
                'address': {
                    'streetaddressline1': formData.fund.address.streetaddressline1,
                    'streetaddressline2': formData.fund.address.streetaddressline2,
                    'city': formData.fund.address.city,
                    'state': formData.fund.address.state,
                    'postcode': formData.fund.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('funds', fundDoc.id, fundDoc, false),
            );

        }

        //=================================================
        //  NMI's
        //=================================================

        formData.nmis.forEach((nmi) => {

            const nmiDoc = {
                'id': nmi.id,
                'expectedconsumption': nmi.expectedconsumption,
                'energystart': formData.energystart, // date-string
                'transferdate': '', // date-string
                'decommissioneddate': '', // date-string
                'address': {
                    'streetaddressline1': nmi.streetaddressline1,
                    'streetaddressline2': nmi.streetaddressline2,
                    'city': nmi.city,
                    'state': nmi.state,
                    'postcode': nmi.postcode,
                },
                'primarysitecontact': {
                    'givenname': formData.sitecontactGivenname,
                    'surname': formData.sitecontactSurname,
                    'emailaddress': formData.sitecontactEmailaddress,
                    'contactnumber': formData.sitecontactContactnumber,
                },
                'primarybillingcontact': {
                    'givenname': formData.primarybillingGivenname,
                    'surname': formData.primarybillingSurname,
                    'emailaddress': formData.primarybillingEmailaddress,
                    'contactnumber': formData.primarybillingContactnumber,
                },
                'businessunit': {
                    'id': formData.businessunit.id,
                    'name': formData.businessunit.name,
                },
                'contractingentity': {
                    'id': formData.contractingentity.id,
                    'name': formData.contractingentity.name,
                },
                'trust': {
                    'id': formData.trust.id,
                    'name': formData.trust.name,
                },
                'fund': {
                    'id': formData.fund.id,
                    'name': formData.fund.name,
                },
                'agreement': {
                    'id': formData.agreement.id,
                    'name': formData.agreement.name,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            };

            docPromises.push(
                WriteDocument('nmis', nmiDoc.id, nmiDoc, false),
            );

        });


        //=================================================
        //  Settle Promises
        //=================================================

        Promise.all(docPromises).then(() => {

            //=================================================
            //  Update original business request
            //=================================================

            const document = {
                'status': 'Completed',
                'modified': new Date(),
                'modifiedby': getUser.emailaddress,

                'nmis': formData.nmis,
    
                'agreement': {
                    'id': formData.agreement.id,
                    'name': formData.agreement.name,
                },

                'businessunit': {
                    'type': formData.businessunit.type,
                    'id': formData.businessunit.id,
                    'name': formData.businessunit.name,
                    'address': {
                        'streetaddressline1': formData.businessunit.address.streetaddressline1,
                        'streetaddressline2': formData.businessunit.address.streetaddressline2,
                        'city': formData.businessunit.address.city,
                        'state': formData.businessunit.address.state,
                        'postcode': formData.businessunit.address.postcode,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'contractingentity': {
                    'type': formData.contractingentity.type,
                    'id': formData.contractingentity.id,
                    'name': formData.contractingentity.name,
                    'abn': formData.contractingentity.abn,
                    'businessunit': [
                        {
                            'id': formData.businessunit.id,
                            'name': formData.businessunit.name,
                        }
                    ],
                    'address': {
                        'streetaddressline1': formData.contractingentity.address.streetaddressline1,
                        'streetaddressline2': formData.contractingentity.address.streetaddressline2,
                        'city': formData.contractingentity.address.city,
                        'state': formData.contractingentity.address.state,
                        'postcode': formData.contractingentity.address.postcode,
                    },
                    'primarybillingcontact': {
                        'givenname': formData.contractingentity.givenname,
                        'surname': formData.contractingentity.surname,
                        'emailaddress': formData.contractingentity.emailaddress,
                        'contactnumber': formData.contractingentity.contactnumber,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'trust': {
                    'type': formData.trust.type,
                    'id': formData.trust.id,
                    'name': formData.trust.name,
                    'abn': formData.trust.abn,
                    'contractingentity': [
                        {
                            'id': formData.contractingentity.id,
                            'name': formData.contractingentity.name,
                        }
                    ],
                    'address': {
                        'streetaddressline1': formData.trust.address.streetaddressline1,
                        'streetaddressline2': formData.trust.address.streetaddressline2,
                        'city': formData.trust.address.city,
                        'state': formData.trust.address.state,
                        'postcode': formData.trust.address.postcode,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'fund': {
                    'type': formData.fund.type,
                    'id': formData.fund.id,
                    'name': formData.fund.name,
                    'abn': formData.fund.abn,
                    'trust': [
                        {
                            'id': formData.trust.id,
                            'name': formData.trust.name,
                        }
                    ],
                    'address': {
                        'streetaddressline1': formData.fund.address.streetaddressline1,
                        'streetaddressline2': formData.fund.address.streetaddressline2,
                        'city': formData.fund.address.city,
                        'state': formData.fund.address.state,
                        'postcode': formData.fund.address.postcode,
                    },
                    'createdby': getUser.emailaddress,
                    'created': new Date(),
                    'modifiedby': '',
                    'modified': '',
                    'status': 'active',
                },
    
                'energystart': formData.energystart, // date-string
    
                'primarysitecontact': {
                    'givenname': formData.sitecontactGivenname,
                    'surname': formData.sitecontactSurname,
                    'emailaddress': formData.sitecontactEmailaddress,
                    'contactnumber': formData.sitecontactContactnumber,
                },
    
                'primarybillingcontact': {
                    'givenname': formData.primarybillingGivenname,
                    'surname': formData.primarybillingSurname,
                    'emailaddress': formData.primarybillingEmailaddress,
                    'contactnumber': formData.primarybillingContactnumber,
                },

            }
            return WriteDocument('energysupplyrequests', energySupplyRequest.energysupplyrequestid, document, true).then(() => {


                navigate('/requests?view=completed');

            });

        }).catch((error) => {

            console.log(error);
            setPageStatus('error-fatal');

        });

    }

    // --------------------------------------------------
    //  useEffects
    // --------------------------------------------------

    // onLoad
    //  1. Get all agreements
    useEffect(() => {

        QueryDocument('agreements', [
            ['status', '==', 'active']
        ]).then((documents) => {

            setAgreements(documents);

        }).catch((error) => {

            console.log('Failed to get agreements', error);
            setPageStatus('error-fatal');

        });

    }, []);

    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

    return (
        <PageComponent
            requiredRoles={['isLLUser', 'isEPM']}
            requiredRolesValue={true}
            status={pageStatus}
            body={

                <div className='Page-Section-Container'>

                    <div className='FormComponent'>

                        {/* ========================================= */}
                        {/*  1. Do you have a NMI assigned? *         */}
                        {/* ========================================= */}

                        <div className='FormComponent-Row'>
                            <label className='font-medium'>1. Do you have a NMI assigned? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                <label className='Radio-Buttons-Container'>
                                    Yes
                                    <input
                                        type='radio'
                                        checked={formData.nmiAssigned === 'Yes'}
                                        onChange={() => setFormData({ 'nmiAssigned': 'Yes' })}
                                        disabled
                                    ></input>
                                    <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                </label>

                                <label className='Radio-Buttons-Container'>
                                    No
                                    <input
                                        type='radio'
                                        checked={formData.nmiAssigned === 'No'}
                                        onChange={() => setFormData({ 'nmiAssigned': 'No' })}
                                        disabled
                                    ></input>
                                    <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                </label>
                            </div>
                        </div>

                        {/* ========================================= */}
                        {/*  1. Do you have a NMI assigned? *         */}
                        {/*  Answer: No                               */}
                        {/* ========================================= */}

                        <div hidden={formData.nmiAssigned === 'Yes' || formData.nmiAssigned === ''} className='FormComponent-Row mb-4'>

                            <div className='flex flex-row gap-2 items-center bg-[#FFF5D2] border-l-4 border-solid border-[#E4BA25] w-fit p-4 rounded'>
                                <img className='w-[28px]' src={WarningIcon} alt='Warning-Icon'></img>
                                Unfortunately our Energy Procurement team will not be able to assist without completing the following prerequisite, <a className='text-[#028E8C] font-semibold w-fit' href='https://www.google.com' target='_blank' rel='noopener noreferrer'>Energy Supply Onboarding - 2024.</a>
                            </div>

                        </div>

                        {/* ========================================= */}
                        {/*  1. Do you have a NMI assigned? *         */}
                        {/*  Answer: Yes                              */}
                        {/*  Proceed with form!                       */}
                        {/* ========================================= */}

                        <div hidden={formData.nmiAssigned === 'No' || formData.nmiAssigned === ''}>

                            {/* ========================================= */}
                            {/*  Provide each new NMI *                   */}
                            {/*  - Adds items to the table                */}
                            {/* ========================================= */}

                            <MeterTable
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></MeterTable>

                            <br></br>

                            {/* ========================================= */}
                            {/*  2. Select the Lendlease Business Unit *  */}
                            {/* ========================================= */}

                            <BusinessUnit
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></BusinessUnit>

                            {/* ==== 2a. Contracting Entity <Optional> ==== */}
                            <ContractingEntity
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></ContractingEntity>

                            {/* ==== 2b. Trust <Optional> ==== */}
                            <Trust
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></Trust>

                            {/* ==== 2c. Fund <Optional> ==== */}
                            <Fund
                                formData={formData}
                                setFormData={setFormData}
                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                            ></Fund>

                            {/* ================================================== */}
                            {/*  3. When does energy need to start being provided? */}
                            {/* ================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>3. When does energy need to start being provided? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                <input
                                    className={formData.energystartError.length === 0 ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                                    type='date'
                                    onChange={(e) => setFormData({ 'energystart': e.target.value })}
                                    value={formData.energystart}
                                    min={new Date().toISOString().split('T')[0]}
                                    disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                ></input>
                            </div>

                            {/* ================================================== */}
                            {/*  4. Who is the primary site contact?               */}
                            {/* ================================================== */}

                            <div className='FormComponent-Row'>

                                <label className='font-medium'>4. Who is the primary site contact? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                                <div className='border border-solid border-[#D8D8D8] rounded px-4  pb-4 pt-2 w-fit'>

                                    <div className='flex flex-row gap-2'>

                                        {/* First name */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>First name</label>
                                            <input
                                                className={formData.sitecontactGivennameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'sitecontactGivenname': e.target.value })}
                                                placeholder='Sally'
                                                value={formData.sitecontactGivenname}
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                        {/* Surname */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>Surname</label>
                                            <input
                                                className={formData.sitecontactSurnameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'sitecontactSurname': e.target.value })}
                                                placeholder='Smith'
                                                value={formData.sitecontactSurname}
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                    </div>
                                    <div className='flex flex-row gap-2'>

                                        {/* Email Address */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>Email Address</label>
                                            <input
                                                className={formData.sitecontactEmailaddressError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'sitecontactEmailaddress': e.target.value })}
                                                placeholder='sally.smith@lendlease.com'
                                                value={formData.sitecontactEmailaddress}
                                                type='email'
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                        {/* Phone */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>Phone</label>
                                            <input
                                                className={formData.sitecontactContactnumberError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'sitecontactContactnumber': e.target.value })}
                                                placeholder='04xx xxx xxx'
                                                value={formData.sitecontactContactnumber}
                                                type='number'
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            {/* ================================================== */}
                            {/*  5. Who is the primary billing contact?            */}
                            {/* ================================================== */}

                            <div className='FormComponent-Row'>

                                <label className='font-medium'>5. Who is the primary billing contact? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                                <div className='border border-solid border-[#D8D8D8] rounded px-4  pb-4 pt-2 w-fit'>
                                    <div className='flex flex-row gap-2'>

                                        {/* First name */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>First name</label>
                                            <input
                                                className={formData.primarybillingGivennameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'primarybillingGivenname': e.target.value })}
                                                placeholder='Sally'
                                                value={formData.primarybillingGivenname}
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                        {/* Surname */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>Surname</label>
                                            <input
                                                className={formData.primarybillingSurnameError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'primarybillingSurname': e.target.value })}
                                                placeholder='Smith'
                                                value={formData.primarybillingSurname}
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                    </div>
                                    <div className='flex flex-row gap-2'>

                                        {/* Email Address */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>Email Address</label>
                                            <input
                                                className={formData.primarybillingEmailaddressError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'primarybillingEmailaddress': e.target.value })}
                                                placeholder='sally.smith@lendlease.com'
                                                value={formData.primarybillingEmailaddress}
                                                type='email'
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                        {/* Phone */}
                                        <div className='flex flex-col'>
                                            <label className='font-medium'>Phone</label>
                                            <input
                                                className={formData.primarybillingContactnumberError?.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                                onChange={(e) => setFormData({ 'primarybillingContactnumber': e.target.value })}
                                                placeholder='04xx xxx xxx'
                                                value={formData.primarybillingContactnumber}
                                                type='number'
                                                disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                            ></input>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            {/* ================================================== */}
                            {/*  6. Attach Energy Agreement                        */}
                            {/* ================================================== */}

                            <div className='FormComponent-Row'>

                                <label className='font-medium'>6. Attach Energy Agreement <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                                <select
                                    className={formData.agreementError.length === 0 ? ('Input-Field-Select') : ('Input-Field-Select-Error')}
                                    style={{width: '300px'}}
                                    disabled={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}
                                    value={formData.agreement.name}
                                    onChange={(e) => {

                                        const value = e.target.value;

                                        // Reset input
                                        if (value === '') {

                                            return setFormData({
                                                'agreement': {
                                                    'id': '', 
                                                    'name': ''
                                                },
                                                'agreementError': 'value required',
                                            });

                                        } 

                                        // Find and attached select agreement
                                        const agreement = agreements.find((object) => object.retailername === value);
                                        setFormData({
                                            'agreement': {
                                                'id': agreement?.id, 
                                                'name': agreement?.retailername
                                            },
                                            'agreementError': '',
                                        });
                                    
                                    }}
                                >
                                    <>
                                        <option className='pt-3' value=''>-</option>

                                        {

                                            // Map in each BU from Firestore
                                            agreements.map((doc, index) => (

                                                <option className='pt-3' key={index} value={doc.retailername}>{doc.retailername}</option>

                                            ))

                                        }
                                    </>

                                </select>

                            </div>

                        </div>

                        {/* ===================================== */}
                        {/*  Checkbox                             */}
                        {/* ===================================== */}
                                                
                        <hr></hr>

                        <div className='flex flex-row items-start gap-2 mb-4 mx-2' hidden={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'}>

                            <label className='Checkbox-Container'>
                                <input
                                    required
                                    type='checkbox'
                                    name='confirmation'
                                    checked={confirmationChecked}
                                    onChange={() => setConfirmationChecked(!confirmationChecked)}
                                ></input>
                                <span className='Checkbox'></span>
                            </label>

                            <label>
                                I understand the impact of the above changes and by selecting <strong>Add</strong>, I am applying these changes to the Asset Inventory.
                            </label>

                        </div>

                        {/* ===================================== */}
                        {/*  Submit Buttons                       */}
                        {/* ===================================== */}

                        <div className='FormComponent-Row flex flex-row gap-2'>

                            <button className='Primary-Button w-fit' hidden={getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed'} disabled={
                                formData.nmis.length === 0 ||
                                formData.businessunit?.id === undefined ||
                                formData.energystart?.length === 0 ||
                                formData?.sitecontactGivenname?.length === 0 ||
                                formData?.sitecontactSurname?.length === 0 ||
                                formData?.sitecontactEmailaddress?.length === 0 ||
                                formData?.sitecontactContactnumber?.length === 0 ||
                                formData?.primarybillingGivenname?.length === 0 ||
                                formData?.primarybillingSurname?.length === 0 ||
                                formData?.primarybillingEmailaddress?.length === 0 ||
                                formData?.primarybillingContactnumber?.length === 0 ||
                                formData?.agreement?.id?.length === 0 || 
                                confirmationChecked === false
                            } onClick={() => handleSubmit()}>
                                Add
                            </button>
                            <button className='Secondary-Button w-fit' onClick={() => getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed' ? navigate('/requests?view=completed') : navigate('/requests?view=underreview')}>
                                {
                                    getUser?.roles?.isEPM === false || energySupplyRequest.status === 'Completed' ?
                                        'Back'
                                        :
                                        'Cancel'
                                }
                            </button>

                        </div>

                    </div>

                </div>

            }
        ></PageComponent>
    )

}
