// Libraries
import React, { useContext } from 'react';
import App from '../App';
import { BrowserRouter } from 'react-router-dom';
import { getAuth} from 'firebase/auth';
import { GetAppStatus, GetAppErrors, SetAppStatus  } from './GlobalContexts';

// Components
import Login from './Login';

// Images
import LoadingIcon from '../Components/Images/Image_Loading_Ripple.svg';
import LLLogo from '../Components/Images/Icon_LLLogo_Green.png';

/**
 * Designed to handle the login process, handle failures and if success direct the user to the App.
 * 
 * @param {undefined} NotApplicable
 * 
 * ```
 * <LoginHandler>
 *     <App></App>
 * </LoginHandler>
 * 
 * ```
 * 
 * Documentation
 * - https://lendleasegroup.atlassian.net/wiki/spaces/ARI/pages/223369691843/AU+Region+-+How+to+deploy+an+environment#6.-The-joys-of-Azure-AD!
 */

export default function LoginHandler() {

  //------------------------------------------------------
  //  Import Firebase
  //------------------------------------------------------

    // eslint-disable-next-line
    const auth = getAuth();

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getAppStatus = useContext(GetAppStatus)
    const getAppErrors = useContext(GetAppErrors)
    const setAppStatus = useContext(SetAppStatus);

  //------------------------------------------------------
  //  Authorised Users
  //------------------------------------------------------

    if (getAppStatus === 'authenticated'){

      //Return the App
      return (
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      )
    }

  //------------------------------------------------------
  //  Access Denied
  //------------------------------------------------------
      
    else if (getAppStatus === 'accessdenied'){

      return (
        <div className='flex justify-center items-center px-[2%] py-[10%]'>
          
          {/* Login Graphic */}
          <img className='max-w-[250px] max-h-[250px] [@media(max-width:500px)]:hidden' src={LLLogo} alt='Login-Graphic'></img>

          {/* Login Pane */}
          <div className='flex flex-col gap-[10px] m-[25px] text-[var(--body)]'>

            <h3>Access Denied!</h3>
            
            <div>
              If you feel this is a mistake, reach out to <a href='mailto:servicedesk@lendlease.com'>servicedesk@lendlease.com</a>
            </div>
            
            {/* Return to Sign In Page Button */}
            <div className='text-left'>
              <button className='Primary-Button' onClick={() => setAppStatus('unauthenticated')}>
                Go Back
              </button>
            </div>

          </div>
        </div>
      )
    }

  //------------------------------------------------------
  //  Pending
  //------------------------------------------------------

    else if (getAppStatus === 'pending'){
  
      return (
        <div className='flex flex-col justify-center items-center px-[15%] py-[10%]'>
          <img alt='loading-circle-icon' src={LoadingIcon}></img>
          <button className='Primary-Button' onClick={() => setAppStatus('unauthenticated')}>
            Cancel
          </button>
        </div>
      )
    }

  //------------------------------------------------------
  //  Failed
  //------------------------------------------------------

    else if (getAppStatus === 'failed'){

      //------------------------------------------------------
      //  Handles each known error message, with a generic catch all response!
      //------------------------------------------------------

        function HandleErrorMessage(message){

          //General catch, to ensure something exists
          if (message === undefined) return;

          //Allow third-party cookies
          if (message.includes('web-storage-unsupported')){
            return(
              <div>
                Third party cookies are disabled. Enable <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop'>third-party cookies</a> in your browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/invalid-credential')){
            return(
              <div>
                Failed verify your credentials. Close the browser and try again.
              </div>
            )
          }

          //Failed to verify the users credenitals, user must close tab and sign in again
          if (message.includes('auth/popup-closed-by-user')){
            return(
              <div>
                You closed the sign in pop up. Click 'return to the sign in page' and try again.
              </div>
            )
          }

          //User is blocking popups
          if (message.includes('auth/popup-blocked')){
            return(
              <div>
                Browser is blocking pop ups. Allow <a target='_blank' rel='noopener noreferrer' href='https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop'>pop ups</a> and try again.
              </div>
            )
          }

          //There is an existing Firebase account with this users UPN 
          if (message.includes('auth/account-exists-with-different-credential')){
            return(
              <div>
                Found an existing account with the same email address. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }

          // Poor connection
          if (message.includes('bad-network-connection')){
            return(
              <div>
                Unable to connect, this typically indicates your device does not have a healthy Internet connection. Try again on another device or network.
              </div>
            )
          }

          //General catch all
          else{
            return(
              <div>
                Unknown issue has occurred. Contact <a href='mailto:ServiceDesk@lendlease.com'>ServiceDesk@lendlease.com</a> and provide the error message below.
              </div>
            )
          }
        }

      //------------------------------------------------------
      //  Show 'Oops, something went wrong!' page 
      //------------------------------------------------------

        return (
          <div className='flex justify-center items-center px-[2%] py-[10%]'>
          
            {/* Login Graphic */}
            <img className='max-w-[250px] max-h-[250px] [@media(max-width:500px)]:hidden' src={LLLogo} alt='Login-Graphic'></img>

            {/* Login Pane */}
            <div className='flex flex-col gap-[10px] m-[25px] text-[var(--body)] w-50'>
            
              <h3>Oops, something went wrong!</h3>

              {HandleErrorMessage(getAppErrors)}

              {/* Return to Sign In Page Button */}
              <div className='text-left'>
                <button className='Primary-Button' onClick={() => setAppStatus('unauthenticated')}>
                  Go Back
                </button>
              </div>

              <div className='mx-0 my-[10px]'>
                <h5>Error Message</h5>
                {
                  getAppErrors ? getAppErrors : <div>No error message found.</div>
                }
              </div>
            
            </div>

          </div>
        )
    }

  //------------------------------------------------------
  //  Signed Out
  //------------------------------------------------------

    else if (getAppStatus === 'signedout'){

      return (

        <div className='flex justify-center items-center px-[2%] py-[10%]'>
          
          {/* Login Graphic */}
          <img className='max-w-[150px] max-h-[150px] [@media(max-width:500px)]:hidden' src={LLLogo} alt='Login-Graphic'></img>

          {/* Login Pane */}
          <div className='flex flex-col gap-[10px] m-[25px] text-[var(--body)]'>

            <h4>Thanks for stopping by!</h4>
            
            <div>
              Please close this tab.
            </div>
            
          </div>
        </div>

      )

    }

  //------------------------------------------------------
  //  Unauthenticated Users
  //------------------------------------------------------

    else if (getAppStatus === 'unauthenticated'){

      return (
        <Login
          autoSignin={true}
        ></Login>
      )

    }

  //------------------------------------------------------
}
