//================================================================
//  Component: ExportToCSV
//  Documentation: https://www.npmjs.com/package/react-csv
//================================================================

//  Purpose: Input firestore data and converts to a CSV file, with a user friendly export button!

//  Properties:
//    - filename <Required> = <String> the name of the file
//    - data <Required> = <Array of Arrays with Objects OR Array of Objects>
//    - type <Optional> = <String> type of dataset, 'nmis'
//    - disabled <Optional> = Enable or disable the button

//  ExportToCSV:
//    <ExportToCSV
//      filename='emissions'
//      data={projectEmissionsChartData} 
//    ></ExportToCSV>    

//================================================================


//Libraries
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';

//Contexts

//Components

//Functions
import ConvertDate from '../../Library/ConvertDate';

//Images

import DownloadIcon from '../Images/Icon_Download_White.svg';

//CSS
import './ExportToCSV.css';


export default function ExportToCSV({
  filename,
  data,
  type,
  disabled,
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  const csvLink = useRef();
  const [csvData, setCSVData] = useState([]);

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // onLoad
  //  1. When NO type is provided, default to a standard export
  useEffect(() => {

    if (data === undefined) return;
    if (type !== undefined || type.length > 0) return;

    setCSVData(data);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // onLoad - nmis
  //  1. When type is nmis, handle nested objects
  useEffect(() => {

    if (data === undefined) return;
    if (type !== 'nmis') return;

    // Unpack the nested dataset (nimis)
    const results = [];

    data.forEach((object) => {

      results.push({
        'NMI': object.id,
        'Street Address (line 1)': object.address?.streetaddressline1,
        'Street Address (line 2)': object.address?.streetaddressline2,
        'City': object.address?.city,
        'State': object.address?.state,
        'Postcode': object.address?.postcode,
        'Energy Start Date': object.energystart,
        'Expected Consumption': object.expectedconsumption,
        'Retailer': object.agreement?.name,
        'Business Unit': object.businessunit?.name,
        'Contracting Entity': object.contractingentity?.name,
        'Billing Contact Email': object.primarybillingcontact?.emailaddress,
        'Billing Contact Phone': object.primarybillingcontact?.contactnumber,
        'Trust': object.trust?.name,
        'Fund': object.fund?.name,
        'Site Contact Email': object.primarysitecontact?.emailaddress,
        'Site Contact Phone': object.primarysitecontact?.contactnumber,
        'Decommissioned Date': object.decommissioneddate,
        'Transferred Date': object.transferdate,
        'Created By': object.createdby,
        'Created': ConvertDate(object.created),
        'Status': object.status,
      });

    });

    setCSVData(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // onLoad - businessunits
  //  1. When type is businessunits, handle nested objects
  useEffect(() => {

    if (data === undefined) return;
    if (type !== 'businessunits') return;

    // Unpack the nested dataset
    const results = [];

    data.forEach((object) => {

      results.push({
        'Name': object.name,
        'Street Address (line 1)': object.address?.streetaddressline1,
        'Street Address (line 2)': object.address?.streetaddressline2,
        'City': object.address?.city,
        'State': object.address?.state,
        'Postcode': object.address?.postcode,
        'Created By': object.createdby,
        'Created': ConvertDate(object.created),
        'Status': object.status,
      });

    });

    setCSVData(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // onLoad - contractingentities
  //  1. When type is contractingentities, handle nested objects
  useEffect(() => {

    if (data === undefined) return;
    if (type !== 'contractingentities') return;

    // Unpack the nested dataset
    const results = [];

    data.forEach((object) => {

      results.push({
        'Name': object.name,
        'ABN': object.abn,
        'Linked Business Unit': object.businessunit.name,
        'Billing Contact Email': object.primarybillingcontact?.emailaddress,
        'Billing Contact Phone': object.primarybillingcontact?.contactnumber,
        'Street Address (line 1)': object.address?.streetaddressline1,
        'Street Address (line 2)': object.address?.streetaddressline2,
        'City': object.address?.city,
        'State': object.address?.state,
        'Postcode': object.address?.postcode,
        'Created By': object.createdby,
        'Created': ConvertDate(object.created),
        'Status': object.status,
      });

    });

    setCSVData(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // onLoad - trusts or funds
  //  1. When type is trusts or funds, handle nested objects
  useEffect(() => {

    if (data === undefined) return;
    if (type !== 'trusts' && type !== 'funds') return;

    // Unpack the nested dataset
    const results = [];

    data.forEach((object, index) => {

      results.push({
        'Name': object.name,
        'ABN': object.abn,
        'Street Address (line 1)': object.address?.streetaddressline1,
        'Street Address (line 2)': object.address?.streetaddressline2,
        'City': object.address?.city,
        'State': object.address?.state,
        'Postcode': object.address?.postcode,
        'Created By': object.createdby,
        'Created': ConvertDate(object.created),
        'Status': object.status,
      });

      if (type === 'trusts'){

        results[index]['Linked Contracting Entity'] = object.contractingentity.name;

      }

      if (type === 'funds'){
        
        results[index]['Linked Trust'] = object.trust.name;

      }

    });

    setCSVData(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // onLoad - agreements
  //  1. When type is agreements, handle nested objects
  useEffect(() => {

    if (data === undefined) return;
    if (type !== 'agreements') return;

    // Unpack the nested dataset
    const results = [];

    data.forEach((object, index) => {

      results.push({
        'Retailer Name': object.retailername,
        'Contact Email': object.contact?.emailaddress,
        'Contact Phone': object.contact?.contactnumber,
        'States': object.contractstates?.map((state) => state),
        'Start Date': object.contractterm?.startdate,
        'End Date': object.contractterm?.enddate,
        'Peak': object.peak,
        'Off Peak': object.offpeak,
        'Shoulder': object.shoulder,
        'Bundled Rates': object.bundledrates,
        'LGCS': object.lgcs,
        'STCS': object.stcs,
        'VEECS': object.veecs,
        'ESCS': object.escs,
        'Created By': object.createdby,
        'Created': ConvertDate(object.created),
        'Status': object.status,
      });

      if (type === 'trusts'){

        results[index]['Linked Contracting Entity'] = object.contractingentity.name;

      }

      if (type === 'funds'){
        
        results[index]['Linked Trust'] = object.trust.name;

      }

    });

    setCSVData(results);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <CSVLink className='no-underline' ref={csvLink} filename={`${filename}.csv`} data={csvData} target='_blank'>

        
      <button disabled={disabled} className='h-10 Primary-Button flex flex-row items-center self-start whitespace-nowrap'>
          <img className='mr-[5px]' src={DownloadIcon} alt='Download'></img>
          Download
      </button>
  
    </CSVLink>
  )
}
