// Libraries
import { initializeApp  } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import {firebaseConfig} from './FirebaseConfig';
import { getDocs, collection, query, where, orderBy, limit } from "firebase/firestore";

/**
 * Returns a document from a collection
 * 
 * @param {string} collectionId
 * @param {Array} queries
 * @param {Array} sortBy - <OPTIONAL> Sort by a field, requires an index
 * @param {Number} limitBy <OPTIONAL> Limit the query to x number of documents
 * 
 * ```
 * const collectionId = 'users';
 * const queries = [
 *  ['emailaddress', '==', 'mark.bennett@lendlease.com'],
 * ];
 * const sortBy = ['created', 'desc'];
 * const limitBy = 5;
 * 
 * QueryDocument(collectionId, queries, sortBy, limitBy).then((documents) =>{
 * 
 *  console.log('Documents', documents);
 * 
 * }).catch((error) => {
 * 
 *  console.log('Error', error);
 * 
 * });
 * 
 * ```
 * 
 * Documentation
 * - https://firebase.google.com/docs/firestore/query-data/queries
 * - https://cloud.google.com/firestore/docs/samples/firestore-query-collection-group-filter-eq#firestore_query_collection_group_filter_eq-nodejs
 */

export default async function QueryDocument(collectionId, queries, sortBy, limitBy){

  try {

    //Firestore database reference
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    
    //Set collection
    const collectionRef = collection(db, collectionId);

    //------------------------------------------------------
    //  Prepare query arguments 
    //------------------------------------------------------

    //Temp array used to store query arguments
    var args = []

    //Extract list of queries from array
    if(queries.length > 0){
      queries.forEach((query) => {
        const queryArg = where(query[0], query[1], query[2])
        args.push(queryArg)
      })
    }

    //Check if sortBy exists > add to query arguments
    if(sortBy?.length > 0) {
      const orderByArg = orderBy(sortBy[0], sortBy[1])
      args.push(orderByArg)
    }

    //Check if limitBy exists > add to query arguments
    if(limitBy) {
      const limitArg = limit(limitBy)
      args.push(limitArg)
    }

    //------------------------------------------------------
    //  Execute query 
    //------------------------------------------------------

    //Create query
    const q = query(collectionRef, ...args);

    //Temp array used to store results 
    var results = []

    //Execute query
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      results.push(doc.data());
    });

    return results

  } 
  catch (error) {
    throw new Error(`Function QueryDocument failed to complete, error ${error}`)
  }

  //------------------------------------------------------
}