//================================================================
//  Component: Status Label
//================================================================

//  Purpose: This is a label component displaying the status of a business request

//  Properties:
//    - status = {A string, this contains the business request status}

//  StatusLabel:
//    <StatusLabel
//      status={request}
//    ></StatusLabel>    

//================================================================

//Libraries
import React from 'react';

//Contexts

//Components

//Functions

//Images

//CSS
import './StatusLabel.css';


export default function StatusLabel({
  status
}) { 

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <div>
        {
            [

              {'name': 'Under Review', 'background': '#00A7A4', 'color': 'white', 'bordercolor': '#00A7A4', 'status': 'Under Review'},
              {'name': 'Completed', 'background': '#8ABB45', 'color': 'white', 'bordercolor': '#8ABB45', 'status': 'Completed'},
              {'name': 'Active', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'active'},
              {'name': 'Decommissioned', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'decommissioned'}, 
            ].filter((style) => style.status === status).map((object, index) => (
                <label key={index} style={{backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}`}}  className='Status-Label'>
                    {object.name}
                </label>
            ))
        }
    </div>
  )
}
