//================================================================
//  Page: Retailers Table
//================================================================

//  Purpose: Table each retailer in AES

// Properties:
//     - setPageStatus = {useState, used to set page status in case of errors}


//  Example:
// <Retailers
//     setPageStatus={setPageStatus}
// ></Retailers>

//================================================================


// Libraries
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Contexts

// Components
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';
import AddRetailer from './AddRetailer';

// Functions
import QueryListener from '../../../Library/QueryListener';

// Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';

// CSS


export default function Retailers({
    setPageStatus
}) {

    //------------------------------------------------------
    //  React Router
    //------------------------------------------------------

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // For filtering project status
    const [currentFilter, setCurrentFilter] = useState('Active');

    // For search
    const [searchInput, setSearchInput] = useState('');

    // Used to store all assets
    const [assetsInventory, setAssetsInventory] = useState([]);

    // Used to store the filtered assets
    const [assetsInventoryFiltered, setAssetsInventoryFiltered] = useState([]);

    // Create Side pane
    const [paneOpen, setPaneOpen] = useState(false);

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search function
    function handleSearch(value) {

        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {

            setAssetsInventoryFiltered(assetsInventory);

        };

        // Search Filter
        const searchResults = assetsInventory.filter((object) =>
            object?.retailername?.toLowerCase().includes(value.toLowerCase()) ||
            object?.contact?.emailaddress?.toLowerCase().includes(value.toLowerCase())
        );

        // Save the filtered search results to the useState
        setAssetsInventoryFiltered(searchResults);

    };

    //Handles status filter
    function handleChange(event) {
        setCurrentFilter(event.target.value);
    };

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Query Listener
    // - Get all the nmis
    useEffect(() => {

        if (currentFilter === undefined) return;

        function onLoadChange(documents) {

            setAssetsInventory(documents);
            setAssetsInventoryFiltered(documents);

        }

        function onError(error) {

            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = QueryListener('agreements', currentFilter === 'All' ?
            searchParams.get('view') === 'ALL' ?
                []
                :
                [
                    ['contractstates', 'array-contains-any', [searchParams.get('view')]],
                ]

            :
            searchParams.get('view') === 'ALL' ?
                [
                    ['status', '==', currentFilter.toLowerCase()],
                ]
                :
                [
                    ['contractstates', 'array-contains-any', [searchParams.get('view')]],
                    ['status', '==', currentFilter.toLowerCase()],
                ]

            , onLoadChange, onLoadChange, onError
        );

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, [currentFilter, searchParams.get('view')]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <div className='flex flex-col gap-3 m-0 p-0'>

            {/* ----------------------------------- */}
            {/*  Filters, Search & Create           */}
            {/* ----------------------------------- */}

            <div className='flex flex-row justify-between w-full'>

                {/* Filters Container */}
                <div className='flex flex-col gap-1'>

                    {/* ======== States (Buttons) ========== */}
                    <div className=' flex flex-row gap-2'>

                        {/* All */}
                        <button className={searchParams.get('view') === 'ALL' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/retailers?view=ALL')}>
                            All
                        </button>

                        {/* NSW */}
                        <button className={searchParams.get('view') === 'NSW' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/retailers?view=NSW')}>
                            NSW
                        </button>

                        {/* VIC */}
                        <button className={searchParams.get('view') === 'VIC' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/retailers?view=VIC')}>
                            VIC
                        </button>

                        {/* QLD */}
                        <button className={searchParams.get('view') === 'QLD' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/retailers?view=QLD')}>
                            QLD
                        </button>

                        {/* East Coast Small Sites */}
                        <button className={searchParams.get('view') === 'East Coast Small Sites' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => navigate('/assets/retailers?view=East Coast Small Sites')}>
                            East Coast Small Sites
                        </button>

                    </div>

                </div>

                {/* ======== Search & Create (Buttons) ======== */}
                <div>
                    <div className='flex justify-end m-0 gap-2'>

                        {/* Search Bar */}
                        <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[300px] my-[5px] h-fit'>
                            <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                            <label htmlFor='searchInput'>
                                <input
                                    id='searchInput'
                                    className='border-none h-[37px] p-0 m-0 outline-none bg-white'
                                    type='text'
                                    placeholder='Search'
                                    onChange={(e) => handleSearch(e.target.value)}
                                    value={searchInput}
                                    autoComplete='no'
                                ></input>
                            </label>
                        </div>

                        {/* Create Retailer Button */}
                        <div className='flex flex-row-reverse'>

                            <button className='Primary-Button' onClick={() => setPaneOpen(true)}>
                                + Add Retailer
                            </button>

                            {/* ======== Create Side Pane ======== */}
                            <AddRetailer
                                paneOpen={paneOpen}
                                setPaneOpen={setPaneOpen}
                            ></AddRetailer>

                        </div>

                        <ExportToCSV filename={`${searchParams.get('view')}-Retailers-${Date.now()}`} type='agreements' data={assetsInventoryFiltered}></ExportToCSV>

                    </div>

                </div>

            </div>

            {/* Table */}
            <div className='Table-Container'>

                {/* ======== Sorting and Filters ========== */}
                <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5] min-h-[55px]'>

                    <label className='font-semibold self-center'>
                        Total: {assetsInventoryFiltered?.length} of {assetsInventoryFiltered?.length}
                    </label>

                    {/* ======== Status (Dropdown) ========== */}
                    <div className='flex flex-row items-center gap-2'>

                        <select className='Input-Field-Select' onChange={handleChange}>
                            <option value='Active'>Active</option>
                            <option value='decommissioned'>Decommissioned</option>
                            <option value='All'>All</option>
                        </select>
                    </div>

                </div>

                {/* ======== Table ========== */}
                <table className='w-full max-h-96 overflow-y-scroll'>
                    <thead>
                        <tr className='border-b-[#D8D8D8] border-solid border-b-2 text-[#424242]'>
                            <th className='py-[15px] px-[10px] font-medium'></th>
                            <th className='py-[15px] px-[10px] font-medium'>Retailer Name</th>
                            <th className='py-[15px] px-[10px] font-medium'>Contact</th>
                            <th className='py-[15px] px-[10px] font-medium'>States</th>
                            <th className='py-[15px] px-[10px] font-medium'>Start Date</th>
                            <th className='py-[15px] px-[10px] font-medium'>End Date</th>
                            <th className='py-[15px] px-[10px] font-medium'>Bundled Rates (cents/kW)</th>
                            <th className='py-[15px] px-[10px] font-medium'>Peak (cents/kW)</th>
                            <th className='py-[15px] px-[10px] font-medium'>Off Peak (cents/kW)</th>
                            <th className='py-[15px] px-[10px] font-medium'>Shoulder (cents/kW)</th>
                            <th className='py-[15px] px-[10px] font-medium'>LGCS (%)</th>
                            <th className='py-[15px] px-[10px] font-medium'>STCS (%)</th>
                            <th className='py-[15px] px-[10px] font-medium'>VEECS (%)</th>
                            <th className='py-[15px] px-[10px] font-medium'>ESCS (%)</th>
                            <th className='py-[15px] px-[10px] font-medium'>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            // No assets found
                            assetsInventoryFiltered?.length === 0 ? (

                                <tr>
                                    <td colSpan={10}>
                                        <div className='text-center p-[2%]'>
                                            No results found.
                                        </div>
                                    </td>
                                </tr>

                            ) : (

                                // Map in each business request
                                assetsInventoryFiltered?.map((object, index) => (

                                    <tr key={index} className='border-b-[#C8C8C9] border-solid border-b last:border-0'>

                                        <td className='py-[15px] px-[10px]'></td>

                                        {/* Retailer Name */}
                                        <td className='py-[15px] px-[10px]'>
                                            <label className='font-medium cursor-pointer hover:underline' onClick={() => navigate(`/assets/retailers/${object?.id}`)}>
                                                {object?.retailername}
                                            </label>
                                        </td>

                                        {/* Contact */}
                                        <td className='py-[15px] px-[10px]'>
                                            <div className='flex flex-col'>
                                                <span className='font-semibold'>
                                                    {object?.contact?.givenname} {object?.contact?.surname}
                                                </span>
                                                <span className='text-[#797979]'>
                                                    {object?.contact?.emailaddress}
                                                </span>
                                                <span className='text-[#797979]'>
                                                    {object?.contact?.contactnumber}
                                                </span>
                                            </div>
                                        </td>

                                        {/* States */}
                                        <td className='py-[15px] px-[10px]'>
                                            <ul>
                                                {
                                                    object?.contractstates?.map((state, index) => (

                                                        <li key={index} className='list-disc'>
                                                            {state}
                                                        </li>
                                                    ))

                                                }
                                            </ul>
                                        </td>

                                        {/* Start Date */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.contractterm?.startdate}
                                        </td>

                                        {/* End Date */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.contractterm?.enddate}
                                        </td>

                                        {/* Bundled Rates (cents/kW) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.bundledrates}
                                        </td>

                                        {/* Peak (cents/kW) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.peak}
                                        </td>

                                        {/* Off Peak (cents/kW) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.offpeak}
                                        </td>

                                        {/* Shoulder (cents/kW) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.shoulder}
                                        </td>

                                        {/* LGCS (%) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.lgcs}
                                        </td>

                                        {/* STCS (%) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.stcs}
                                        </td>

                                        {/* VEECS (%) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.veecs}
                                        </td>

                                        {/* ESCS (%) */}
                                        <td className='py-[15px] px-[10px]'>
                                            {object?.escs}
                                        </td>

                                        {/* Status */}
                                        <td className='pt-[15px] pb-[10px] px-[10px]'>
                                            <StatusLabel status={object?.status}></StatusLabel>
                                        </td>

                                    </tr>

                                ))

                            )

                        }
                    </tbody>
                </table>

            </div>

        </div>

    )
}
