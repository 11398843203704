//================================================================
//  Page: Asset Inventory
//================================================================

//  Business Lead: 
//    - CAN NOT view or modify anything in Asset Inventory

//  EPM: 
//    - CAN view or modify anything in Asset Inventory

//================================================================


// Libraries
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import Meters from './Meters/Meters';
import Retailers from './Retailers/Retailers';
import BusinessUnits from './BusinessUnits/BusinessUnits';
import ContractingEntities from './ContractingEntities/ContractingEntities';
import Trusts from './Trusts/Trusts';
import Funds from './Funds/Funds';

// Tabs

// Functions

// Images

// CSS


export default function AssetInventory() {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate();
  const location = useLocation();

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

  // Used to determine the status of a page > 'pending', 'onload', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('onload');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Toggles the style when the tab is selected
  function navStyle(path) {

    // Highlighted Nav item
    if (location.pathname.includes(path)) {

      return 'cursor-pointer font-medium border-b-solid border-b-[var(--teal)] border-b-2 px-[10px] py-[12px]'; // Selected Style border-b-[2px solid var(--teal)]

    } else {

      return 'cursor-pointer px-[10px] py-[12px]'; // Default Style

    }

  };

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      header={
        <div className='Page-Header-Container'>
          <p className='font-medium text-[20px] mb-0'>Assets Inventory</p>
        </div>
      }
      body={

        <div className='w-full h-full'>

          {/* =========================== */}
          {/*   Tab Names                 */}
          {/* =========================== */}

          <div className='flex flex-row gap-3 border-[#D8D8D8] border-b mt-2 px-[50px] justify-between'>

            <div className='flex flex-row gap-3'>

              {/* Meters */}
              <div className={navStyle('/assets/meters')} onClick={() => navigate('/assets/meters?view=ALL')} style={{ fontSize: '16px' }}>
                Meters
              </div>

              {/* Retailers */}
              <div className={navStyle('/assets/retailers')} onClick={() => navigate('/assets/retailers?view=ALL')} style={{ fontSize: '16px' }}>
                Retailers
              </div>

              {/* Business Units */}
              <div className={navStyle('/assets/businessunits')} onClick={() => navigate('/assets/businessunits?view=ALL')} style={{ fontSize: '16px' }}>
                Business Units
              </div>

              {/* Contracting Entities */}
              <div className={navStyle('/assets/contractingentities')} onClick={() => navigate('/assets/contractingentities?view=ALL')} style={{ fontSize: '16px' }}>
                Contracting Entities
              </div>

              {/* Trusts */}
              <div className={navStyle('/assets/trusts')} onClick={() => navigate('/assets/trusts?view=ALL')} style={{ fontSize: '16px' }}>
                Trusts
              </div>

              {/* Funds */}
              <div className={navStyle('/assets/funds')} onClick={() => navigate('/assets/funds?view=ALL')} style={{ fontSize: '16px' }}>
                Funds
              </div>

            </div>

          </div>

          {/* =========================== */}
          {/*   Tab Content               */}
          {/* =========================== */}

          <div className='mx-[0%] my-[20px] px-[50px]'>

            {
              // Meters
              location.pathname === `/assets/meters` ?
                <Meters
                  setPageStatus={setPageStatus}
                ></Meters>

                :

                // Retailers
                location.pathname === `/assets/retailers` ?
                  <Retailers
                    setPageStatus={setPageStatus}
                  ></Retailers>

                  :

                  // Business Units
                  location.pathname === `/assets/businessunits` ?
                    <BusinessUnits
                      setPageStatus={setPageStatus}
                    ></BusinessUnits>

                    :

                    // Contracting Entities
                    location.pathname === `/assets/contractingentities` ?
                      <ContractingEntities
                        setPageStatus={setPageStatus}
                      ></ContractingEntities>

                      :

                      // Trusts
                      location.pathname === `/assets/trusts` ?
                        <Trusts
                          setPageStatus={setPageStatus}
                        ></Trusts>

                        :

                        // Funds
                        location.pathname === `/assets/funds` ?
                          <Funds
                            setPageStatus={setPageStatus}
                          ></Funds>

                          :

                          // Page Not Found
                          <div className='mx-[0%] my-[20px] px-[30px]'>

                            <div className='p-[10px] text-center'>

                              <h3>Error 404 - Page not found.</h3>
                              <br></br>
                              <div>
                                If the error persists, please log a ticket through Service Central <a href='https://lendlease.service-now.com/lendlease?id=sc_cat_item&sys_id=7343bc9a1be9d050b88f0d45ec4bcb96' target='_blank' rel='noopener noreferrer'> here</a> and include the above url.
                                <div>
                                  <button className='Primary-Button' onClick={() => navigate('/')}>Return Home</button>
                                </div>
                              </div>

                            </div>

                          </div>

            }

          </div>

        </div>

      }
    ></PageComponent >

  )
}
