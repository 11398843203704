//================================================================
//  Component: Breadcrumbs
//================================================================

//  Purpose: Allows the user to navigate between pages

//  Example:
//    <BreadCrumbs
//      rootLinkName={'Approvals'}                         // String - Display name for root link
//      childLinkName={energySupplyRequest?.initiativename}    // String - Display name for child link
//    ></BreadCrumbs>    

//================================================================

// Libraries
import React from 'react';
import { Link } from 'react-router-dom';

// Images
import Arrow from '../../Components/Images/Icon_Breadcrumb_Black.svg';

export default function BreadCrumbs({
  rootLinkName,
  childLinkName
}) {
  return (
    <p className='flex flex-row gap-1 text-[15px]'>
        <Link className='font-medium text-[#028E8C] no-underline cursor-pointer' onClick={() => window.history.back()}>
          {rootLinkName}
        </Link> 
        <img src={Arrow} alt='>'></img>
        {childLinkName}
    </p>
  )
}
