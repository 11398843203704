//================================================================
//  Page: Energy Supply Request Page
//================================================================

// Purpose: Route Users (Business Leads & EPMs) to the correct 'Overview' component based off the 'requesttype'

//================================================================


//Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//Contexts

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../../Components/Breadcrumbs/BreadCrumbs';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import NewEnergyRequestOverview from './NewEnergyRequestOverview/NewEnergyRequestOverview';
import ModifyEnergySupplyOverview from './ModifyEnergySupplyOverview/ModifyEnergySupplyOverview';

//Functions
import DocumentListener from '../../../Library/DocumentListener';
import ConvertDate from '../../../Library/ConvertDate';

//Images
import Tick from '../../../Components/Images/Icon_Tick_DarkTeal.svg';


export default function EnergySupplyRequestsId() {

    //------------------------------------------------------
    //  React Router and useContext
    //------------------------------------------------------

    const navigate = useNavigate();
    const params = useParams();

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Used to save the page status > 'pending', 'onload', 'success', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('pending');

    // Holds the current business request
    const [energySupplyRequest, setEnergySupplyRequest] = useState();

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // Find the energy supply request document
    useEffect(() => {

        const requestId = params.id;

        if (requestId === '') return;
        if (requestId === null) return;

            function onLoad(document){

            setEnergySupplyRequest(document);
            setPageStatus('onload');

        }

            function onChange(document){

            setEnergySupplyRequest(document);
            setPageStatus('onload');

        }

            function onError(error){

            console.log('error', error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = DocumentListener('energysupplyrequests', requestId, onLoad, onChange, onError);

            return () =>{
            unsubscribe();
        };


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

        return(
        <PageComponent
            header={
                <div className='Page-Header-Container [@media(max-width:450px)]:py-[5%] [@media(max-width:450px)]:px-[8%]'>

                    {/* Breadcrumbs */}
                    <BreadCrumbs
                        rootLinkName={'Requests'}
                        childLinkName={energySupplyRequest?.energysupplyrequestid}
                    ></BreadCrumbs>

                    {/* Heading */}
                    <div className='flex flex-col w-fit-content'>
                        <div className='flex flex-row gap-[15px] text-[20px] font-medium m-0 p-0 text-wrap'>
                            {energySupplyRequest?.energysupplyrequestid}
                            <StatusLabel status={energySupplyRequest?.status}></StatusLabel>
                        </div>

                        <p className='mt-2 text-base text-wrap mb-0'>
                            This energy supply request was submitted by <b className='capitalize font-medium'>{energySupplyRequest?.createdby.split('@')[0].split('.')[0]} {energySupplyRequest?.createdby.split('@')[0].split('.')[1]}</b> on {ConvertDate(energySupplyRequest?.created)}.
                        </p>
                    </div>

                </div>
            }
            status={pageStatus}
            body={
                <div className='mx-[0%] my-[20px] px-[50px]'>

                    {
                        energySupplyRequest?.requesttype === 'New Meter'?

                            <NewEnergyRequestOverview
                                energySupplyRequest={energySupplyRequest}
                            ></NewEnergyRequestOverview>

                        :

                            <ModifyEnergySupplyOverview
                                energySupplyRequest={energySupplyRequest}
                            ></ModifyEnergySupplyOverview>
                    }

                </div>
            }
            successContent={
                <div className='p-[4%] rounded-[5px] text-center items-center'>
                    <div className='inline-flex gap-[12px] text-center'>
                        <img className='m-0 p-0 text-center' src={Tick} alt='Complete'></img>
                        <h4 className='my-2 text-[#007A78]'>Thank You</h4>
                    </div>
                    <p className='leading-[1.7]'> Your changes have now been saved.</p>
                    <button className='Primary-Button' onClick={() => navigate('/energysupplyrequests')}> Close </button>
                </div>
            }
        ></PageComponent>
    )

    //------------------------------------------------------
}
