//================================================================
//  Component: Add Meter - Child component of NewEnergyRequestForm.js
//================================================================

//  Purpose: new meter form, appends the parent useReducer

//  Properties:
//    - formData = {useReducer, partner useReducer formData}
//    - setFormData = {useReducer, partner useReducer setFormData}
//    - openModal = {useState, open & close the modal}
//    - setOpenModal = {useState, open & close the modal}

//  Example:
//
//    <AddMeter
//      formData={formData}
//      setFormData={setFormData}
//      openModal={openModal}
//      setOpenModal={setOpenModal}
//    ></AddMeter>    

//================================================================


//Libraries
import React, { useContext, useReducer } from 'react';

//Contexts
import { SetToast } from '../../../../../Library/GlobalContexts';

//Components

//Functions
import GetDocument from '../../../../../Library/GetDocument';

//Images
import InfoIcon from '../../../../../Components/Images/Icon_Info_Blue.svg';
import closeIcon from '../../../../../Components/Images/Icon_Clear_Grey.svg';

//CSS


export default function AddMeter({
    formData,
    setFormData,
    openModal,
    setOpenModal,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast)

    // --------------------------------------------------
    //  useReducer
    // --------------------------------------------------

    
    const [modalData, setModalData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

            'id': '',
            'idError': '',

            'expectedconsumption': '',

            'streetaddressline1': '',

            'streetaddressline2': '',

            'city': '',

            'state': '',

            'postcode': '',
            'postcodeError': '',
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Check for an existing meter
    function handleLookup() {

        if (modalData.id.length === 0) return;

        // 1. Check the local values
        if (formData.nmis.find((object) => object.id === modalData.id) !== undefined) {

            setModalData({ 'idError': `Meter already added.` });
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': `Meter already added.`,
            });

            return;

        }

        // 2. Check the Firestore collection
        GetDocument('nmis', modalData.id, true).then((document) => {

            if (document === undefined) {

                setModalData({ 'idError': '' });
                setToast({
                    'type': '', // info, pending, success, warning, error
                    'message': '',
                });

                return;

            }

            setModalData({ 'idError': `Unable to add an existing meter.` });
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': `Unable to add an existing meter.`,
            });

        }).catch((error) => {

            console.log('error', error);
            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to check for existing NMI. Try again later.',
            });

        });

    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    // Close the Modal
    if (openModal === false) return null;

    return (
        <div className='Modal-Background'>

            {/* Modal Container */}
            <dialog className='Modal-Container'>

                {/* ============ Header ============*/}
                <div className='flex flex-row justify-between items-center'>

                    <div className='flex flex-row gap-2 text-[#5E93AC] font-semibold items-center'>
                        <img src={InfoIcon} alt='Info-Icon'></img>
                        Provide the NMI Information *
                    </div>

                    <img className='cursor-pointer' src={closeIcon} alt='icon-close' onClick={() => {
                        
                        // Clear the form
                        setModalData({
                            'id': '',
                            'idError': '',
                            'expectedconsumption': '',
                            'streetaddressline1': '',
                            'streetaddressline2': '',
                            'city': '',
                            'state': '',
                            'postcode': '',
                            'postcodeError': '',
                        });

                        setOpenModal(false);
                        
                    }}></img>
                </div>

                <div className='flex flex-col rounded w-fit gap-2'>

                    {/* ============ Add NMI Form ============*/}
                    <div className='flex flex-row gap-2'>

                        {/* id */}
                        <div className='flex flex-col gap-1'>
                            <label className='font-semibold'>Number (NMI)</label>
                            <input
                                className={modalData.idError === '' ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                onChange={(e) => setModalData({ 'id': e.target.value })}
                                onBlur={() => handleLookup()}
                                placeholder='123456789'
                                style={{ width: '260px' }}
                                type='number'
                                value={modalData.id}
                            ></input>
                        </div>

                        {/* Estimated consumption per annum? */}
                        <div className='flex flex-col gap-1'>
                            <label className='font-semibold'>Estimated consumption per annum?</label>
                            <input
                                className='Input-Field-Text'
                                onChange={(e) => setModalData({ 'expectedconsumption': e.target.value })}
                                placeholder='1,000 (kWh)'
                                type='number'
                                style={{ width: '260px' }}
                                value={modalData.expectedconsumption}
                            ></input>
                        </div>

                    </div>

                    {/* Address Line 1 */}
                    <div className='flex flex-col gap-1'>
                        <label className='font-semibold'>Address Line 1</label>
                        <input
                            className='Input-Field-Text'
                            onChange={(e) => setModalData({ 'streetaddressline1': e.target.value })}
                            placeholder='Street Address'
                            style={{ width: '100%' }}
                            value={modalData.streetaddressline1}
                        ></input>
                    </div>

                    {/* Address Line 2 */}
                    <div className='flex flex-col gap-1'>
                        <label className='font-semibold'>Address Line 2</label>
                        <input
                            className='Input-Field-Text'
                            onChange={(e) => setModalData({ 'streetaddressline2': e.target.value })}
                            placeholder='Apartment, suite, building, floor, etc. (optional)'
                            style={{ width: '100%' }}
                            value={modalData.streetaddressline2}
                        ></input>
                    </div>

                    <div className='flex flex-row gap-2'>

                        {/* City */}
                        <div className='flex flex-col gap-1'>
                            <label className='font-semibold'>City</label>
                            <input
                                className='Input-Field-Text'
                                onChange={(e) => setModalData({ 'city': e.target.value })}
                                placeholder='Sydney'
                                style={{ width: '312px' }}
                                value={modalData.city}
                            ></input>
                        </div>

                        {/* State */}
                        <div className='flex flex-col gap-1'>
                            <label className='font-semibold'>State</label>
                            <select
                                className='Input-Field-Select'
                                onChange={(e) => setModalData({ 'state': e.target.value })}
                                style={{ width: '100px' }}
                                value={modalData.state}
                            >
                                <option hidden value=''>-</option>
                                <option value='NSW'> NSW </option>
                                <option value='VIC'> VIC </option>
                                <option value='QLD'> QLD </option>
                                <option value='SA'> SA </option>
                                <option value='WA'> WA </option>
                                <option value='NT'> NT </option>
                                <option value='ACT'> ACT </option>
                                <option value='TAS'> TAS </option>
                            </select>
                        </div>

                        {/* Post Code */}
                        <div className='flex flex-col gap-1'>
                            <label className='font-semibold'>Post Code</label>
                            <input
                                className={modalData.postcodeError.length === 0 ? 'Input-Field-Text' : 'Input-Field-Text-Error'}
                                onChange={(e) => {

                                    const value = e.target.value;

                                    if (value.length > 4 || value.length < 4) {

                                        return setModalData({
                                            'postcode': e.target.value,
                                            'postcodeError': 'No Valid'
                                        });

                                    }

                                    setModalData({
                                        'postcode': e.target.value,
                                        'postcodeError': '',
                                    });

                                }}
                                placeholder='2000'
                                style={{ width: '100px' }}
                                type='number'
                                value={modalData.postcode}
                            ></input>
                        </div>

                    </div>

                    {/* ============ Add Meter Button ============*/}

                    <button disabled={
                        modalData.id.length === 0 ||
                        modalData.expectedconsumption.length === 0 ||
                        modalData.streetaddressline1.length === 0 ||
                        modalData.city.length === 0 ||
                        modalData.state.length === 0 ||
                        modalData.postcode.length === 0 ||
                        modalData.idError.length !== 0 ||
                        modalData.postcodeError.length !== 0
                    } className='Primary-Button w-fit self-end mt-3' onClick={() => {

                        // Add the NMI to the table
                        setFormData({
                            'nmis': [
                                ...formData.nmis,
                                {
                                    'id': modalData.id,
                                    'expectedconsumption': modalData.expectedconsumption,
                                    'streetaddressline1': modalData.streetaddressline1,
                                    'streetaddressline2': modalData.streetaddressline2,
                                    'city': modalData.city,
                                    'state': modalData.state,
                                    'postcode': modalData.postcode,
                                },
                            ]
                        });

                        // Clear the form
                        setModalData({
                            'id': '',
                            'idError': '',
                            'expectedconsumption': '',
                            'streetaddressline1': '',
                            'streetaddressline2': '',
                            'city': '',
                            'state': '',
                            'postcode': '',
                            'postcodeError': '',
                        });

                        setOpenModal(false);

                    }}>Add NMI</button>

                </div>

            </dialog>
        </div>
    )
}
