//================================================================
//  Component: Fund - Child component of NewEnergyRequestForm.js
//================================================================

//  Purpose: Shows the new meters from the the parent useReducer

//  Properties:
//    - formData = {useReducer, partner useReducer formData}
//    - setFormData = {useReducer, partner useReducer setFormData}

//  Fund:
//    <Fund
//      formData={formData}
//      setFormData={setFormData}
//    ></Fund>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import { SetToast } from '../../../Library/GlobalContexts';

//Components
import AddFund from './AddFund';

//Functions
import QueryDocument from '../../../Library/QueryDocument';

//Images

//CSS


export default function Fund({
    formData,
    setFormData,
    disabled,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast)

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Holds the funds
    const [funds, setFunds] = useState([]);

    const [openForm, setOpenForm] = useState(formData.fund.type === 'new' ? true : false );

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onChange
    //  1. Get all 'funds' linked to the selected trust
    useEffect(() => {

        if (formData?.trust?.id === undefined) return;

        QueryDocument('funds', [
            ['trust.id', '==', formData.trust.id],
            ['status', '==', 'active'],
        ]).then((docs) => {

            setFunds(docs);

        }).catch((error) => {

            console.log('error', error);

            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to retrieve funds . Try again later.',
            });

        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.trust]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div hidden={formData?.trust?.id === ''} className='FormComponent-Row'>

            <label className='font-medium'>2c. Fund? </label>

            <div hidden={openForm} className='flex flex-row gap-2'>
                <select
                    className='Input-Field-Select'
                    value={formData.fund.name}
                    disabled={disabled}
                    onChange={(e) => {

                        const doc = funds.find((object) => e.target.value === object.name);
                        
                        // Select the correct fund
                        setFormData({'fund': {
                            'type': 'existing',
                            'id': doc?.id, 
                            'name': doc?.name, 
                            'abn': doc?.abn,
                            'businessunits': [
                                {
                                    'id': formData.businessunit.id,
                                    'name': formData.businessunit.name,
                                }
                            ],
                            'address': {
                                'streetaddressline1': doc?.address?.streetaddressline1,
                                'streetaddressline2': doc?.address?.streetaddressline2,
                                'city': doc?.address?.city,
                                'state': doc?.address?.state,
                                'postcode': doc?.address?.postcode,
                            },
                            'createdby': doc?.createdby,
                            'created': doc?.created,
                            'modifiedby': doc?.modifiedby,
                            'modified': doc?.modified,
                            'status': doc?.status, // active or decommissioned
                        }});

                    }}
                >
                    <>
                        <option value=''>-</option>
                        {

                            // Map in each BU from Firestore
                            funds.map((doc, index) => (

                                <option key={index} value={doc.name}>{doc.name}</option>

                            ))

                        }
                    </>

                </select>
                <button hidden={disabled} className='Primary-Button w-fit' onClick={() => setOpenForm(true)}>+ Add New</button>
            </div>

            <AddFund
                formData={formData}
                setFormData={setFormData}
                openForm={openForm}
                setOpenForm={setOpenForm}
                disabled={disabled}
            ></AddFund>

        </div>
    )

}
