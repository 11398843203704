//================================================================
//  Component: Trust - Child component of NewEnergyRequestForm.js
//================================================================

//  Purpose: Shows the new meters from the the parent useReducer

//  Properties:
//    - formData = {useReducer, partner useReducer formData}
//    - setFormData = {useReducer, partner useReducer setFormData}

//  Trust:
//    <Trust
//      formData={formData}
//      setFormData={setFormData}
//    ></Trust>    

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import { SetToast } from '../../../Library/GlobalContexts';

//Components
import AddTrust from './AddTrust';

//Functions
import QueryDocument from '../../../Library/QueryDocument';

//Images

//CSS


export default function Trust({
    formData,
    setFormData,
    disabled,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast)

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Holds the trusts
    const [trusts, setTrusts] = useState([]);

    const [openForm, setOpenForm] = useState(formData.trust.type === 'new' ? true : false);

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // onChange
    //  1. Get all 'trusts' linked to the selected contractingentity
    useEffect(() => {

        if (formData?.contractingentity?.id === undefined) return;

        QueryDocument('trusts', [
            ['contractingentity.id', '==', formData.contractingentity.id],
            ['status', '==', 'active'],
        ]).then((docs) => {

            setTrusts(docs);

        }).catch((error) => {

            console.log('error', error);

            setToast({
                'type': 'error', // info, pending, success, warning, error
                'message': 'Failed to retrieve trusts . Try again later.',
            });

        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.contractingentity]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div hidden={formData?.contractingentity?.id === ''} className='FormComponent-Row'>

            <label className='font-medium'>2b. Trust? </label>

            <div hidden={openForm} className='flex flex-row gap-2'>
                <select
                    className='Input-Field-Select'
                    value={formData.trust.name}
                    disabled={disabled}
                    onChange={(e) => {

                        const doc = trusts.find((object) => e.target.value === object.name);

                        // Select the correct Trust
                        setFormData({
                            'trust': {
                                'type': 'existing',
                                'id': doc?.id,
                                'name': doc?.name,
                                'abn': doc?.abn,
                                'contractingentity': {
                                    'id': formData.contractingentity.id,
                                    'name': formData.contractingentity.name,
                                },
                                'address': {
                                    'streetaddressline1': doc?.address?.streetaddressline1,
                                    'streetaddressline2': doc?.address?.streetaddressline2,
                                    'city': doc?.address?.city,
                                    'state': doc?.address?.state,
                                    'postcode': doc?.address?.postcode,
                                },
                                'createdby': doc?.createdby,
                                'created': doc?.created,
                                'modifiedby': doc?.modifiedby,
                                'modified': doc?.modified,
                                'status': doc?.status, // active or decommissioned
                            },

                            // Clear all children, as the parent was updated
                            'fund': {
                                'id': ``,
                                'name': '',
                            }

                        });

                    }}
                >
                    <>
                        <option value=''>-</option>
                        {

                            // Map in each doc from Firestore
                            trusts.map((doc, index) => (

                                <option key={index} value={doc.name}>{doc.name}</option>

                            ))

                        }
                    </>

                </select>

                {/* + Add New */}
                <button hidden={disabled} className='Primary-Button w-fit' onClick={() => {

                    // Clear all children, as the parent is 'new'
                    setFormData({
                        'fund': {
                            'type': 'existing', // new or existing

                            // ======== EXISTING & NEW ======== //
                            'id': '',
                            'name': '',
            
                            // ========   NEW    ======== //
                            'abn': '',
                            'trust': {},
                            'address': {
                                'streetaddressline1': '',
                                'streetaddressline2': '',
                                'city': '',
                                'state': '',
                                'postcode': '',
                            },
                            'createdby': '', // emailaddress
                            'created': new Date(),
                            'modifiedby': '', // emailaddress
                            'modified': '',
                            'status': 'active', // active or decommissioned
                        }
                    });

                    setOpenForm(true);

                }}>+ Add New</button>

            </div>

            <AddTrust
                formData={formData}
                setFormData={setFormData}
                openForm={openForm}
                setOpenForm={setOpenForm}
                disabled={disabled}
            ></AddTrust>

        </div>
    )

}
