//================================================================
//  Page: Modify Energy Supply Form
//================================================================

// Purpose: Allows the Business Lead/EPM to modify an existing NMI

//  Business Lead: 
//    - CAN submit a 'Modify Energy Supply' Form

//  EPM: 
//    - CAN submit a 'Modify Energy Supply' Form

//================================================================

// Libraries
import React, { useContext, useState, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

// Functions
import WriteDocument from '../../../../Library/WriteDocument';

//Components
import PageComponent from '../../../../Components/PageComponent/PageComponent';
import BreadCrumbs from '../../../../Components/Breadcrumbs/BreadCrumbs';
import MeterTable from './MeterTable/MeterTable';
import BusinessUnit from '../../../../Components/EnergySupplyComponents/BusinessUnit/BusinessUnit';
import ContractingEntity from '../../../../Components/EnergySupplyComponents/ContractingEntity/ContractingEntity';
import Trust from '../../../../Components/EnergySupplyComponents/Trust/Trust';
import Fund from '../../../../Components/EnergySupplyComponents/Fund/Fund';

// Images
import Tick from '../../../../Components/Images/Icon_Tick_DarkTeal.svg';

//CSS


export default function ModifyEnergySupplyForm() {

    // --------------------------------------------------
    //  useContext and React Router
    // --------------------------------------------------

    const getUser = useContext(GetUser);
    const navigate = useNavigate();

    // --------------------------------------------------
    //  useStates
    // --------------------------------------------------

    // Used to save page status >  'onload', 'pending', 'success', 'error-invalid', 'error-fatal'
    const [pageStatus, setPageStatus] = useState('onload');

    // Used to determine if the confirmation box has been checked
    const [confirmationChecked, setConfirmationChecked] = useState(false);

    // --------------------------------------------------
    //  useReducer
    // --------------------------------------------------

    const [formData, setFormData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

            'nmiAssigned': '',
            'nmiAssignedError': '',

            // Array of NMI's
            'nmis': [],

            'modificationType': 'Reassignment', // 'Reassignment' or 'Termination' 

            'businessunit': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'abn': '',
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },
            'businessunitError': '',

            'contractingentity': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'abn': '',
                'businessunit': {},
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'givenname': '',
                'surname': '',
                'emailaddress': '',
                'contactnumber': '',

                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },

            'trust': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'abn': '',
                'contractingentity': {},
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },

            // 'fund' doc ref
            'fund': {
                'type': 'existing', // new or existing

                // ======== EXISTING & NEW ======== //
                'id': '',
                'name': '',

                // ========   NEW    ======== //
                'abn': '',
                'trust': {},
                'address': {
                    'streetaddressline1': '',
                    'streetaddressline2': '',
                    'city': '',
                    'state': '',
                    'postcode': '',
                },
                'createdby': '', // emailaddress
                'created': new Date(),
                'modifiedby': '', // emailaddress
                'modified': '',
                'status': 'active', // active or decommissioned
            },
            'energychange': '', // date-string
            'energychangeError': '',

            'reason': 'Meter is been transferred to customer',
            'reasonError': '',
            'reasonOther': '',
            'reasonOtherError': '',

        }
    );

    // --------------------------------------------------
    //  Functions
    // --------------------------------------------------

    //  Submit Handler
    function handleSubmit() {

        setPageStatus('pending');

        //=================================================
        //  Prepare Document for Firestore Write
        //=================================================

        const energySupplyRequestId = `es-${Date.now()}`;

        const energySupplyRequestDoc = {
            'energysupplyrequestid': energySupplyRequestId,

            'createdby': getUser.emailaddress,
            'created': new Date(),
            'modifiedby': '', // emailaddress
            'modified': '',
            'status': 'Under Review', // Under Review, Completed

            'nmis': formData.nmis,

            'requesttype': 'Modify Meter', // 'New Meter', 'Modify Meter'

            'modificationtype': formData.modificationType, // 'Reassignment' or 'Termination' 

            'agreement': {
                'id': '',
                'name': '',
            },

            'businessunit': {
                'type': formData.businessunit.type,
                'id': formData.businessunit.id,
                'name': formData.businessunit.name,
                'address': {
                    'streetaddressline1': formData.businessunit.address.streetaddressline1,
                    'streetaddressline2': formData.businessunit.address.streetaddressline2,
                    'city': formData.businessunit.address.city,
                    'state': formData.businessunit.address.state,
                    'postcode': formData.businessunit.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'contractingentity': {
                'type': formData.contractingentity.type,
                'id': formData.contractingentity.id,
                'name': formData.contractingentity.name,
                'abn': formData.contractingentity.abn,
                'businessunit': [
                    {
                        'id': formData.businessunit.id,
                        'name': formData.businessunit.name,
                    }
                ],
                'address': {
                    'streetaddressline1': formData.contractingentity.address.streetaddressline1,
                    'streetaddressline2': formData.contractingentity.address.streetaddressline2,
                    'city': formData.contractingentity.address.city,
                    'state': formData.contractingentity.address.state,
                    'postcode': formData.contractingentity.address.postcode,
                },
                'primarybillingcontact': {
                    'givenname': formData.contractingentity.givenname,
                    'surname': formData.contractingentity.surname,
                    'emailaddress': formData.contractingentity.emailaddress,
                    'contactnumber': formData.contractingentity.contactnumber,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'trust': {
                'type': formData.trust.type,
                'id': formData.trust.id,
                'name': formData.trust.name,
                'abn': formData.trust.abn,
                'contractingentity': [
                    {
                        'id': formData.contractingentity.id,
                        'name': formData.contractingentity.name,
                    }
                ],
                'address': {
                    'streetaddressline1': formData.trust.address.streetaddressline1,
                    'streetaddressline2': formData.trust.address.streetaddressline2,
                    'city': formData.trust.address.city,
                    'state': formData.trust.address.state,
                    'postcode': formData.trust.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'fund': {
                'type': formData.fund.type,
                'id': formData.fund.id,
                'name': formData.fund.name,
                'abn': formData.fund.abn,
                'trust': [
                    {
                        'id': formData.trust.id,
                        'name': formData.trust.name,
                    }
                ],
                'address': {
                    'streetaddressline1': formData.fund.address.streetaddressline1,
                    'streetaddressline2': formData.fund.address.streetaddressline2,
                    'city': formData.fund.address.city,
                    'state': formData.fund.address.state,
                    'postcode': formData.fund.address.postcode,
                },
                'createdby': getUser.emailaddress,
                'created': new Date(),
                'modifiedby': '',
                'modified': '',
                'status': 'active',
            },

            'energychange': formData.energychange, // date-string

            'reason': formData.reason,
            'reasonother': formData.reasonOther,

        };

        WriteDocument('energysupplyrequests', energySupplyRequestId, energySupplyRequestDoc, false)
        .then(() => {

            setPageStatus('success');

        }).catch((error) => {

            console.log(error);
            setPageStatus('error-fatal');

        })

    }

    // --------------------------------------------------
    //  useEffects
    // --------------------------------------------------

    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

    return (
        <PageComponent
            header={
                pageStatus !== 'success' &&
                <div className='Page-Header-Container'>

                    {/* Breadcrumbs */}
                    <BreadCrumbs
                        rootLinkName={'Requests'}
                        childLinkName={'Modify Energy Supply Form'}
                    ></BreadCrumbs>

                    {/* Header */}
                    <p className='font-medium text-[20px]'>Modify Existing Energy Supply</p>

                    <p className='text-base'>

                        This is an automated process that follows a path that is determined by the answers you provide on this form.
                        You will be supported along this path by your Energy Procurement Manager who will be notified and will contact you once you complete this questionnaire in full.
                        Please note that making this request does not signify approval, but it does set in motion an automated review and approval process that is aligned with Regional and Group Limits of Authority.
                        <br></br>
                        <br></br>

                        Funding for any approved energy supply must come from the project or Business Unit, and final approval will be through Energy Procurement Manager, so it is important to work with your Project Lead and the Energy Procurement Manager.
                        <br></br>
                        <br></br>

                        Energy Procurement Manager will contact you and follow up on the details of the energy supply contract once they liaise and negotiate with the energy supply vendors.
                    </p>

                </div>
            }
            requiredRoles={['isLLUser']}
            requiredRolesValue={true}
            status={pageStatus}
            body={
                <div className='p-[2%]'>
                    <div className='Page-Section-Container'>

                        <div className='FormComponent'>

                            {/* ========================================= */}
                            {/*  Provide each existing NMI *              */}
                            {/*  - Adds items to the table                */}
                            {/* ========================================= */}

                            <MeterTable
                                formData={formData}
                                setFormData={setFormData}
                            ></MeterTable>

                            {/* =========================================================== */}
                            {/*  2. What type of modification are you requesting?           */}
                            {/* =========================================================== */}

                            <div className='FormComponent-Row'>

                                <label className='font-medium'>2. What type of modification are you requesting? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                    <label className='Radio-Buttons-Container'>
                                        Reassignment
                                        <input
                                            type='radio'
                                            checked={formData.modificationType === 'Reassignment'}
                                            onChange={() => setFormData({ 'modificationType': 'Reassignment' })}
                                        ></input>
                                        <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                    </label>

                                    <label className='Radio-Buttons-Container'>
                                        Termination
                                        <input
                                            type='radio'
                                            checked={formData.modificationType === 'Termination'}
                                            onChange={() => setFormData({ 'modificationType': 'Termination' })}
                                        ></input>
                                        <span className={formData.nmiAssignedError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                    </label>
                                </div>

                            </div>

                            {/* =================================================== */}
                            {/*  2a. What type of modification are you requesting?  */}
                            {/*  Q2 Answer: Reassignment                            */}
                            {/* =================================================== */}

                            <div hidden={formData.modificationType !== 'Reassignment' || formData.modificationType === ''} className='FormComponent-Row'>

                                <BusinessUnit
                                    formData={formData}
                                    setFormData={setFormData}
                                ></BusinessUnit>

                                {/* ==== 2a. Contracting Entity <Optional> ==== */}
                                <ContractingEntity
                                    formData={formData}
                                    setFormData={setFormData}
                                ></ContractingEntity>

                                {/* ==== 2b. Trust <Optional> ==== */}
                                <Trust
                                    formData={formData}
                                    setFormData={setFormData}
                                ></Trust>

                                {/* ==== 2c. Fund <Optional> ==== */}
                                <Fund
                                    formData={formData}
                                    setFormData={setFormData}
                                ></Fund>

                            </div>

                            {/* =================================================== */}
                            {/*  2a. What type of modification are you requesting?  */}
                            {/*  Q2 Answer: Termination                             */}
                            {/* =================================================== */}

                            <div hidden={formData.modificationType !== 'Termination' || formData.modificationType === ''} className='FormComponent-Row'>

                                <label className='font-medium'>2a. Reason for the change? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>

                                    {/* Meter is been transferred to customer */}
                                    <label className='Radio-Buttons-Container'>
                                        Meter is been transferred to customer
                                        <input
                                            type='radio'
                                            checked={formData.reason === 'Meter is been transferred to customer'}
                                            onChange={() => setFormData({ 'reason': 'Meter is been transferred to customer' })}
                                        ></input>
                                        <span className={formData.reasonError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                    </label>

                                    {/* Meter is been handed back to Landlord */}
                                    <label className='Radio-Buttons-Container'>
                                        Meter is been handed back to Landlord
                                        <input
                                            type='radio'
                                            checked={formData.reason === 'Meter is been handed back to Landlord'}
                                            onChange={() => setFormData({ 'reason': 'Meter is been handed back to Landlord' })}
                                        ></input>
                                        <span className={formData.reasonError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                    </label>

                                    {/* Other */}
                                    <label className='Radio-Buttons-Container'>
                                        Other
                                        <input
                                            type='radio'
                                            checked={formData.reason === 'Other'}
                                            onChange={() => setFormData({ 'reason': 'Other' })}
                                        ></input>
                                        <span className={formData.reasonError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                                    </label>

                                </div>

                                {/* Other Text Area */}
                                <textarea
                                    hidden={formData.reason === 'Other' ? false : true}
                                    className={formData.reasonOtherError?.length === 0 ? 'Input-Field-TextArea' : 'Input-Field-TextArea-Error'}
                                    onChange={(e) => setFormData({ 'reasonOther': e.target.value })}
                                    placeholder='Reason for the change'
                                    value={formData.reasonOther}
                                ></textarea>

                            </div>

                            {/* ================================================== */}
                            {/*  3. When should this change take effect?           */}
                            {/* ================================================== */}

                            <div className='FormComponent-Row'>
                                <label className='font-medium'>3. When should this change take effect? <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                <input
                                    className={formData.energychangeError.length === 0 ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                                    type='date'
                                    onChange={(e) => setFormData({ 'energychange': e.target.value })}
                                    value={formData.energychange}
                                    min={new Date().toISOString().split('T')[0]}
                                ></input>
                            </div>

                        </div>

                        {/* ===================================== */}
                        {/*  Checkbox                             */}
                        {/* ===================================== */}
                                                
                        <hr className='mt-4'></hr>

                        <div className='flex flex-row items-start gap-2 my-4 mx-2'>

                            <label className='Checkbox-Container'>
                                <input
                                    required
                                    type='checkbox'
                                    name='confirmation'
                                    checked={confirmationChecked}
                                    onChange={() => setConfirmationChecked(!confirmationChecked)}
                                ></input>
                                <span className='Checkbox'></span>
                            </label>

                            <label>
                                By proceeding with the submission of this form, I have granted authority to the Energy Procurement Team to action this request.
                            </label>

                        </div>

                        {/* ===================================== */}
                        {/*  Submit Buttons                       */}
                        {/* ===================================== */}

                        <div className='FormComponent-Row flex flex-row gap-2'>

                            <button className='Primary-Button w-fit' disabled={
                                formData.nmis.length === 0 ||
                                formData.businessunit?.id === undefined ||
                                formData.energychange?.length === 0 || 
                                confirmationChecked === false
                            } onClick={() => handleSubmit()}>
                                Submit
                            </button>
                            <button className='Secondary-Button w-fit' onClick={() => navigate('/requests')}>
                                Cancel
                            </button>

                        </div>

                    </div>

                </div>
            }
            successContent={
                <div className='p-[2%]'>
                    <div className='Page-Section-Container' style={{ padding: '3%' }}>

                        <div className='flex flex-row gap-[12px] items-center'>
                            <img className='m-0 p-0' src={Tick} alt='Complete'></img>
                            <h4 className='my-2 text-[#007A78]'>Thank You</h4>
                        </div>

                        <p className='font-medium text-[18px] my-3'>Your request has been successfully submitted</p>

                        <p className='text-base'>
                            This is the first step in having your Energy Supply Request submit to Energy Procurement Manager and be assessed.
                            <br></br>
                            <br></br>

                            This is an automated process that follows a path that is determined by the answers you provide on this form.
                            You will be supported along this path by your Energy Procurement Manager who will be notified and will contact you once you complete this questionnaire in full.
                            Please note that making this request does not signify approval, but it does set in motion an automated review and approval process that is aligned with Regional and Group Limits of Authority.
                            <br></br>
                            <br></br>

                            Funding for any approved energy supply must come from the project or Business Unit, and final approval will be through Energy Procurement Manager, so it is important to work with your Project Lead and the Energy Procurement Manager.
                            <br></br>
                            <br></br>

                            Energy Procurement Manager will contact you and follow up on the details of the energy supply contract once they liaise and negotiate with the energy supply vendors.
                        </p>

                        <button className='Primary-Button' onClick={() => navigate('/requests')}> Return Home </button>

                    </div>
                </div>
            }
        ></PageComponent>
    )

}
