//================================================================
//  Component: Navbar
//================================================================

//  Purpose: The Navbar for Events App

//  Example:
//    <Navbar></Navbar>


//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

// Contexts
import { GetUser } from '../../Library/GlobalContexts.js';

//Components
import Logout from '../../Library/Logout';
import NavItem from './NavItem';

//Images
import LLLogo from '../Images/Icon_LLLogo_Green.png';

//CSS
import './Navbar.css';


export default function Navbar() {

  //------------------------------------------------------
  //  useContexts and React Router
  //------------------------------------------------------
  
    const getUser = useContext(GetUser);
    const routerUseLocation = useLocation();

  //------------------------------------------------------
  //  Define useStates
  //------------------------------------------------------

    // Used to change between > 'pending', 'success'
    const [pageStatus, setPageStatus] = useState('pending');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    //Returns the style of each component
    function changeStyleHandler(link, textColour) {

      if (textColour === undefined) {

        textColour = 'text-black'

      }

      //Exception for the default route '/' > We want to the home icon to be selected
      if (routerUseLocation.pathname === '/' && link.toLowerCase() === '/requests?view=underreview') {

        //Update the style to clicked
        return `cursor-pointer border-b-solid border-b-[#E9EBED] border-b-2 px-[10px] py-[10px] no-underline ${textColour}`;

      // Clicked item --> include child pages --> '/requests' & '/requests/child' are treated the same!
      } else if (routerUseLocation.pathname.toLowerCase().includes(link.toLowerCase())){
    
        //Update the style to clicked
        return `cursor-pointer font-medium px-[10px] py-[10px] no-underline ${textColour}`;

      //Update the style to NOT clicked
      } else {
        
        return `cursor-pointer p-[10px] no-underline  ${textColour}`;

      }

    }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

    // OnLoad
    useEffect(() => {

      if (getUser?.roles === undefined) return setPageStatus('success');

    },[pageStatus, getUser]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <div className='flex flex-row justify-between items-center bg-[#141C25] text-[white] px-[10px] py-0 h-[70px] [@media(max-width:450px)]:pl-0 [@media(max-width:450px)]:pr-5'>
  
        {/* ---------------------------------------------------- */}
        {/*  Logo & Links                                        */}
        {/* ---------------------------------------------------- */}

        <div className='flex flex-row items-center justify-between p-0'>
          
          {/* Logo */}
          <div className='flex items-center p-[25px]'>

            <NavLink to='/requests?view=underreview'>
              <img className='mr-[20px] cursor-pointer' src={LLLogo} alt='Lendlease Logo' width='55px' height='39px'></img>
            </NavLink>

            <h5 className='font-medium m-0'> AutoEnergy Supply</h5>

            {/* Divider */}
            <hr className='w-[2px] h-[40px] border-0 bg-[#35404B] p-0 ml-[20px]'></hr>

          </div>

          {/* Links */}
          <div className='flex flex-row m-0 p-0'>

            {/* Energy Supply Requests */}
            <NavItem style={changeStyleHandler('/requests?view=underreview', 'text-white')} link={'/requests'} title={'Requests'} roles={['isLLUser']}></NavItem>

            {/* Asset Inventory */}
            <NavItem style={

              // Nested routes --> keep 'Assets Inventory' active
              routerUseLocation.pathname.includes('/assets') ? 
                `cursor-pointer font-medium px-[10px] py-[10px] no-underline text-white`
              :
                `cursor-pointer p-[10px] no-underline text-white`

            } link={
              
              // Nested routes --> keep 'Assets Inventory' active
              routerUseLocation.pathname.includes('/assets') ? 

                `${routerUseLocation.pathname}?view=ALL`

              :
                `/assets/meters?view=ALL`

            } title={'Assets Inventory'} roles={['isEPM']}></NavItem>

          </div>

        </div>

        {/* ---------------------------------------------------- */}
        {/*  Menu                                                */}
        {/* ---------------------------------------------------- */}

        <Logout></Logout>
        
      </div>
    )

}
