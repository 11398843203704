//================================================================
//  Component: Add Retailer
//================================================================

//  Purpose: Add a new retailer to the assets collection

//  Properties:
//    - paneOpen = {useState, determines if the pane is open}
//    - setPaneOpen = {useState, allows you to open/close pane}

//  Example:
//    <AddRetailer
//      paneOpen={paneOpen}
//      setPaneOpen={setPaneOpen}
//    ></AddRetailer>    

//================================================================


//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Contexts
import { GetUser } from '../../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../../Library/WriteDocument';
import QueryDocument from '../../../Library/QueryDocument';

//Images
import LoadingIcon from '../../../Components/Images/Image_Loading_Ripple.svg';
import Error from '../../../Components/Images/Icon_ErrorX_Red.svg';


export default function AddRetailer({
    paneOpen,
    setPaneOpen
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to determine the pane status > 'onload', 'pending', 'error'
    const [paneStatus, setPaneStatus] = useState('onload');

    // --------------------------------------------------
    //  Form State
    // --------------------------------------------------

    // https://react-hook-form.com/get-started
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        setError,
        clearErrors,
        formState: { isSubmitSuccessful, errors },
    } = useForm();

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Submit Handler
    const onSubmit = (formData) => {

        setPaneStatus('pending');

        //  Prepare Document
        const documentObject = {
            'id': `ag-${Date.now()}`,
            'retailername': formData.retailername,
            'contact': {
                'givenname': formData.givenname,
                'surname': formData.surname,
                'emailaddress': formData.emailaddress,
                'contactnumber': formData.contactnumber,
            },
            'contractterm': {
                'startdate': formData.startdate, //date-string
                'enddate': formData.enddate, //date-string
            },
            'contractstates': formData.contractstates, // NSW, VIC, QLD, WA & East Coast Small Sites
            'bundledrates': formData.bundledrates,
            'peak': formData.peak,
            'offpeak': formData.offpeak,
            'shoulder': formData.shoulder,
            'lgcs': formData.lgcs,
            'stcs': formData.stcs,
            'escs': formData.escs,
            'veecs': formData.veecs,

            'createdby': getUser.emailaddress,
            'created': new Date(),
            'modifiedby': '',
            'modified': new Date(),
            'status': 'active', // active or decommissioned
        };

        WriteDocument('agreements', documentObject.id, documentObject, false).then(() => {

            // Reset form & close side pane
            reset();
            setPaneStatus('onload');
            setPaneOpen(false);

        }).catch((errors) => {

            console.log('error', errors);
            setPaneStatus('error');

        });

    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    useEffect(() => {

        if (isSubmitSuccessful) {

            reset();

        };

    }, [isSubmitSuccessful, reset]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (paneOpen === false) return null;

    //------------------------------------------------------
    //  Pending
    //------------------------------------------------------

    if (paneStatus === 'pending') {
        return (
            <div className='Pane-Background'>
                <dialog className='Pane-Container flex justify-center items-center mb-[2%] p-[4%] rounded-[5px] text-center'>
                    <img alt='loading-circle-icon' src={LoadingIcon}></img>
                </dialog>
            </div>
        )
    }

    //------------------------------------------------------
    //  Onload
    //------------------------------------------------------

    else if (paneStatus === 'onload') {
        return (
            <div className='Pane-Background'>

                <dialog className='Pane-Container flex flex-col gap-2 pb-0'>

                    {/* Header */}
                    <div>
                        <h4> Add Retailer </h4>
                        <hr className='border-[1px] mb-0'></hr>
                    </div>

                    {/* Form */}
                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* ===================================================== */}
                        {/*  1. Retailer Name                                     */}
                        {/* ===================================================== */}

                        <div className='FormComponent-Row'>

                            <label className='font-medium'>1. Retailer Name <span className='text-[#C4314B]'>* </span></label>
                            <input
                                style={{ width: '610px' }}
                                className={errors.retailername ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                {
                                ...register('retailername', {
                                    required: 'This field is required',
                                    onBlur: () => {

                                        QueryDocument('agreements', [['retailername', '==', watch('retailername')]]).then((existingRequests) => {

                                            // Clear any errors
                                            if (existingRequests.length === 0) return clearErrors(['retailername']);

                                            // Found existing retailer
                                            setError(
                                                'retailername', {
                                                type: 'custom', message: 'Found an existing retailer with this name.'
                                            }
                                            )

                                        }).catch((error) => {

                                            return `Failed to validate retailer details. ${error}`

                                        });

                                    }
                                })}
                                placeholder='Snowy Hydro'
                                type='text'
                            ></input>
                            {errors.retailername && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.retailername?.message}</p>}

                        </div>

                        {/* ===================================================== */}
                        {/*  2. Retailer Contact Information                      */}
                        {/* ===================================================== */}

                        <div className='FormComponent-Row'>

                            <label className='font-medium'>2. Retailer Contact Information <span className='text-[#C4314B]'>* </span></label>

                            <div className='flex flex-row gap-2'>

                                {/* First name */}
                                <div className='flex flex-col'>
                                    <label className=' font-medium'>First name <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.givenname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('givenname', {
                                            required: 'This field is required.',
                                        })}
                                        placeholder='Sally'
                                    ></input>

                                    {errors.givenname && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.givenname?.message}</p>}
                                </div>

                                {/* Surname */}
                                <div className='flex flex-col'>
                                    <label className='font-semibold'>Surname <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.surname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('surname', {
                                            required: 'This field is required.',
                                        })}
                                        placeholder='Smith'
                                    ></input>
                                    {errors.surname && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.surname?.message}</p>}
                                </div>

                            </div>

                            {/* Email Address & Contact Number */}
                            <div className='flex flex-row gap-2'>

                                {/* Email Address */}
                                <div className='flex flex-col'>
                                    <label className=' font-medium'>Email Address <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.givenname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('emailaddress', {
                                            required: 'This field is required.',
                                        })}
                                        placeholder='sally.simth@hydro.com'
                                    ></input>

                                    {errors.emailaddress && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.emailaddress?.message}</p>}
                                </div>

                                {/* Contact Number */}
                                <div className='flex flex-col'>
                                    <label className='font-semibold'>Contact Number <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.surname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('contactnumber', {
                                            required: 'This field is required.',
                                        })}
                                        placeholder='04xx xxx xxx'
                                    ></input>
                                    {errors.contactnumber && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.contactnumber?.message}</p>}
                                </div>

                            </div>

                        </div>

                        {/* ===================================================== */}
                        {/*  3. Agreement Terms                                   */}
                        {/* ===================================================== */}

                        <div className='FormComponent-Row'>

                            <label className='font-medium'> 3. Agreement Terms <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>

                            <div className='flex flex-row gap-2'>

                                {/* Start Date */}
                                <div className='flex flex-col'>
                                    <label className=' font-medium'>Start Date <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.startdate ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('startdate', {
                                            required: 'This field is required',
                                            onChange: (value) => {
                                                setValue('startdate', value.target.value);
                                            },
                                        })}
                                        placeholder='Enter start date of agreement'
                                        type='date'
                                    ></input>

                                    {errors.startdate && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.startdate?.message}</p>}
                                </div>

                                {/* End Date */}
                                <div className='flex flex-col'>
                                    <label className=' font-medium'>End Date <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.startdate ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('enddate', {
                                            required: 'This field is required',
                                            onChange: (value) => {
                                                setValue('enddate', value.target.value);
                                            },
                                        })}
                                        placeholder='Enter start date of agreement'
                                        type='date'
                                    ></input>

                                    {errors.enddate && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.enddate?.message}</p>}
                                </div>

                            </div>

                            {/*  Contracting States */}
                            <div className='flex flex-col'>

                                <label className='font-medium'> Contracting States <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                <select
                                    className={errors.contractstates ? ('Input-Field-MultiSelect-Error') : ('Input-Field-MultiSelect')}
                                    {
                                    ...register('contractstates', {
                                        required: 'This field is required'
                                    })}
                                    style={{ width: '300px' }}
                                    multiple
                                >
                                    <option hidden value=''>-</option>
                                    <option value='NSW'> NSW </option>
                                    <option value='VIC'> VIC </option>
                                    <option value='QLD'> QLD </option>
                                    <option value='WA'> WA </option>
                                    <option value='East Coast Small Sites'> East Coast Small Sites</option>
                                </select>
                                <p className='mt-2 text-sm text-slate-600'>To select more than one, press Ctrl and select your options.</p>
                                {errors.contractstates && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.contractstates?.message}</p>}

                            </div>

                            <div className='flex flex-row gap-2'>

                                {/*  Bundled Rates */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Bundled Rates (cents/kW) <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.bundledrates ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('bundledrates', {
                                            required: 'This field is required.',
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('bundledrates', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='0.004'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                    ></input>
                                    {errors.bundledrates && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.bundledrates?.message}</p>}

                                </div>

                                {/* Peak (cents/kW) */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Peak (cents/kW) <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.peak ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('peak', {
                                            required: 'This field is required.',
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('peak', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='0.004'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                    ></input>
                                    {errors.peak && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.peak?.message}</p>}

                                </div>

                            </div>

                            <div className='flex flex-row gap-2'>

                                {/* Off Peak (cents/kW) */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Off Peak (cents/kW) <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.offpeak ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('offpeak', {
                                            required: 'This field is required.',
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('offpeak', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='0.004'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                    ></input>
                                    {errors.offpeak && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.offpeak?.message}</p>}

                                </div>

                                {/* Shoulder (cents/kW) */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Shoulder (cents/kW) <span className='text-[#C4314B]'><span className='text-[#C4314B]'>* </span></span></label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.shoulder ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('shoulder', {
                                            required: 'This field is required.',
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('shoulder', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='0.004'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                    ></input>
                                    {errors.shoulder && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.shoulder?.message}</p>}

                                </div>

                            </div>

                        </div>

                        {/* ===================================================== */}
                        {/*  4. Clean Energy Mandates                             */}
                        {/* ===================================================== */}

                        <div className='FormComponent-Row'>

                            <label className='font-medium'> 4. Clean Energy Mandates</label>

                            <div className='flex flex-row gap-2'>

                                {/*  Large-scale Generation Certs */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Large-scale Generation Certs (%) </label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.lgcs ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('lgcs', {
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('lgcs', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='11.08'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                        max='100'
                                    ></input>
                                    {errors.lgcs && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.lgcs?.message}</p>}

                                </div>

                                {/*  Small-scale Technology Certs */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Small-scale Technology Certs (%) </label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.stcs ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('stcs', {
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('stcs', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='11.08'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                        max='100'
                                    ></input>
                                    {errors.stcs && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.stcs?.message}</p>}

                                </div>

                            </div>

                            <div className='flex flex-row gap-2'>

                                {/*  Energy Savings Certs */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Energy Savings Certs (%) </label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.escs ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('escs', {
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('escs', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='11.08'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                        max='100'
                                    ></input>
                                    {errors.escs && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.escs?.message}</p>}

                                </div>

                                {/*  Victorian Energy Efficiency Certs */}
                                <div className='flex flex-col'>

                                    <label className='font-medium'> Victorian Energy Efficiency Certs (%) </label>
                                    <input
                                        style={{ width: '300px' }}
                                        className={errors.veecs ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                        {...register('veecs', {
                                            valueAsNumber: true,
                                            onChange: (value) => {

                                                // set leading zero if decimal without numbers
                                                setValue('veecs', value.target.value.replace(/^\./, '0.'));

                                            },
                                        })}
                                        placeholder='11.08'
                                        type='number'
                                        step='0.00001'
                                        min='0'
                                        max='100'
                                    ></input>
                                    {errors.veecs && <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errors.veecs?.message}</p>}

                                </div>

                            </div>


                        </div>


                        <div className='h-8' />

                        {/*  ========= Button Container ========= */}
                        <div className='flex flex-row gap-2 sticky bottom-0 right-[10px] left-1/2 pl-2 py-[10px] bg-[#FFFFFF] z-10 border-t-[1px]'>

                            {/* Submit */}
                            <button
                                className='Primary-Button whitespace-nowrap'
                                disabled={false}
                                type='submit'
                                value='submit'
                            >
                                Submit
                            </button>

                            {/* Cancel */}
                            <button className='Secondary-Button whitespace-nowrap' onClick={() => {

                                // Close side pane
                                setPaneOpen(false);
                                reset();

                            }}>
                                Cancel
                            </button>
                        </div>

                    </form>


                </dialog >
            </div >
        )
    }

    //------------------------------------------------------
    //  Error
    //------------------------------------------------------

    else if (paneStatus === 'error') {
        return (
            <div className='Pane-Background'>
                <dialog className='Pane-Container flex flex-col gap-2 justify-center items-center mb-[2%] p-[4%] rounded-[5px] text-center'>

                    <img src={Error} alt='error'></img>

                    <h4> Oops Something Went Wrong! </h4>

                    <p>
                        An error occurred while we processed your request.
                        <br></br>
                        If you feel this is a mistake, contact your site administrator.
                    </p>

                    <button className='Primary-Button' onClick={() => {
                        window.location.reload();
                    }}>
                        Try Again
                    </button>

                </dialog>
            </div>
        )
    }
}